import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";


const initialState = {
    videoCallVideo:[],
    isLoading:false,
    isSkeleton:false,
    total:null
}


export const getVideoCallVideo = createAsyncThunk("admin/videoCallVideo/getVideoCallVideos",async (payload)=>{

    return apiInstanceFetch.get(`admin/videoCallVideo/getVideoCallVideos?start=${payload?.start}&limit=${payload?.limit}`)
})

export const createVideoCallVideo = createAsyncThunk("admin/videoCallVideo/createVideoCallVideo", async (payload) =>{
    return apiInstance.post("admin/videoCallVideo/createVideoCallVideo",payload)
})

export const deleteVideoCallVideo = createAsyncThunk("admin/videoCallVideo/deleteVideoCallVideo", async (id) =>{
    
    return apiInstanceFetch.delete(`admin/videoCallVideo/deleteVideoCallVideo?videoCallVideoId=${id}`)
})

const videoCallVideoSlice = createSlice({
    name:"videoCallVideoSlice",
    initialState,
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(getVideoCallVideo.pending,(state,action) =>{
            state.isSkeleton = true;
        })

        builder.addCase(getVideoCallVideo.fulfilled,(state,action) =>{
            state.isSkeleton = false;
            state.videoCallVideo = action?.payload?.videoCallVideo
            state.total = action?.payload?.totalVideoCallVideo
        })

        
        builder.addCase(getVideoCallVideo.rejected,(state,action) =>{
            state.isSkeleton = false;
        })

        builder.addCase(createVideoCallVideo.pending,(state,action) =>{
            state.isLoading = true;
        })

        builder.addCase(createVideoCallVideo.fulfilled,(state,action) =>{
            state.isLoading = false;
            state.videoCallVideo = [...action?.payload?.videoCallVideo, ...state.videoCallVideo];
            setToast("success","Added VideoCall Video  Successfully");
        })

        
        builder.addCase(createVideoCallVideo.rejected,(state,action) =>{
            state.isLoading = false;
        })

        
    builder.addCase(deleteVideoCallVideo.pending, (state, action) => {
        state.isLoading = true;
      })
  
      builder.addCase(deleteVideoCallVideo.fulfilled, (state, action) => {
        state.videoCallVideo = state.videoCallVideo.filter((videoCallVideo) => videoCallVideo._id !== action.meta.arg);
        state.isLoading = false;
        setToast("success","Deleted VideoCall Video Successfully");
  
      })
      builder.addCase(deleteVideoCallVideo.rejected, (state, action) => {
        state.isLoading = false;
      })
    }
})
export default videoCallVideoSlice.reducer;