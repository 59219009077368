import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../Component/Api/axiosApi";
import { apiInstanceFetch } from "../../Component/Api/axiosApi";

import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  admin: {},
  dashboardCount: null,
  totalLiveApp:null,
  isAuth: false,
  isLoading: false,
  dashData:[]
};

export const login = createAsyncThunk("admin/login", async (payload) => {
  return apiInstanceFetch.post("admin/admin/login", payload);
});

// export const getAdmin = createAsyncThunk(
//   "admin/getProfile",
//   async (payload) => {
//     return apiInstance.get(`admin/admin/profile?adminId=${payload}`);
//   }
// );

export const getAdmin = createAsyncThunk(
  `admin/getProfile`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/admin/profile?adminId=${payload}`);
  }
);

// export const getDashboardCount = createAsyncThunk(
//   "dashboard/dashBoardCount",
//   async (payload) => {
//     return apiInstance.get(`admin/dashboard/dashBoardCount`);
//   }
// )
export const getDashboardCount = createAsyncThunk(
  `admin/dashboard/dashBoardCount`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/dashboard/dashBoardCount`, payload);
  }
);

// export const updateProfile = createAsyncThunk("admin/admin/updateProfile", async (payload) => {
//   return apiInstanceFetch.patch(`admin/admin/updateProfile?adminId=${payload?.id}`, payload?.data);
// });

export const updateProfile = createAsyncThunk(
  "admin/admin/updateProfile",
  async (payload) => {
    return apiInstance.patch(
      `admin/admin/updateProfile?adminId=${payload?.id}`,
      payload?.data
    );
  }
);

export const updatePassword = createAsyncThunk(
  "admin/admin/updatePassword",
  async (payload) => {
    return apiInstance.patch(`admin/admin/updatePassword`, payload?.data);
  }
);

export const dashboardAnalyticData = createAsyncThunk(
  "admin/dashboard/dashboardAnalytic",
  async (payload) => {
    
    return apiInstanceFetch.get(
      `admin/dashboard/dashboardAnalytic?startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);
const authSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token_ = JSON.parse(action.payload);
      state.admin = token_;
      state.isAuth = true;
      SetDevKey(key);
    },

    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      sessionStorage.removeItem("adminToken");
      state.admin = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    //  login api
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        let token_ = action.payload.token && jwt_decode(action.payload.token);
        state.admin = token_;
        state.isAuth = true;
        state.isLoading = false;
        SetDevKey(key);
        setToken(token_ ? JSON.stringify(token_) : undefined);
        sessionStorage.setItem(
          "adminToken",
          JSON.stringify(action.payload.token)
        );
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem("key", key ? key : undefined);
        sessionStorage.setItem("isAuth", true);
        sessionStorage.setItem("user", "admin");
        window.location.href = "/admin/dashboard";
      } else {
        setToast("error", action.payload?.message);
        console.log("action.payload", action.payload);
        state.isLoading = false;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });
    // admin api
    builder.addCase(dashboardAnalyticData.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(dashboardAnalyticData.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.dashData = action.payload.data;
    });
    builder.addCase(dashboardAnalyticData.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = action.payload.user;
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // dashboard count
    builder.addCase(getDashboardCount.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getDashboardCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardCount = action.payload.totalApp;
      state.totalLiveApp = action.payload.totalLiveApp;
    });
    builder.addCase(getDashboardCount.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === true) {
        state.admin = action.payload.admin;
        setToast("success", "Updated profile Successfully");
      } else {
        setToast("error", action.payload  ?.message);
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === true) {
        state.admin = action.payload.admin;
        setToast("success", "Admin Password Update Successfully");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        setToast("error", action.payload?.message);
      }
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
    });
   
  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
