import React, { useEffect, useState } from "react";
import Input from "../../extra/Input";
import { sendNotification } from "../../../store/slice/notificationSlice";
import SendIcon from "@mui/icons-material/Send";
import Button from "../../extra/Button";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../utils/config";

const PushNotification = (props) => {
  const location = useLocation();
  const [notificationInput, setNotificationInput] = useState({
    title: "",
    description: "",
    link: "",
  });
  const [appId, setAppId] = useState("");
  const [invalidUrls, setInvalidUrls] = useState("");
  const [error, setError] = useState({
    title: "",
    description: "",
    link: "",
  });

  useEffect(() => {
    setAppId(location.state.editAppData?._id);
  }, [location.state]);

  const handleImmediatelyInputChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setNotificationInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [errorValue]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [errorValue]: "",
      });
    }
  };

  const validateImage = (url, index) => {
    const img = new Image();
    img.src = url;

    img.onerror = () => {
      setInvalidUrls(`Invalid image URL`);
    };

    img.onload = () => {
      setInvalidUrls("");
    };
  };

  useEffect(() => {
    if (notificationInput?.link) {
      validateImage(notificationInput?.link);
    }
  }, [notificationInput]);

  const handleSubmit = () => {
    const { title, description, link } = notificationInput;

    if (!title || !description || !link || invalidUrls?.length > 0) {
      let error = {};
      if (!title) error.title = "Title Is Required !";
      if (!description) error.description = "Description Is Required !";
      if (!link) error.link = "Image Is Required !";
      return setError({ ...error });
    } else {
      let data = {
        title: title,
        discreption: description,
        image: link,
        appId: [appId],
      };

      const payload = {
        data: data,
        appId: appId,
      };
      props.sendNotification(payload);
    }
  };

  return (
    <div>
      <div className="notificationPush">
        <form>
          <div className="row">
            <div className="col-12">
              <Input
                label={`Title`}
                id={`title`}
                type={`text`}
                name={"title"}
                placeholder={"Enter Title"}
                value={notificationInput.title}
                errorMessage={error.title && error.title}
                onChange={(e) =>
                  handleImmediatelyInputChange(
                    e,
                    "title",
                    "Title Is Required !"
                  )
                }
              />
            </div>
            <div className="col-12">
              <Input
                label={`Description`}
                id={`description`}
                type={`text`}
                name={"description"}
                placeholder={"Enter Description"}
                value={notificationInput.description}
                errorMessage={error.description && error.description}
                onChange={(e) =>
                  handleImmediatelyInputChange(
                    e,
                    "description",
                    "Description Is Required !"
                  )
                }
              />
            </div>
            <div className="col-12">
              <div className="image-inputBox">
                <label>App Name</label>
                <div className="image-input">
                  <input
                    value={location.state?.editAppData?.appName}
                    disabled
                  />
                  <div className="img-box">
                    <img
                      src={baseURL + "/" + location.state?.editAppData?.appLogo}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <Input
                label={`Image`}
                id={`link`}
                type={`text`}
                name={"link"}
                placeholder={"Enter Image Link"}
                value={notificationInput.link}
                errorMessage={error.link && error.link}
                onChange={(e) =>
                  handleImmediatelyInputChange(e, "link", "Image Is Required !")
                }
              />
              {invalidUrls && <p className="errorMessage">{invalidUrls}</p>}
              {notificationInput.link && (
                <img src={notificationInput.link} className="img-notifi" />
              )}
            </div>
            <div className="mt-3 d-flex justify-content-end send-btn">
              <Button
                btnIcon={<SendIcon />}
                type={"button"}
                style={{ marginRight: "10px" }}
                onClick={() => handleSubmit()}
                btnName={"Send"}
                newClass={"back-btn"}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, {
  sendNotification,
})(PushNotification);
