import React, { useEffect, useState } from "react";
import Pagination from "../../extra/Pagination";
import Table from "../../extra/Table";
import ButtonCustom from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";
import {
  getGiftCategory,
  deleteGiftCategory,
} from "../../../store/slice/GiftSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../store/slice/dialogueSlice";
import SVGA from "svgaplayerweb";
import { warning } from "../../utils/Alert";
import dayjs from "dayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import { Button, Menu, MenuItem, alpha } from "@mui/material";
import AddSvgaGiftDialog from "../../dialogComponent/AddSvgaGiftDialog";
import GiftImageDialog from "../../dialogComponent/GiftImageDialog";
import GiftCategoryDialog from "../../dialogComponent/GiftCategoryDialog";
import { isSkeleton } from "../../utils/allSelector";

const SkeletonLoaderGift = () => (
  <div className="giftCategoryWiseLoader col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
    <div className="gift-Image">
      <div className="giftImageShow">
        <p></p>
      </div>
      <div className="giftText">
        <h5></h5>
        <h4></h4>
        <div className="gift-button">
          <ButtonCustom newClass={"edit-btn"} />
          <ButtonCustom newClass={"delete-btn"} />
        </div>
      </div>
    </div>
  </div>
);

function GiftCategory(props) {
  const { allGiftCategory, totalGiftCategory } = useSelector(
    (state) => state.gift
  );
  const isSkeletonLoader = useSelector(isSkeleton);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    props.getGiftCategory(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(allGiftCategory);
  }, [allGiftCategory]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleDeleteGift = (item) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              id: item?._id,
              name: item?.name,
            };
            props.deleteGiftCategory(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    setAnchorEl(null);
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  useEffect(() => {
    data?.map((item) =>
      item?.gift?.map((gift, index) => {
        if (gift?.image?.split(".")?.pop() === "svga") {
          var player = new SVGA.Player(`div[attr="${index}"]`);
          var parser = new SVGA.Parser(`div[attr="${index}"]`);
          parser.load(gift.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        }
      })
    );
  }, [data]);

  const handleEditGift = (item) => {
    const payload = {
      dialogue: true,
      dialogueType: "giftCategory",
      dialogueData: item,
    };

    dispatch(openDialog(payload));
  };

  return (
    <div className="game-table giftCategory">
      {dialogueType === "giftCategory" && <GiftCategoryDialog />}
      <h6 style={{ marginTop: "10px" }}>
        Total Gift Category :{" "}
        {allGiftCategory?.length ? allGiftCategory?.length : "0"}
      </h6>
      <div className="row mt-3 pt-1">
        <div className="col-8">
          <Searching
            placeHolder={"What’re you searching for?"}
            type="client"
            buttonShow={true}
            data={allGiftCategory}
            setData={setData}
            onFilterData={handleFilterData}
            searchValue={search}
          />
        </div>
        <div className="col-4 new-button d-flex justify-content-end">
          <ButtonCustom
            btnName={"New"}
            btnIcon={<AddRoundedIcon />}
            type={"button"}
            onClick={() => handleNewOpenDialog("giftCategory")}
            newClass={"new-btn"}
          />
        </div>
      </div>
      <div className="gift-show row">
        {isSkeletonLoader === true ? (
          <>
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
            <SkeletonLoaderGift />
          </>
        ) : (
          <>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <div className="giftCategoryWise col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="giftBox">
                      <>
                        <div className=" ">
                          <div className="gift-Image">
                            <div className="giftImageShow">
                              <img src={item?.image} />
                            </div>
                            <div className="giftText">
                              <h5>{item?.name}</h5>
                              <h4>{`${
                                item?.giftCount ? item?.giftCount : 0
                              } Gifts`}</h4>
                              <div className="gift-button">
                                <ButtonCustom
                                  btnIcon={<EditRoundedIcon />}
                                  newClass={"edit-btn"}
                                  onClick={() => handleEditGift(item)}
                                />
                                <ButtonCustom
                                  btnIcon={<DeleteRoundedIcon />}
                                  newClass={"delete-btn"}
                                  onClick={() => handleDeleteGift(item)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 style={{ height: "600px", borderBottom: "none" }} className="not-foundText">
                Gift Category Not Found....
              </h2>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default connect(null, { getGiftCategory, deleteGiftCategory })(
  GiftCategory
);
