import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../../Component/extra/Input";
import Button from "../../Component/extra/Button";
import { useNavigate } from "react-router-dom";
import { getTestAdId, updateTestAdId } from "../../store/slice/testAdIdSlice";
import Title from "../extra/Title";

const TestId = () => {
  const { testId } = useSelector((state) => state.testId);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  useEffect(() => {
    dispatch(getTestAdId());
  }, []);

  useEffect(() => {
    setData(testId);
  }, [testId]);

  const [bannerGoogle, setBannerGoogle] = useState("");
  const [bannerFacebook, setBannerFacebook] = useState("");
  const [nativeFacebook, setNativeFacebook] = useState("");
  const [interstitialFacebook, setInterstitialFacebook] = useState("");
  const [rewardFacebook, setRewardFacebook] = useState("");
  const [nativeGoogle, setNativeGoogle] = useState("");
  const [interstitialGoogle, setInterstitialGoogle] = useState("");
  const [rewardGoogle, setRewardGoogle] = useState("");

  useEffect(() => {
    setBannerGoogle(data?.google?.bannerGoogle);
    setNativeGoogle(data?.google?.nativeGoogle);
    setRewardGoogle(data?.google?.rewardGoogle);
    setInterstitialGoogle(data?.google?.interstitialGoogle);
    setBannerFacebook(data?.facebook?.bannerFacebook);
    setNativeFacebook(data?.facebook?.nativeFacebook);
    setInterstitialFacebook(data?.facebook?.interstitialFacebook);
    setRewardFacebook(data?.facebook?.rewardFacebook);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      bannerGoogle,
      nativeGoogle,
      interstitialGoogle,
      rewardGoogle,
      bannerFacebook,
      nativeFacebook,
      rewardFacebook,
      interstitialFacebook,
    };

    dispatch(updateTestAdId({ data: payload, id: data?._id }));
  };
  return (
    <div className="testId">
      <Title title="Test Ad Id" />
      <div className="testId-page">
        <form>
          <div className="col-12 d-flex justify-content-end mb-3">
            <Button
              btnName={"Submit"}
              type={"submit"}
              newClass={"submit-btn"}
              style={{marginRight:"13px"}}
              onClick={handleSubmit}
            />
          </div>
          <div className="testIdBox">
            <div className="row">
              <div className="col-12 col-md-6">
                <div
                  className="googleBox  p-4"
                //   style={{ border: "1px solid black", borderRadius: "15px" }}
                >
                  <h3 className="fw-bold">Google</h3>
                  <div className="col-12">
                    <Input
                      label={"Banner Google"}
                      type={`text`}
                      name={"bannerGoogle"}
                      value={bannerGoogle}
                      onChange={(e) => setBannerGoogle(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"Native Google"}
                      type={`text`}
                      name={"nativeGoogle"}
                      value={nativeGoogle}
                      onChange={(e) => setNativeGoogle(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"InterstitialGoogle Google"}
                      type={`text`}
                      name={"interstitialGoogle"}
                      value={interstitialGoogle}
                      onChange={(e) => setInterstitialGoogle(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"Reward Google"}
                      type={`text`}
                      name={"rewardGoogle"}
                      value={rewardGoogle}
                      onChange={(e) => setRewardGoogle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="fbBox p-4 ms-2"
                //   style={{ border: "1px solid black", borderRadius: "15px" }}
                >
                  <h3 className="fw-bold">Facebook</h3>
                  <div className="col-12">
                    <Input
                      label={"Banner Facebook"}
                      type={`text`}
                      name={"bannerFacebook"}
                      value={bannerFacebook}
                      onChange={(e) => setBannerFacebook(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"Native Facebook"}
                      type={`text`}
                      name={"nativeFacebook"}
                      value={nativeFacebook}
                      onChange={(e) => setNativeFacebook(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"InterstitialFacebook Facebook"}
                      type={`text`}
                      name={"interstitialFacebook"}
                      value={interstitialFacebook}
                      onChange={(e) => setInterstitialFacebook(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      label={"Reward Facebook"}
                      type={`text`}
                      name={"rewardFacebook"}
                      value={rewardFacebook}
                      onChange={(e) => setRewardFacebook(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestId;
