import { IconButton, Rating } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { Fragment, useEffect, useState } from "react";
import {
  createGift,
  editGift,
  getGiftCategory,
} from "../../store/slice/GiftSlice";
import { closeDialog } from "../../store/slice/dialogueSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { baseURL } from "../utils/config";
import ReactSelect from "react-select";

const GiftImageDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { allGiftCategory } = useSelector((state) => state.gift);

  const [image, setImage] = useState(null);
  const [imageShow, setImageShow] = useState(null);
  const [coin, setCoin] = useState("");
  const [selectCategoryId, setSelectCategoryId] = useState("");
  const [categoryOption, setCategoryOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState();
  const [error, setError] = useState({
    coin: "",
    image: "",
    category: "",
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    props.getGiftCategory();
  }, []);

  useEffect(() => {
    setCategoryOption(allGiftCategory);
  }, [allGiftCategory]);

  useEffect(() => {
    const allGiftCategoryFilter = allGiftCategory?.filter(
      (item) => item?._id === dialogueData?.giftData?.giftCategoryId
    );
    if (dialogueData) {
      setCoin(dialogueData?.giftData?.coin);
      setSelectCategoryId(allGiftCategoryFilter[0]?._id);
      setSelectedOptions(allGiftCategoryFilter[0]);
      setImageShow(dialogueData?.giftData?.image);
    }
  }, [dialogueData, allGiftCategory]);

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="moreApp-optionList">
      <img src={data?.image} />
      <span>{data?.name}</span>
    </div>
  );

  const handleSelectChange = (selected, type) => {
    setSelectedOptions(selected);
    setSelectCategoryId(selected?._id);

    if (!selected) {
      return setError({
        ...error,
        category: `Category Is Required !`,
      });
    } else {
      return setError({
        ...error,
        category: "",
      });
    }
  };

  const handleFileUpload = (e) => {
    setImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setImageShow(showLogo);

    if (!e.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required !`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleSubmit = (e) => {
    const imageValid=dialogueData?.giftData ? "" : !image 
    if (!coin || !selectCategoryId || imageValid) {
      let error = {};
      if (!coin) error.coin = "Coin Is Required !";
      if (!selectCategoryId) error.category = "Category Is Required !";
      if (imageValid)  {
        error.image = "Image Is Required !";
      }

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("coin", coin);
      if (image?.type === "image/gif") {
        formData.append("type", 2);
      } else {
        formData.append("type", 1);
      }
      formData.append("giftCategoryId", selectCategoryId);
      formData.append("image", image);
      if (dialogueData) {
        const payload = {
          id: dialogueData?.giftData?._id,
          data: formData,
          categoryId:selectCategoryId
        };
        props.editGift(payload);
        dispatch(closeDialog());
      } else {
        const payload = {
            data: formData,
            categoryId:selectCategoryId
          };
        props.createGift(payload);
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="giftDialog">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update Gift" : "Add Gift"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 ">
                  <Input
                    label={`Coin`}
                    id={`coin`}
                    type={`number`}
                    value={coin}
                    errorMessage={error.coin && error.coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          coin: `Coin Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          coin: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 categorySelect">
                  <label className="label-text mb-0">Category</label>
                  <ReactSelect
                    options={categoryOption || []}
                    value={selectedOptions}
                    isClearable={false}
                    onChange={(e) => handleSelectChange(e, "addAds")}
                    getOptionValue={(option) => option._id}
                    formatOptionLabel={(option) => (
                      <div className="moreApp-option">
                        <img src={option?.image} />
                        <span>{option?.name}</span>
                      </div>
                    )}
                    components={{
                      Option: CustomOption,
                    }}
                  />
                  {error?.category && (
                    <p className="errorMessage">{error?.category}</p>
                  )}
                </div>
                <div className="col-12 image-input">
                  <label className="label-input">Image (.jpg,.png,GIF)</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/gif, image/jpeg, image/png"
                    className="form-input"
                    onChange={handleFileUpload}
                  />
                  {error?.image && (
                    <p className="errorMessage">{error?.image}</p>
                  )}
                  {imageShow && <img src={imageShow} />}
                </div>
              </div>
              <div className="row dialog-footer mt-2">
                <div className="col-6"></div>
                <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                  <Button
                    btnName={"Cancel"}
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                    newClass={"cancel-btn"}
                  />
                  <Button
                    btnName={"Submit"}
                    type={"button"}
                    onClick={handleSubmit}
                    newClass={"submit-btn"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createGift, editGift, getGiftCategory })(
  GiftImageDialog
);
