import React from "react";
import Input from "./Input";

export default function MonetizationBox(props) {
  const {
    inputValue,
    title,
    onDelete,
    onUpdate,
  } = props;




  return (
    <div
      className="ad-revenue-box mt-0 mb-4"
      style={{
        boxShadow:
          "0 -2px 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="ad-revenue-title d-flex justify-content-between align-items-center">
        <h6 className="text-capitalize">{title}</h6>
      </div>
      <div className="row">
        <div className="col-12"></div>
        <div className="col-12">
          <Input
            label={"Type"}
            type={`text`}
            name={"type"}
            value={inputValue?.type || ""}
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <Input
            label={"BannerAd UnitId"}
            type={`text`}
            name={"bannerAdUnitId"}
            value={inputValue?.bannerAdUnitId || ""}
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <Input
            label={"NativeAd UnitId"}
            type={`text`}
            name={"nativeAdUnitId"}
            value={inputValue?.nativeAdUnitId || ""}
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <Input
            label={"InterstitialAd UnitId"}
            type={`text`}
            name={"interstitialAdUnitId"}
            value={inputValue?.interstitialAdUnitId || ""}
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <Input
            label={"AppOpenAd UnitId"}
            type={`text`}
            name={"appOpenAdUnitId"}
            value={inputValue?.appOpenAdUnitId || ""}
            readOnly={true}
          />
        </div>
        <div className="col-12">
          <Input
            label={"Status"}
            type={`text`}
            name={"enable"}
            value={inputValue?.enable === true ? "Active" : "Inactive"}
            readOnly={true}
            fieldClass={`${inputValue?.enable ? "text-success" : "text-danger"} fw-bold `}
          />
        </div>
        <div className="d-flex mt-3">
          <button
            className="btn edit-btn text-white ms-2"
            style={{
              background: "linear-gradient(180deg, #6d72ff 0%, #6a5dff 99.99%)",
            }}
            onClick={(e) => {
              e.preventDefault();
              onUpdate();
            }}
          >
            Update
          </button>
          <button
            className="btn btn-danger ms-2"
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
