import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  allGift: [],
  totalGift: 0,
  allGiftCategory: [],
  totalGiftCategory: 0,
  isLoading: false,
  isSkeleton: false,
};

export const getGiftAll = createAsyncThunk(
  "gift/categoryWiseGift",
  async (payload) => {
    return apiInstanceFetch.get(`admin/gift/categoryWiseGift`, payload);
  }
);

export const createGift = createAsyncThunk(
  "admin/gift/createGift",
  async (payload) => {
    return apiInstance.post("admin/gift/createGift", payload?.data);
  }
);

export const editGift = createAsyncThunk(
  "admin/gift/updateGift?giftId",
  async (payload) => {
    return apiInstance.patch(
      `admin/gift/updateGift?giftId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteGift = createAsyncThunk(
  "admin/gift/deleteGift?giftId",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/gift/deleteGift?giftId=${payload?.id}`
    );
  }
);

export const getGiftCategory = createAsyncThunk(
  "giftCategory/getGiftCategory",
  async (payload) => {
    return apiInstanceFetch.get(`admin/giftCategory/getGiftCategory`, payload);
  }
);

export const createGiftCategory = createAsyncThunk(
  "giftCategory/createGiftCategory",
  async (payload) => {
    return apiInstance.post(
      "admin/giftCategory/createGiftCategory",
      payload?.data
    );
  }
);

export const editGiftCategory = createAsyncThunk(
  "giftCategory/updateGiftCategory",
  async (payload) => {
    return apiInstance.patch(
      `admin/giftCategory/updateGiftCategory?giftCategoryId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteGiftCategory = createAsyncThunk(
  "giftCategory/deleteGiftCategory",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/giftCategory/deleteGiftCategory?giftCategoryId=${payload?.id}`
    );
  }
);

const giftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGiftAll.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getGiftAll.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.allGift = action.payload.gifts;
      state.totalGift = action.payload.totalGifts;
    });
    builder.addCase(getGiftAll.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(createGift.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createGift.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        const { categoryId } = action.meta.arg; // Assuming you have categoryId in your action arguments
        const updatedGiftArray = state.allGift.map((giftCategory) => {
          if (giftCategory._id === categoryId) {
            return {
              ...giftCategory,
              gift: [...giftCategory.gift, action.payload.data],
            };
          }
          return giftCategory;
        });

        state.allGift = updatedGiftArray;
        setToast("success", "Gift New Added");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(createGift.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(editGift.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editGift.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.status === true) {
        const { categoryId, id } = action.meta.arg;

        const updatedGiftArray = state.allGift.map((giftCategory) => {
          if (giftCategory?._id === categoryId) {
            const updatedGifts = giftCategory.gift.map((gift) => {
              if (gift?._id === id) {
                return { ...gift,...action.payload.data };
              }
              return gift;
            });
            return {
              ...giftCategory,
              gift: updatedGifts,
            };
          }
          return giftCategory;
        });

        state.allGift = updatedGiftArray;
        setToast("success", "Gift Updated Successfully");
      }
    });
    builder.addCase(editGift.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteGift.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteGift.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === true) {
        state.allGift = state.allGift.map((category) => {
          if (category?._id === action.meta.arg?.categoryId) {
            category.gift = category?.gift.filter((gift) => {
              return gift?._id !== action.meta.arg?.id;
            });
          }
          return category;
        });
        setToast("success", "Gift Deleted Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deleteGift.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getGiftCategory.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getGiftCategory.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.allGiftCategory = action.payload.data;
      state.totalGiftCategory = action.payload.total;
    });
    builder.addCase(getGiftCategory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(createGiftCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createGiftCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allGiftCategory.unshift(action.payload.data);
        setToast("success", "Gift Category Added");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(createGiftCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(editGiftCategory.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editGiftCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allGiftCategory = state?.allGiftCategory?.map((category) => {
        if (category?._id === action.meta.arg?.id) {
          return action.payload?.data;
        }
        return category;
      });
      setToast("success", "Gift Category Updated Successfully");
    });
    builder.addCase(editGiftCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteGiftCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteGiftCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allGiftCategory = state.allGiftCategory.filter((data) => {
          return data?._id !== action.meta.arg?.id;
        });
        setToast(
          "success",
          `${action.meta.arg?.name && action.meta.arg?.name}` +
            " " +
            "Category Deleted Successfully"
        );
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deleteGiftCategory.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default giftSlice.reducer;
