import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  postData: [],
  totalPost: "",
  isLoading: false,
  isSkeleton: false,
};

export const getPost = createAsyncThunk("admin/post/getPosts", async (payload) => {
  return apiInstanceFetch.get(
    `admin/post/getPosts?start=${payload?.start}&limit=${payload?.limit}`,
    payload
  );
});

export const createPost = createAsyncThunk(
  "admin/post/store",
  async (payload) => {
    return apiInstance.post("admin/post/store", payload?.data);
  }
);

export const editPost = createAsyncThunk(
  "admin/post/update",
  async (payload) => {
    return apiInstance.patch(
      `admin/post/update?postId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deletePost = createAsyncThunk(
  "admin/post/destroy",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/post/destroy?postId=${payload?.id}`,
    );
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPost.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getPost.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.postData = action.payload.post;
      state.totalPost = action.payload.totalPosts;
    });
    builder.addCase(getPost.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(createPost.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.postData.unshift(action.payload.post);
        setToast("success", "Post New Created Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(createPost.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(editPost.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editPost.fulfilled, (state, action) => {
      state.isLoading = false;
      state.postData = state?.postData?.map((post) => {
        if (post?._id === action.meta.arg?.id) {
          return action.payload?.post;
        }
        return post;
      });
      setToast("success", "Post Updated Successfully");
    });
    builder.addCase(editPost.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletePost.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.postData = state.postData.filter((data) => {
          return data._id !== action.meta.arg?.id;
        });
        setToast("success","Post Deleted SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deletePost.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default postSlice.reducer;
