import { IconButton, Rating } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { Fragment, useEffect, useState } from "react";
import {
  createGiftCategory,
  editGiftCategory,
} from "../../store/slice/GiftSlice";
import { closeDialog } from "../../store/slice/dialogueSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { baseURL } from "../utils/config";
import ReactSelect from "react-select";

const GiftCategoryDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [image, setImage] = useState(null);
  const [imageShow, setImageShow] = useState(null);
  const [name, setName] = useState("");
  const [error, setError] = useState({
    coin: "",
    image: "",
    category: "",
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setImageShow(dialogueData?.image);
    }
  }, [dialogueData]);

  const handleFileUpload = (e) => {
    setImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setImageShow(showLogo);

    if (!e.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required !`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleSubmit = (e) => {
    const imageValid=dialogueData ? "" : !image 
    if (!name || imageValid) {
      let error = {};
      if (!name) error.name = "Name Is Required !";
      if (imageValid)  {
        error.image = "Image Is Required !";
      }

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      if (dialogueData) {
        const payload = {
          id: dialogueData?._id,
          data: formData,
        };
        props.editGiftCategory(payload);
        dispatch(closeDialog());
      } else {
        const payload = {
            data: formData,
          };
        props.createGiftCategory(payload);
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="giftCategoryDialog">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update Gift Category" : "Add Gift Category"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 ">
                  <Input
                    label={`Name`}
                    id={`name`}
                    type={`text`}
                    value={name}
                    errorMessage={error.name && error.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: `Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          name: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 image-input">
                  <label className="label-input">Image</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/gif, image/jpeg, image/png"
                    className="form-input"
                    onChange={handleFileUpload}
                  />
                  {error?.image && (
                    <p className="errorMessage">{error?.image}</p>
                  )}
                  {imageShow && <img src={imageShow} />}
                </div>
              </div>
              <div className="row dialog-footer mt-2">
                <div className="col-6"></div>
                <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                  <Button
                    btnName={"Cancel"}
                    onClick={handleClose}
                    style={{ marginRight: "10px" }}
                    newClass={"cancel-btn"}
                  />
                  <Button
                    btnName={"Submit"}
                    type={"button"}
                    onClick={handleSubmit}
                    newClass={"submit-btn"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createGiftCategory, editGiftCategory })(
  GiftCategoryDialog
);
