import React, { useEffect, useState } from "react";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { createPost, editPost } from "../../store/slice/postSlice";
import TextArea from "../extra/TextArea";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "../extra/Button";

const PostDialogue = (props) => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const [postInput, setInput] = useState({
    mName: "",
    mUsername: "",
    mBio: "",
    mComment: "",
    mImage: "",

    fName: "",
    fUsername: "",
    fBio: "",
    fComment: "",
    fImage: "",
  });
  const [error, setError] = useState({});

  const handleClose = () => {
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (dialogueData) {
      setInput({
        mName: dialogueData?.mName?.join("%%"),
        mUsername: dialogueData?.mUsername?.join("%%"),
        mBio: dialogueData?.mBio?.join("%%"),
        mComment: dialogueData?.mComment?.join("%%"),
        mImage: dialogueData?.mImage?.join("%%"),

        fName: dialogueData?.fName?.join("%%"),
        fUsername: dialogueData?.fUsername?.join("%%"),
        fBio: dialogueData?.fBio?.join("%%"),
        fComment: dialogueData?.fComment?.join("%%"),
        fImage: dialogueData?.fImage?.join("%%"),
      });
    }
  }, [dialogueData]);

  const handlePostChange = (e, errorMessage) => {
    const { name, value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [name]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleSubmit = () => {
    if (dialogueData) {
      const payload = {
        id: dialogueData._id,
        data: postInput,
      };
      props.editPost(payload)

      dispatch(closeDialog())
    } else {
      if (
        !postInput?.mBio ||
        !postInput?.mComment ||
        !postInput?.mName ||
        !postInput?.mUsername ||
        !postInput?.mImage ||
        !postInput?.fBio ||
        !postInput?.fComment ||
        !postInput?.fName ||
        !postInput?.fUsername ||
        !postInput?.fImage
      ) {
        let error = {};
        if (!postInput?.mBio) error.mBio = "Bio Is Required !";
        if (!postInput?.mComment) error.mComment = "Comment Is Required !";
        if (!postInput?.mName) error.mName = "Name Is Required !";
        if (!postInput?.mUsername) error.mUsername = "UserName Is Required !";
        if (!postInput?.mImage) error.mImage = "Link Is Required !";

        if (!postInput?.fBio) error.fBio = "Bio Is Required !";
        if (!postInput?.fComment) error.fComment = "Comment Is Required !";
        if (!postInput?.fName) error.fName = "Name Is Required !";
        if (!postInput?.fUsername) error.fUsername = "UserName Is Required !";
        if (!postInput?.fImage) error.fImage = "Link Is Required !";

        return setError({ ...error });
      } else {
        const payload = {
          data: postInput,
        };
        props.createPost(payload);
        dispatch(closeDialog())
      }
    }
  };

  return (
    <div className="gameDialogue">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Edit Post" : "Create New Post"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body mb-3 pb-2">
              <h5>Note : Enter coma (%%) separated string.</h5>
              <div className="row post-box">
                <div className="col-12 col-md-6">
                  <h6>Male</h6>
                  <div className="input-post">
                    <TextArea
                      label={`Name`}
                      placeholder={"Enter Name"}
                      onChange={(e) =>
                        handlePostChange(e, "Name Is Required !")
                      }
                      row={2}
                      value={postInput?.mName}
                      name={"mName"}
                      errorMessage={error.mName && error.mName}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Username`}
                      placeholder={"Enter Username"}
                      onChange={(e) =>
                        handlePostChange(e, "Username Is Required !")
                      }
                      row={2}
                      value={postInput?.mUsername}
                      name={"mUsername"}
                      errorMessage={error.mUsername && error.mUsername}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Bio`}
                      placeholder={"Enter Bio"}
                      onChange={(e) => handlePostChange(e, "Bio Is Required !")}
                      row={2}
                      value={postInput?.mBio}
                      name={"mBio"}
                      errorMessage={error.mBio && error.mBio}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Comment`}
                      placeholder={"Enter Comment"}
                      onChange={(e) =>
                        handlePostChange(e, "Comment Is Required !")
                      }
                      row={2}
                      value={postInput?.mComment}
                      name={"mComment"}
                      errorMessage={error.mComment && error.mComment}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Link`}
                      placeholder={"Enter Link"}
                      onChange={(e) =>
                        handlePostChange(e, "Link Is Required !")
                      }
                      row={2}
                      value={postInput?.mImage}
                      name={"mImage"}
                      errorMessage={error.mImage && error.mImage}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h6>Female</h6>
                  <div className="input-post">
                    <TextArea
                      label={`Name`}
                      placeholder={"Enter Name"}
                      onChange={(e) =>
                        handlePostChange(e, "Name Is Required !")
                      }
                      row={2}
                      value={postInput?.fName}
                      name={"fName"}
                      errorMessage={error.fName && error.fName}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Username`}
                      placeholder={"Enter Username"}
                      onChange={(e) =>
                        handlePostChange(e, "Username Is Required !")
                      }
                      row={2}
                      value={postInput?.fUsername}
                      name={"fUsername"}
                      errorMessage={error.fUsername && error.fUsername}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Bio`}
                      placeholder={"Enter Bio"}
                      onChange={(e) => handlePostChange(e, "Bio Is Required !")}
                      row={2}
                      value={postInput?.fBio}
                      name={"fBio"}
                      errorMessage={error.fBio && error.fBio}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Comment`}
                      placeholder={"Enter Comment"}
                      onChange={(e) =>
                        handlePostChange(e, "Comment Is Required !")
                      }
                      row={2}
                      value={postInput?.fComment}
                      name={"fComment"}
                      errorMessage={error.fComment && error.fComment}
                    />
                  </div>
                  <div className="input-post">
                    <TextArea
                      label={`Link`}
                      placeholder={"Enter Link"}
                      onChange={(e) =>
                        handlePostChange(e, "Link Is Required !")
                      }
                      row={2}
                      value={postInput?.fImage}
                      name={"fImage"}
                      errorMessage={error.fImage && error.fImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row dialog-footer mt-2">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  style={{ marginRight: "10px" }}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { createPost, editPost })(PostDialogue);
