import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";


const initialState = {
    privateVideo:[],
    isLoading:false,
    isSkeleton:false,
    total:null
}


export const getPrivateVideo = createAsyncThunk("admin/privateVideo/getPrivateVideos",async (payload)=>{

    return apiInstanceFetch.get(`admin/privateVideo/getPrivateVideos?start=${payload?.start}&limit=${payload?.limit}`)
})

export const createPrivateVideo = createAsyncThunk("admin/privateVideo/createPrivateVideo", async (payload) =>{
    return apiInstance.post("admin/privateVideo/createPrivateVideo",payload)
})

export const deletePrivateVideo = createAsyncThunk("admin/privateVideo/deletePrivateVideo", async (id) =>{
    
    return apiInstanceFetch.delete(`admin/privateVideo/deletePrivateVideo?privateVideoId=${id}`)
})

const privateVideoSlice = createSlice({
    name:"privateVideoSlice",
    initialState,
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(getPrivateVideo.pending,(state,action) =>{
            state.isSkeleton = true;
        })

        builder.addCase(getPrivateVideo.fulfilled,(state,action) =>{
            state.isSkeleton = false;
            state.privateVideo = action?.payload?.privateVideo
            state.total = action?.payload?.totalPrivateVideo
        })

        
        builder.addCase(getPrivateVideo.rejected,(state,action) =>{
            state.isSkeleton = false;
        })

        builder.addCase(createPrivateVideo.pending,(state,action) =>{
            state.isLoading = true;
        })

        builder.addCase(createPrivateVideo.fulfilled,(state,action) =>{
            state.isLoading = false;
            state.privateVideo = [...action?.payload?.privateVideo, ...state.privateVideo];
            setToast("success","Added Private Video Successfully");
        })

        
        builder.addCase(createPrivateVideo.rejected,(state,action) =>{
            state.isLoading = false;
        })

        
    builder.addCase(deletePrivateVideo.pending, (state, action) => {
        state.isLoading = true;
      })
  
      builder.addCase(deletePrivateVideo.fulfilled, (state, action) => {
        state.privateVideo = state.privateVideo.filter((privateVideo) => privateVideo._id !== action.meta.arg);
        state.isLoading = false;
        setToast("success","Deleted Private Video Successfully");
  
      })
      builder.addCase(deletePrivateVideo.rejected, (state, action) => {
        state.isLoading = false;
      })
    }
})
export default privateVideoSlice.reducer;