import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  moreApp: [],
  moreAppAll:[],
  total: null,
  isLoading: false,
  isSkeleton: false,
};

export const getMoreApp = createAsyncThunk(
  "admin/moreApp/moreAppList",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/moreApp/moreAppList?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const getMoreAppAll = createAsyncThunk(
  "admin/moreApp/moreApps",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/moreApp/moreApps?appId=${payload}`
    );
  }
);


export const createMoreApp = createAsyncThunk(
  "admin/moreApp/createmoreApp",
  async (payload) => {
    return apiInstance.post("admin/moreApp/createmoreApp", payload);
  }
);

export const updateMoreApp = createAsyncThunk(
  "admin/moreApp/updateMoreApp",
  async (payload) => {
    return apiInstance.patch(
      `admin/moreApp/updateMoreApp?moreAppId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteMoreApp = createAsyncThunk(
  "admin/moreApp/deleteMoreApp",
  async (id) => {
    return apiInstanceFetch.delete(`admin/moreApp/deleteMoreApp?moreAppId=${id}`);
  }
);

const moreAppSlice = createSlice({
  name: "moreApp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMoreApp.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getMoreApp.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.moreApp = action.payload.moreApp;
      state.total = action.payload.totalMoreApps;
    });
    builder.addCase(getMoreApp.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getMoreAppAll.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getMoreAppAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.moreAppAll = action.payload.moreApp;
    });
    builder.addCase(getMoreAppAll.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createMoreApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createMoreApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.moreApp.unshift(action?.payload?.moreApp);
      setToast("success", "App Added Successfully");
    });

    builder.addCase(createMoreApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateMoreApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateMoreApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        const updatedApp = action?.payload?.moreApp;
        const appIndex = state?.moreApp?.findIndex(
          (ad) => ad?._id === updatedApp?._id
        );
        if (appIndex !== -1) {
          state.moreApp[appIndex] = {
            ...state.moreApp[appIndex],
            ...action?.payload?.moreApp,
          };
        }
        setToast("success", "App Updated Successfully");
      }
    });

    builder.addCase(deleteMoreApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteMoreApp.fulfilled, (state, action) => {
      state.moreApp = state.moreApp.filter(
        (moreApp) => moreApp._id !== action.meta.arg
      );
      state.isLoading = false;
      setToast("success", "App Deleted  Successfully");
    });
    builder.addCase(deleteMoreApp.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default moreAppSlice.reducer;
