import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
const initialState = {
  developer: [],
  total: null,
  isSkeleton: false,
  isLoading: false,
  dev:{},
  app:[]
};

export const devLogin = createAsyncThunk(
  "developer/developer/post",
  async (payload) => {
    
    const encodedPassword = encodeURIComponent(payload?.password);
    return apiInstanceFetch.post(
      `developer/developer/login?name=${payload?.name}&password=${encodedPassword}`
    );
  }
);


export const getDevelopers = createAsyncThunk(
  "developer/developer/getAll",
  async (payload) => {
    return apiInstanceFetch.get(
      `developer/developer/getAll?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const addDeveloper = createAsyncThunk(
  "developer/developer/create",
  async (payload) => {
    return apiInstance.post("developer/developer/create", payload);
  }
);

export const updateDeveloper = createAsyncThunk(
  "developer/developer/update",
  async (payload) => {
    return apiInstance.patch(
      `developer/developer/update?developerId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteDeveloper = createAsyncThunk(
  "developer/developer/delete",
  async (payload) => {
    return apiInstanceFetch.delete(
      `developer/developer/delete?developerId=${payload?.id}`
    );
  }
);



const developerSlice = createSlice({
  name: "developer",
  initialState,
  reducers: {
    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      sessionStorage.removeItem("adminToken");
      state.dev = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(devLogin.pending, (state, action) => {
      state.isLoading = false;

    });
    builder.addCase(devLogin.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        
        let token_ = action.payload.token && jwt_decode(action.payload.token);
        state.dev = token_;
        state.isAuth = true;
        state.isLoading = false;
        SetDevKey(key);
        setToken(token_ ? JSON.stringify(token_) : undefined);
        sessionStorage.setItem(
          "adminToken",
          JSON.stringify(action.payload.token)
        );
        sessionStorage.setItem(
          "token",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem("key", key ? key : undefined);
        sessionStorage.setItem("user", "dev");
        sessionStorage.setItem("isAuth", true);
        window.location.href = "/dev";
      } else {
        setToast("error", action.payload?.message);
        console.log("action.payload", action.payload);
        state.isLoading = false;
      }
    });
    builder.addCase(devLogin.rejected, (state, action) => {
      
      state.isSkeleton = false;
      state.isLoading = false;
    });

    builder.addCase(getDevelopers.pending, (state, action) => {
      state.isSkeleton = true;
      state.isLoading = false;

    });
    builder.addCase(getDevelopers.fulfilled, (state, action) => {
      state.developer = action?.payload?.developer;
      state.total = action?.payload?.totalDevelopers;
      state.isSkeleton = false;
      state.isLoading = false;

    });
    builder.addCase(getDevelopers.rejected, (state, action) => {
      state.isSkeleton = false;
      state.isLoading = false;
    });

   

    builder.addCase(addDeveloper.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addDeveloper.fulfilled, (state, action) => {
      state.isLoading = false;
      state.developer.unshift(action?.payload?.developer);
      setToast("success", "Devloper Added  Successfully");
    });

    builder.addCase(addDeveloper.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateDeveloper.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateDeveloper.fulfilled, (state, action) => {
      const updatedDev = action?.payload?.developer;
      const devIndex = state?.developer?.findIndex(
        (dev) => dev?._id === updatedDev?._id
      );
      if (devIndex !== -1) {
        state.developer[devIndex] = {
          ...state.developer[devIndex],
          ...action?.payload?.developer,
        };
      }
      state.isLoading = false;
      setToast(
        "success",
        `${updatedDev?.name && updatedDev?.name}` +
          " " +
          "Devoper Update  Successfully"
      );
    });
    builder.addCase(updateDeveloper.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteDeveloper.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteDeveloper.fulfilled, (state, action) => {
      state.developer = state.developer.filter(
        (developer) =>
          developer._id !== action.meta.arg?.id && action.meta.arg?.id
      );
      state.isLoading = false;
      setToast(
        "success",
        `${action.meta.arg?.name && action.meta.arg?.name}` +
          " " +
          "Deleted Successfully"
      );
    });
    builder.addCase(deleteDeveloper.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default developerSlice.reducer;
