import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { addNewApp, getDevloper, updateApp } from "../../store/slice/appSlice";
import Input from "../extra/Input";
import ToggleSwitch from "../extra/ToggleSwitch";
import Button from "../extra/Button";
import ReactSelect from "react-select";

const NewAppCreate = (props) => {
  const { devloperAll } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [appName, setAppName] = useState("");
  const [packageName, setPackageName] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [logoImageShow, setLogoImageShow] = useState();
  const [getDevloperData, setGetDevloperData] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState(""); 
  const [appTestSwitch, setAppTestSwitch] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState({
    appName: "",
    packageName: "",
    logoImage: "",
    selectedDeveloper: "",
  });

  useEffect(() => {
    props.getDevloper();
  }, []);

  useEffect(() => {
    setGetDevloperData(devloperAll);
  }, [devloperAll]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleFileUpload = (e) => {
    setLogoImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setLogoImageShow(showLogo);
  };

  const handleAutocompleteChange = (selected) => {
    setSelectedOptions(selected);
    const selectedIds = selected?.map((option) => option?._id);
    const updatedData = selectedIds?.join(",");
    setSelectedDeveloper(updatedData);
  };

  const handleSubmit = (e) => {
    if (
      !appName ||
      !packageName ||
      !logoImage ||
      selectedDeveloper?.length === 0
    ) {
      let error = {};
      if (!appName) error.appName = "App Name Is Required !";
      if (!logoImage) error.logoImage = "Logo  Is Required !";
      if (!packageName) error.packageName = "Package Name  Is Required !";
      if (selectedDeveloper?.length === 0)
        error.selectedDeveloper = "Devloper Is Required !";

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("appName", appName);
      formData.append("packageName", packageName);
      formData.append("isTestAdId", appTestSwitch);
      formData.append("developerId", selectedDeveloper);
      formData.append("appLogo", logoImage);
      if (formData) {
        dispatch(addNewApp(formData));
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="new-appCreate">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>Create New App</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12">
                  <Input
                    label={`App Name`}
                    id={`enterAppName`}
                    type={`appName`}
                    value={appName}
                    errorMessage={error.appName && error.appName}
                    onChange={(e) => {
                      setAppName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          appName: `App Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          appName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12">
                  <Input
                    label={`Package Name`}
                    id={`packageName`}
                    type={`packageName`}
                    value={packageName}
                    errorMessage={error.packageName && error.packageName}
                    onChange={(e) => {
                      setPackageName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          packageName: `Package Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          packageName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 devloper-select">
                  <label className="label-input">Devloper</label>
                  <ReactSelect
                    isMulti
                    options={getDevloperData || []}
                    value={selectedOptions}
                    onChange={handleAutocompleteChange}
                    // getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.name}
                    formatOptionLabel={(option) => (
                      <div className="moreApp-option">
                        <span>{option.name}</span>
                      </div>
                    )}
                  />
                  <p className="errorMessage">{error.selectedDeveloper}</p>
                </div>
                <div className="col-6 image-input mt-2">
                  <label className="label-input">App Logo</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className="form-input"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={handleFileUpload}
                  />
                  <p className="errorMessage">{error.logoImage}</p>
                </div>
                <div className="col-6 imgShow">
                  {logoImageShow && <img src={logoImageShow} />}
                </div>
                <div className="col-12 mt-3">
                  <label className="label-input">Test Ad</label>
                  <ToggleSwitch
                    value={appTestSwitch}
                    onChange={setAppTestSwitch}
                  />
                </div>
              </div>
            </div>
            <div className="row dialog-footer">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { getDevloper })(NewAppCreate);
