import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAppPlan, planUpdate, addPlan } from "../../../store/slice/appSlice";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import { closeDialog } from "../../../store/slice/dialogueSlice";
import { baseURL } from "../../utils/config";

const PlanEdit = (props) => {
  const location = useLocation();
  const selectTimeData = ["Second", "Minute", "Hour", "Day", "Month", "Year","Life Time"];
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const [planInput, setPlanEdit] = useState({
    name: "",
    productKey: "",
    contract: "",
    rupee: "",
    monthlyEarning: "",
    serverName: "",
    usd: "",
    description: "",
    selectTime: "",
  });

  const [cInput, setCInput] = useState({
    c1MiningTime: 0,
    c1EarningRate: "",
    c1EarningSpeed: "",
    c1EarningSpeedName: "",
    c1CoinName: "",
    c1Algorithm: "",

    c2MiningTime: 0,
    c2EarningRate: "",
    c2EarningSpeed: "",
    c2EarningSpeedName: "",
    c2Algorithm: "",
    c2CoinName: "",

    c3MiningTime: 0,
    c3EarningRate: "",
    c3EarningSpeed: "",
    c3EarningSpeedName: "",
    c3Algorithm: "",
    c3CoinName: "",

    c4MiningTime: 0,
    c4EarningRate: "",
    c4EarningSpeed: "",
    c4EarningSpeedName: "",
    c4Algorithm: "",
    c4CoinName: "",

    c5MiningTime: 0,
    c5EarningRate: "",
    c5EarningSpeed: "",
    c5EarningSpeedName: "",
    c5Algorithm: "",
    c5CoinName: "",

    c6MiningTime: 0,
    c6EarningRate: "",
    c6EarningSpeed: "",
    c6EarningSpeedName: "",
    c6CoinName: "",
    c6Algorithm: "",
  });

  const [imageShow, setImageShow] = useState();
  const [image, setImage] = useState("");
  const { appPlan } = useSelector((state) => state.app);
  const { planData, totalPlans } = useSelector((state) => state.app);
  const [appPlanData, setAppPlanData] = useState();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    name: "",
    productKey: "",
    contract: "",
    rupee: "",
    monthlyEarning: "",
    serverName: "",
    usd: "",
    description: "",
    selectTime: "",
    c1MiningTime: "",
    c1EarningRate: "",
    c1EarningSpeed: "",
    c1EarningSpeedName: "",
    c1CoinName: "",
    c1Algorithm: "",

    c2MiningTime: "",
    c2EarningRate: "",
    c2EarningSpeed: "",
    c2EarningSpeedName: "",
    c2Algorithm: "",
    c2CoinName: "",

    c3MiningTime: "",
    c3EarningRate: "",
    c3EarningSpeed: "",
    c3EarningSpeedName: "",
    c3Algorithm: "",
    c3CoinName: "",

    c4MiningTime: "",
    c4EarningRate: "",
    c4EarningSpeed: "",
    c4EarningSpeedName: "",
    c4Algorithm: "",
    c4CoinName: "",

    c5MiningTime: "",
    c5EarningRate: "",
    c5EarningSpeed: "",
    c5EarningSpeedName: "",
    c5Algorithm: "",
    c5CoinName: "",

    c6MiningTime: "",
    c6EarningRate: "",
    c6EarningSpeed: "",
    c6EarningSpeedName: "",
    c6CoinName: "",
    c6Algorithm: "",
  });

  const handlePlanChange = (e, errorMessage) => {
    const { name, value } = e.target;
    setPlanEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [name]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleChange = (e, errorMessage) => {
    const { name, value } = e.target;
    setCInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [name]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const handleFileUpload = (e) => {
    setImage(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setImageShow(showLogo);

    if (!e.target.files[0]) {
      setError({
        ...error,
        image: "Image is required !",
      });
    } else {
      setError({
        ...error,
        image: "",
      });
    }
  };

  useEffect(() => {
    if (dialogueData) {
      const getPlanFilter = planData?.filter(
        (item) => item?._id === dialogueData?._id
      );
      const getPlan = getPlanFilter && getPlanFilter[0];
      setAppPlanData(getPlan);
    }
  }, [dialogueData,planData]);

  useEffect(() => {
    if (appPlanData) {
      setPlanEdit({
        name: appPlanData?.name,
        productKey: appPlanData?.productKey,
        contract: appPlanData?.contract,
        rupee: appPlanData?.rupee,
        monthlyEarning: appPlanData?.monthlyEarning,
        serverName: appPlanData?.serverName,
        usd: appPlanData?.dollar,
        description: appPlanData?.description,
        selectTime: appPlanData?.contractTime,
      });
      setImageShow(baseURL+ "/"+appPlanData?.image);

      setCInput({
        c1MiningTime: appPlanData?.C1?.miningTimeC1,
        c1EarningRate: appPlanData?.C1?.earningRateC1,
        c1EarningSpeed: appPlanData?.C1?.earningSpeedC1,
        c1EarningSpeedName: appPlanData?.C1?.earningSpeedNameC1,
        c1CoinName: appPlanData?.C1?.coinNameC1,
        c1Algorithm: appPlanData?.C1?.algorithmC1,

        c2MiningTime: appPlanData?.C2?.miningTimeC2,
        c2EarningRate: appPlanData?.C2?.earningRateC2,
        c2EarningSpeed: appPlanData?.C2?.earningSpeedC2,
        c2EarningSpeedName: appPlanData?.C2?.earningSpeedNameC2,
        c2CoinName: appPlanData?.C2?.coinNameC2,
        c2Algorithm: appPlanData?.C2?.algorithmC2,

        c3MiningTime: appPlanData?.C3?.miningTimeC3,
        c3EarningRate: appPlanData?.C3?.earningRateC3,
        c3EarningSpeed: appPlanData?.C3?.earningSpeedC3,
        c3EarningSpeedName: appPlanData?.C3?.earningSpeedNameC3,
        c3CoinName: appPlanData?.C3?.coinNameC3,
        c3Algorithm: appPlanData?.C3?.algorithmC3,

        c4MiningTime: appPlanData?.C4?.miningTimeC4,
        c4EarningRate: appPlanData?.C4?.earningRateC4,
        c4EarningSpeed: appPlanData?.C4?.earningSpeedC4,
        c4EarningSpeedName: appPlanData?.C4?.earningSpeedNameC4,
        c4CoinName: appPlanData?.C4?.coinNameC4,
        c4Algorithm: appPlanData?.C4?.algorithmC4,

        c5MiningTime: appPlanData?.C5?.miningTimeC5,
        c5EarningRate: appPlanData?.C5?.earningRateC5,
        c5EarningSpeed: appPlanData?.C5?.earningSpeedC5,
        c5EarningSpeedName: appPlanData?.C5?.earningSpeedNameC5,
        c5CoinName: appPlanData?.C5?.coinNameC5,
        c5Algorithm: appPlanData?.C5?.algorithmC5,

        c6MiningTime: appPlanData?.C6?.miningTimeC6,
        c6EarningRate: appPlanData?.C6?.earningRateC6,
        c6EarningSpeed: appPlanData?.C6?.earningSpeedC6,
        c6EarningSpeedName: appPlanData?.C6?.earningSpeedNameC6,
        c6CoinName: appPlanData?.C6?.coinNameC6,
        c6Algorithm: appPlanData?.C6?.algorithmC6,
      });
    }
  }, [appPlanData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", planInput?.name);
    formData.append("productKey", planInput?.productKey);
    formData.append("contract", planInput?.contract);
    formData.append("contractTime", planInput?.selectTime);
    formData.append("rupee", planInput?.rupee);
    formData.append("monthlyEarning", planInput?.monthlyEarning);
    formData.append("serverName", planInput?.serverName);
    formData.append("dollar", planInput?.usd);
    formData.append("description", planInput?.description);
    formData.append("selectTime", planInput?.selectTime);
    formData.append("image", image);

    formData.append("miningTimeC1", cInput?.c1MiningTime);
    formData.append("earningRateC1", cInput?.c1EarningRate);
    formData.append("earningSpeedC1", cInput?.c1EarningSpeed);
    formData.append("earningSpeedNameC1", cInput?.c1EarningSpeedName);
    formData.append("algorithmC1", cInput?.c1Algorithm);
    formData.append("coinNameC1", cInput?.c1CoinName);

    formData.append("miningTimeC2", cInput?.c2MiningTime);
    formData.append("earningRateC2", cInput?.c2EarningRate);
    formData.append("earningSpeedC2", cInput?.c2EarningSpeed);
    formData.append("earningSpeedNameC2", cInput?.c2EarningSpeedName);
    formData.append("algorithmC2", cInput?.c2Algorithm);
    formData.append("coinNameC2", cInput?.c2CoinName);

    formData.append("miningTimeC3", cInput?.c3MiningTime);
    formData.append("earningRateC3", cInput?.c3EarningRate);
    formData.append("earningSpeedC3", cInput?.c3EarningSpeed);
    formData.append("earningSpeedNameC3", cInput?.c3EarningSpeedName);
    formData.append("algorithmC3", cInput?.c3Algorithm);
    formData.append("coinNameC3", cInput?.c3CoinName);

    formData.append("miningTimeC4", cInput?.c4MiningTime);
    formData.append("earningRateC4", cInput?.c4EarningRate);
    formData.append("earningSpeedC4", cInput?.c4EarningSpeed);
    formData.append("earningSpeedNameC4", cInput?.c4EarningSpeedName);
    formData.append("algorithmC4", cInput?.c4Algorithm);
    formData.append("coinNameC4", cInput?.c4CoinName);

    formData.append("miningTimeC5", cInput?.c5MiningTime);
    formData.append("earningRateC5", cInput?.c5EarningRate);
    formData.append("earningSpeedC5", cInput?.c5EarningSpeed);
    formData.append("earningSpeedNameC5", cInput?.c5EarningSpeedName);
    formData.append("algorithmC5", cInput?.c5Algorithm);
    formData.append("coinNameC5", cInput?.c5CoinName);

    formData.append("miningTimeC6", cInput?.c6MiningTime);
    formData.append("earningRateC6", cInput?.c6EarningRate);
    formData.append("earningSpeedC6", cInput?.c6EarningSpeed);
    formData.append("earningSpeedNameC6", cInput?.c6EarningSpeedName);
    formData.append("algorithmC6", cInput?.c6Algorithm);
    formData.append("coinNameC6", cInput?.c6CoinName);

    if (dialogueData) {
      let payload = {
        data: formData,
        planId: appPlanData?._id,
        appId: location?.state?.editAppData?._id,
      };
      props.planUpdate(payload);
      dispatch(closeDialog());
    } else {
      if (
        (!planInput?.name,
        !planInput?.productKey,
        !planInput?.contract,
        !planInput?.rupee,
        !planInput?.monthlyEarning,
        !planInput?.serverName,
        !planInput?.usd,
        !planInput?.description,
        !planInput?.selectTime)
        // !cInput?.c1MiningTime,
        // !cInput?.c1EarningRate,
        // !cInput?.c1EarningSpeed,
        // !cInput?.c1EarningSpeedName,
        // !cInput?.c1CoinName,
        // !cInput?.c1Algorithm,
        // !cInput?.c2MiningTime,
        // !cInput?.c2EarningRate,
        // !cInput?.c2EarningSpeed,
        // !cInput?.c2EarningSpeedName,
        // !cInput?.c2Algorithm,
        // !cInput?.c2CoinName,
        // !cInput?.c3MiningTime,
        // !cInput?.c3EarningRate,
        // !cInput?.c3EarningSpeed,
        // !cInput?.c3EarningSpeedName,
        // !cInput?.c3Algorithm,
        // !cInput?.c3CoinName,
        // !cInput?.c4MiningTime,
        // !cInput?.c4EarningRate,
        // !cInput?.c4EarningSpeed,
        // !cInput?.c4EarningSpeedName,
        // !cInput?.c4Algorithm,
        // !cInput?.c4CoinName,
        // !cInput?.c5MiningTime,
        // !cInput?.c5EarningRate,
        // !cInput?.c5EarningSpeed,
        // !cInput?.c5EarningSpeedName,
        // !cInput?.c5Algorithm,
        // !cInput?.c5CoinName,
        // !cInput?.c6MiningTime,
        // !cInput?.c6EarningRate,
        // !cInput?.c6EarningSpeed,
        // !cInput?.c6EarningSpeedName,
        // !cInput?.c6CoinName,
        // !cInput?.c6Algorithm
      ) {
        let error = {};
        if (!planInput?.name) error.name = "Name Is Required !";
        if (!planInput?.productKey)
          error.productKey = "Product Key Is Required !";
        if (!planInput?.contract) error.contract = "Contract Is Required !";
        if (!planInput?.selectTime)
          error.selectTime = "Select Time Is Required !";
        if (!planInput?.monthlyEarning)
          error.monthlyEarning = "Monthly Earning Is Required !";
        if (!planInput?.rupee) error.rupee = "Rupee Is Required !";
        if (!planInput?.usd) error.usd = "USD Is Required !";
        if (!planInput?.serverName)
          error.serverName = "Server Name Is Required !";
        if (!planInput?.description)
          error.description = "Description Is Required !";
        if (!imageShow) error.image = "Image is required !";

        // if (!cInput?.c1CoinName) error.c1CoinName = "Coin Name is required !";
        // if (!cInput?.c1MiningTime) error.c1MiningTime = "Mining Time is required !";
        // if (!cInput?.c1EarningRate) error.c1EarningRate = "Earning Rate is required !";
        // if (!cInput?.c1EarningSpeed) error.c1EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c1EarningSpeedName) error.c1EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c1Algorithm) error.c1Algorithm = "Algorithm is required !";

        // if (!cInput?.c2CoinName) error.c2CoinName = "Coin Name is required !";
        // if (!cInput?.c2MiningTime) error.c2MiningTime = "Mining Time is required !";
        // if (!cInput?.c2EarningRate) error.c2EarningRate = "Earning Rate is required !";
        // if (!cInput?.c2EarningSpeed) error.c2EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c2EarningSpeedName) error.c2EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c2Algorithm) error.c2Algorithm = "Algorithm is required !";

        // if (!cInput?.c3CoinName) error.c3CoinName = "Coin Name is required !";
        // if (!cInput?.c3MiningTime) error.c3MiningTime = "Mining Time is required !";
        // if (!cInput?.c3EarningRate) error.c3EarningRate = "Earning Rate is required !";
        // if (!cInput?.c3EarningSpeed) error.c3EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c3EarningSpeedName) error.c3EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c3Algorithm) error.c3Algorithm = "Algorithm is required !";

        // if (!cInput?.c4CoinName) error.c4CoinName = "Coin Name is required !";
        // if (!cInput?.c4MiningTime) error.c4MiningTime = "Mining Time is required !";
        // if (!cInput?.c4EarningRate) error.c4EarningRate = "Earning Rate is required !";
        // if (!cInput?.c4EarningSpeed) error.c4EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c4EarningSpeedName) error.c4EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c4Algorithm) error.c4Algorithm = "Algorithm is required !";

        // if (!cInput?.c5CoinName) error.c5CoinName = "Coin Name is required !";
        // if (!cInput?.c5MiningTime) error.c5MiningTime = "Mining Time is required !";
        // if (!cInput?.c5EarningRate) error.c5EarningRate = "Earning Rate is required !";
        // if (!cInput?.c5EarningSpeed) error.c5EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c5EarningSpeedName) error.c5EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c5Algorithm) error.c5Algorithm = "Algorithm is required !";

        // if (!cInput?.c6CoinName) error.c6CoinName = "Coin Name is required !";
        // if (!cInput?.c6MiningTime) error.c6MiningTime = "Mining Time is required !";
        // if (!cInput?.c6EarningRate) error.c6EarningRate = "Earning Rate is required !";
        // if (!cInput?.c6EarningSpeed) error.c6EarningSpeed = "Earning Speed is required !";
        // if (!cInput?.c6EarningSpeedName) error.c6EarningSpeedName = "Earning Speed Name is required !";
        // if (!cInput?.c6Algorithm) error.c6Algorithm = "Algorithm is required !";

        return setError({ ...error });
      } else {
        let payload = {
          data: formData,
          appId: location?.state?.editAppData?._id,
        };
        props.addPlan(payload);
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="plan-edit">
      <form>
        <div className="col-12 d-flex justify-content-end mb-3">
          <Button
            btnIcon={<ArrowBackIcon />}
            type={"button"}
            style={{ marginRight: "10px" }}
            onClick={() => dispatch(closeDialog())}
            newClass={"back-btn"}
          />

          <Button
            btnName={"Submit"}
            type={"submit"}
            newClass={"submit-btn"}
            onClick={handleSubmit}
          />
        </div>
        <div className="row plan-edit-left">
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Name"}
              type={`text`}
              name={"name"}
              value={planInput?.name}
              errorMessage={error.name && error.name}
              onChange={(e) => handlePlanChange(e, "Name Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Contract"}
              type={`text`}
              name={"contract"}
              value={planInput?.contract}
              errorMessage={error.contract && error.contract}
              onChange={(e) => handlePlanChange(e, "Contract Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4  select-time-selector">
            <h6>Select Time</h6>
            <select
              value={planInput?.selectTime}
              name="selectTime"
              onChange={(e) => handlePlanChange(e, "Select Time Is Required !")}
            >
              {selectTimeData?.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
            {error?.selectTime && (
              <p className="errorMessage">{error?.selectTime}</p>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Server Name"}
              type={`text`}
              name={"serverName"}
              value={planInput?.serverName}
              errorMessage={error.serverName && error.serverName}
              onChange={(e) => handlePlanChange(e, "Server Name Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"USD"}
              type={`number`}
              name={"usd"}
              value={planInput?.usd}
              errorMessage={error.usd && error.usd}
              onChange={(e) => handlePlanChange(e, "USD Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Rupee"}
              type={`number`}
              name={"rupee"}
              value={planInput?.rupee}
              errorMessage={error.rupee && error.rupee}
              onChange={(e) => handlePlanChange(e, "Rupee Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Product Key"}
              type={`text`}
              name={"productKey"}
              value={planInput?.productKey}
              errorMessage={error.productKey && error.productKey}
              onChange={(e) => handlePlanChange(e, "Product Key Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Monthly Earning"}
              type={`text`}
              name={"monthlyEarning"}
              value={planInput?.monthlyEarning}
              errorMessage={error.monthlyEarning && error.monthlyEarning}
              onChange={(e) =>
                handlePlanChange(e, "Monthly Earning Is Required !")
              }
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 ">
            <Input
              label={"Description"}
              type={`text`}
              name={"description"}
              value={planInput?.description}
              errorMessage={error.description && error.description}
              onChange={(e) => handlePlanChange(e, "Description Is Required !")}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4  image-input">
            <label className="label-input">Images</label>
            <input
              type="file"
              accept="image/gif, image/jpeg, image/png"
              name="image"
              id="image"
              className="form-input"
              onChange={handleFileUpload}
            />
            {imageShow && <img src={imageShow} />}
            {error?.image && <p className="errorMessage">{error?.image}</p>}
          </div>
        </div>
        <div className="row plan-edit-right mt-3">
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C1</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c1CoinName"}
                    value={cInput?.c1CoinName}
                    errorMessage={error.c1CoinName && error.c1CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c1MiningTime"}
                    value={cInput?.c1MiningTime}
                    errorMessage={error.c1MiningTime && error.c1MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c1EarningRate"}
                    value={cInput?.c1EarningRate}
                    errorMessage={error.c1EarningRate && error.c1EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c1EarningSpeed"}
                    value={cInput?.c1EarningSpeed}
                    errorMessage={error.c1EarningSpeed && error.c1EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c1EarningSpeedName"}
                    value={cInput?.c1EarningSpeedName}
                    errorMessage={
                      error.c1EarningSpeedName && error.c1EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed Name is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c1Algorithm"}
                    value={cInput?.c1Algorithm}
                    errorMessage={error.c1Algorithm && error.c1Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C2</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c2CoinName"}
                    value={cInput?.c2CoinName}
                    errorMessage={error.c2CoinName && error.c2CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c2MiningTime"}
                    value={cInput?.c2MiningTime}
                    errorMessage={error.c2MiningTime && error.c2MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c2EarningRate"}
                    value={cInput?.c2EarningRate}
                    errorMessage={error.c2EarningRate && error.c2EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c2EarningSpeed"}
                    value={cInput?.c2EarningSpeed}
                    errorMessage={error.c2EarningSpeed && error.c2EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c2EarningSpeedName"}
                    value={cInput?.c2EarningSpeedName}
                    errorMessage={
                      error.c2EarningSpeedName && error.c2EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed Name is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c2Algorithm"}
                    value={cInput?.c2Algorithm}
                    errorMessage={error.c2Algorithm && error.c2Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C3</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c3CoinName"}
                    value={cInput?.c3CoinName}
                    errorMessage={error.c3CoinName && error.c3CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c3MiningTime"}
                    value={cInput?.c3MiningTime}
                    errorMessage={error.c3MiningTime && error.c3MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c3EarningRate"}
                    value={cInput?.c3EarningRate}
                    errorMessage={error.c3EarningRate && error.c3EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c3EarningSpeed"}
                    value={cInput?.c3EarningSpeed}
                    errorMessage={error.c3EarningSpeed && error.c3EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c3EarningSpeedName"}
                    value={cInput?.c3EarningSpeedName}
                    errorMessage={
                      error.c3EarningSpeedName && error.c3EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed Name is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c3Algorithm"}
                    value={cInput?.c3Algorithm}
                    errorMessage={error.c3Algorithm && error.c3Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C4</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c4CoinName"}
                    value={cInput?.c4CoinName}
                    errorMessage={error.c4CoinName && error.c4CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c4MiningTime"}
                    value={cInput?.c4MiningTime}
                    errorMessage={error.c4MiningTime && error.c4MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c4EarningRate"}
                    value={cInput?.c4EarningRate}
                    errorMessage={error.c4EarningRate && error.c4EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c4EarningSpeed"}
                    value={cInput?.c4EarningSpeed}
                    errorMessage={error.c4EarningSpeed && error.c4EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c4EarningSpeedName"}
                    value={cInput?.c4EarningSpeedName}
                    errorMessage={
                      error.c4EarningSpeedName && error.c4EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed Name is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c4Algorithm"}
                    value={cInput?.c4Algorithm}
                    errorMessage={error.c4Algorithm && error.c4Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C5</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c5CoinName"}
                    value={cInput?.c5CoinName}
                    errorMessage={error.c5CoinName && error.c5CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c5MiningTime"}
                    value={cInput?.c5MiningTime}
                    errorMessage={error.c5MiningTime && error.c5MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c5EarningRate"}
                    value={cInput?.c5EarningRate}
                    errorMessage={error.c5EarningRate && error.c5EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c5EarningSpeed"}
                    value={cInput?.c5EarningSpeed}
                    errorMessage={error.c5EarningSpeed && error.c5EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c5EarningSpeedName"}
                    value={cInput?.c5EarningSpeedName}
                    errorMessage={
                      error.c5EarningSpeedName && error.c5EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed Name is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c5Algorithm"}
                    value={cInput?.c5Algorithm}
                    errorMessage={error.c5Algorithm && error.c5Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mt-3">
            <div className="cInput-box">
              <h6>C6</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={"Coin Name"}
                    type={`text`}
                    name={"c6CoinName"}
                    value={cInput?.c6CoinName}
                    errorMessage={error.c6CoinName && error.c6CoinName}
                    onChange={(e) => handleChange(e, "Coin Name is required !")}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Mining Time"}
                    type={`number`}
                    name={"c6MiningTime"}
                    value={cInput?.c6MiningTime}
                    errorMessage={error.c6MiningTime && error.c6MiningTime}
                    onChange={(e) =>
                      handleChange(e, "Mining Time is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Rate"}
                    type={`number`}
                    name={"c6EarningRate"}
                    value={cInput?.c6EarningRate}
                    errorMessage={error.c6EarningRate && error.c6EarningRate}
                    onChange={(e) =>
                      handleChange(e, "Earning Rate is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed"}
                    type={`number`}
                    name={"c6EarningSpeed"}
                    value={cInput?.c6EarningSpeed}
                    errorMessage={error.c6EarningSpeed && error.c6EarningSpeed}
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Earning Speed Name"}
                    type={`text`}
                    name={"c6EarningSpeedName"}
                    value={cInput?.c6EarningSpeedName}
                    errorMessage={
                      error.c6EarningSpeedName && error.c6EarningSpeedName
                    }
                    onChange={(e) =>
                      handleChange(e, "Earning Speed is required !")
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={"Algorithm"}
                    type={`text`}
                    name={"c6Algorithm"}
                    value={cInput?.c6Algorithm}
                    errorMessage={error.c6Algorithm && error.c6Algorithm}
                    onChange={(e) => handleChange(e, "Algorithm is required !")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(null, { getAppPlan, planUpdate, addPlan })(PlanEdit);
