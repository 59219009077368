import React from "react";
import Title from "../../extra/Title";
import { useState } from "react";
import ImmediatelyNotifi from "./ImmediatelyNotifi";
import SpecifiedNotification from "./SpecifiedNotification";
import { Box, Tab, Tabs } from "@mui/material";
import SpecifiedNotificationData from "./SpecifiedNotificationData";

export default function NotificationPage() {
  const getValue = localStorage.getItem("notificationTable");
  const [changeTable, setChangeTable] = useState(
    Number(getValue) ? Number(getValue) : 0
  );

  const handleChange = (event, newValue) => {
    setChangeTable(newValue);
    localStorage.setItem("notificationTable", newValue);
  };

  const renderEditComponent = React.useCallback(() => {
    switch (changeTable) {
      case 0:
        return <ImmediatelyNotifi />;
      case 1:
        return <SpecifiedNotification />;
      case 2:
        return <SpecifiedNotificationData />;
      default:
        break;
    }
  }, [changeTable]);

  return (
    <div className="notification-page">
      <Title title={"Notification"} />
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={changeTable}
          onChange={handleChange}
          aria-label="icon tabs example"
        >
          <Tab
            // icon={<AddDetailsIcon />}
            iconPosition="start"
            label="Immediately Notification"
          />
          <Tab
            // icon={<MonetizationIcon />}
            iconPosition="start"
            label="Specified Notification"
          />
          <Tab
            // icon={<MonetizationIcon />}
            iconPosition="start"
            label="Specified Notification Data"
          />
        </Tabs>
      </Box>
      <div className="edit-renderComponent">{renderEditComponent()}</div>
    </div>
  );
}
