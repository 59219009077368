import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// toast.configure();

// export const setToast = (type, data,options) => {
//   return toast(data, {
//     type: type,
//     position: "top-right",
//     autoClose: 2000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     rtl: false,
//     ...options
//   });
// };

let currentToast = null;

export const setToast = (type, data) => {
  if (currentToast) {
    toast.update(currentToast, {
      render: data,
      type,
      autoClose: 2000,
    });
  } else {
    currentToast = toast(data, {
      type,
      autoClose: 2000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: false,
      onClose: () => {
        currentToast = null;
      },
    });
  }
};


 