import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  shortVideo: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
};

export const getAllShortVideo = createAsyncThunk(
  "admin/shortVideo/getShortVideos",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/shortVideo/getShortVideos?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const createShortVideo = createAsyncThunk(
  "admin/shortVideo/createShortVideo",
  async (payload) => {
    return apiInstance.post("admin/shortVideo/createShortVideo", payload);
  }
);

export const deleteShortVideo = createAsyncThunk(
  "admin/shortVideo/deleteShortVideo",
  async (id) => {
    return apiInstanceFetch.delete(
      `admin/shortVideo/deleteShortVideo?shortVideoId=${id}`
    );
  }
);

const shortVideoSlice = createSlice({
  name: "shortVideoSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllShortVideo.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllShortVideo.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.shortVideo = action?.payload?.shortVideo;
      state.total = action?.payload?.totalShortVideo;
    });

    builder.addCase(getAllShortVideo.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(createShortVideo.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createShortVideo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shortVideo = [...action?.payload?.shortVideo, ...state.shortVideo];
      setToast("success", "Added Short Video  Successfully");
    });

    builder.addCase(createShortVideo.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteShortVideo.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteShortVideo.fulfilled, (state, action) => {
      state.shortVideo = state.shortVideo.filter(
        (shortVideo) => shortVideo._id !== action.meta.arg
      );
      state.isLoading = false;
      setToast("success", "Deleted Short Video Successfully");
    });
    builder.addCase(deleteShortVideo.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default shortVideoSlice.reducer;
