import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
const initialState = {
  banner: [],
  isSkeleton: false,
  isLoading: false,
};

export const getBanners = createAsyncThunk(
  "admin/banner/getBanner",
  async (payload) => {
    return apiInstanceFetch.get(`admin/banner/getBanner?appId=${payload}`);
  }
);

export const addBanner = createAsyncThunk(
  "admin/banner/createBanner",
  async (payload) => {
    return apiInstance.post(`admin/banner/createBanner`, payload);
  }
);

export const updateBanner = createAsyncThunk(
  "admin/banner/updateBanner",
  async (payload) => {
    return apiInstance.patch(
      `admin/banner/updateBanner?bannerId=${payload.bannerId}`,
      payload.formData
    );
  }
);

export const handleActive = createAsyncThunk(
  "admin/banner/isActive",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/banner/isActive?bannerId=${payload}`);
  }
);

export const deleteBanner = createAsyncThunk(
  "admin/banner/deleteBanner",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/banner/deleteBanner?bannerId=${payload}`
    );
  }
);
const bannerSlice = createSlice({
  name: "bannerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanners.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getBanners.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.banner = action.payload.data;
    });

    builder.addCase(getBanners.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addBanner.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(addBanner.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.banner.unshift(action?.payload?.data);
    });

    builder.addCase(addBanner.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(updateBanner.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateBanner.fulfilled, (state, action) => {
      const updatedBanner = action?.payload?.data;
      const bannerIndex = state?.banner?.findIndex(
        (banner) => banner?._id === updatedBanner?._id
      );
      if (bannerIndex !== -1) {
        state.banner[bannerIndex] = {
          ...state.banner[bannerIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",

        "Banner Update  Successfully"
      );
    });

    builder.addCase(updateBanner.rejected, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(handleActive.fulfilled, (state, action) => {
      const updatedBanner = action?.payload?.data;
      const bannerIndex = state?.banner?.findIndex(
        (banner) => banner?._id === updatedBanner?._id
      );
      if (bannerIndex !== -1) {
        state.banner[bannerIndex] = {
          ...state.banner[bannerIndex],
          ...action?.payload?.data,
        };
      }
      state.isLoading = false;
      setToast(
        "success",

        "Banner Update  Successfully"
      );
    });
    builder.addCase(handleActive.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteBanner.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.banner = state.banner.filter((banner) => banner._id !== action.meta.arg);
      state.isLoading = false;
      setToast("success", "Banner Deleted Successfully");
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default bannerSlice.reducer;
