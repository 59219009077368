import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getPlatform,
  deletePlatform,
} from "../../../store/slice/newMonetizationSlice";
import MonetizationBox from "../../extra/MonetizationBox";
import Button from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { openDialog } from "../../../store/slice/dialogueSlice";
import AddMonetization from "../../dialogComponent/AddMonetization";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { warning } from "../../utils/Alert";
import { appMonetizationSwitch, getMonetization } from "../../../store/slice/appSlice";

const Monetization2 = () => {
  const location = useLocation();
  const { monetization } = useSelector((state) => state.monetization);
  const { monetizationData: appMonetization } = useSelector((state) => state.app);
  const { dialogueType } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [monetizationData, setMonetizationData] = useState([]);
  const [isAppActive, setIsAppActive] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);

  useEffect(() => {
    const payload = location?.state?.editAppData?._id;
    dispatch(getPlatform(payload));
    dispatch(getMonetization(payload));
  }, [location.state, dispatch]);

  const mainAppId = location?.state?.editAppData?._id;

  useEffect(() => {
    setMonetizationData(monetization);
  }, [monetization]);
  console.log('appMonetization', appMonetization)

  const handleInputChange = (index, newValue) => {
    setMonetizationData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        ...newValue,
      };
      console.log("Updated monetizationData:", newData); // Debug log
      return newData;
    });
  };

  const handleDelete = (platformId, appId) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          let data = { appId, platformId };
          dispatch(deletePlatform(data));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type, appId) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: appId,
    };
    dispatch(openDialog(payload));
  };

  const handleUpdate = (type, data, appId, platformId) => {
    const payloadData = {
      data,
      appId,
      platformId,
    };
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: payloadData,
    };
    dispatch(openDialog(payload));
  };

  console.log("Initial monetizationData:", monetizationData); // Debug log

  const handleSwitch = (id, type, value) => {
    const payload = {
      appId: id,
      type: type,
      value: value,
    };
    dispatch(appMonetizationSwitch(payload));
  };

  return (
    <div className="monetization-edit">
      {(dialogueType === "addMonetization" ||
        dialogueType === "editMonetization") && <AddMonetization />}
      <div className="d-flex justify-content-start mb-3 new-button me-auto">
        <Button
          btnName={"New"}
          btnIcon={<AddRoundedIcon />}
          type={"button"}
          onClick={() =>
            handleNewOpenDialog(
              "addMonetization",
              location.state.editAppData?._id
            )
          }
          newClass={"new-btn"}
        />
      </div>
      <div className="d-flex ">
        <div className="mt-2">
          <label className="label-text mb-2 me-3 label">App Active</label>
          <ToggleSwitch
            onChange={setIsAppActive}
            value={appMonetization.isAppActive}
            onClick={() =>
              handleSwitch(location.state.editAppData?._id, "isAppActive")
            }
          />
        </div>
        <div className="mt-2 ">
          <label className="label-text mb-2 label">IsShuffle</label>
          <ToggleSwitch
            value={appMonetization.isShuffle}
            onChange={setIsShuffle}
            onClick={() =>
              handleSwitch(location.state.editAppData?._id, "isShuffle")
            }
          />
        </div>
      </div>
      <form>
        <div className="add-revenue">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="row" style={{ position: "relative" }}>
                {monetizationData.map((item, index) => (
                  <div
                    className="col-12 mb-4 pb-4 col-md-6 mb-md-0 pb-md-0"
                    key={index}
                  >
                    <MonetizationBox
                      title={item.name}
                      inputValue={item}
                      setInputValue={(newValue) =>
                        handleInputChange(index, newValue)
                      }
                      margin={"0px 10px 0px 0px"}
                      marginShow={true}
                      onDelete={() => handleDelete(item?._id, mainAppId)}
                      onUpdate={() =>
                        handleUpdate(
                          "editMonetization",
                          item,
                          mainAppId,
                          item?._id
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Monetization2;
