import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import {
  createSpecifiedNotification,
  editNotification,
} from "../../store/slice/notificationSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import Textarea from "../extra/TextArea";

const SpecifiedNotifiDialogueData = (props) => {
  const dispatch = useDispatch();
  const { getAppData, getSpecifiedNotiData } = useSelector(
    (state) => state.notification
  );
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const [specificTimeInput, setSpecificTimeInput] = useState({
    title: [],
    description: [],
    link: [],
  });

  const [specificTimeError, setSpecificTimeError] = useState({
    specificTimeTitle: "",
    specificTimeDescription: "",
    specificTimeLink: "",
    specificTimeAppSelect: "",
    specificTimeTime: "",
  });

  const [imageShow, setImageShow] = useState();
  const [imageErrorShow, setImageErrorShow] = useState("");
  const [invalidUrls, setInvalidUrls] = useState([]);
  const [imgType, setImgType] = useState("1");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showImgFile, setShowImgFile] = useState([]);

  const handleSpecificTimeChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setSpecificTimeInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setSpecificTimeError({
        ...specificTimeError,
        [errorValue]: errorMessage,
      });
    } else {
      setSpecificTimeError({
        ...specificTimeError,
        [errorValue]: "",
      });
    }
  };

  const validateImage = (url, index) => {
    const img = new Image();
    img.src = url;

    img.onerror = () => {
      setImageErrorShow();
    };

    img.onerror = () => {
      setInvalidUrls(`Invalid image URL`);
    };

    img.onload = () => {
      setInvalidUrls("");
    };
  };

  useEffect(() => {
    imageShow && imageShow?.forEach(validateImage);
  }, [imageShow]);

  useEffect(() => {
    setSpecificTimeInput({
      title: dialogueData?.title
        ? dialogueData?.title?.join(",")
        : dialogueData?.title,
      description: dialogueData?.discreption
        ? dialogueData?.discreption?.join(",")
        : dialogueData?.discreption,
      link: dialogueData?.image
        ? dialogueData?.image?.join(",")
        : dialogueData?.image,
    });
  }, [dialogueData]);

  const handleSubmitpecificTime = () => {
    const { title, description, link } = specificTimeInput;
    if (
      !title ||
      !description ||
      (imgType === "1" && !link) ||
      (imgType === "1" ? invalidUrls?.length > 0 : selectedFiles?.length === 0)
    ) {
      let error = {};
      if (!title) error.specificTimeTitle = "Title Is Required !";
      if (!description)
        error.specificTimeDescription = "Description Is Required !";
      if (imgType === "1") {
        if (!link) error.specificTimeLink = "Image Is Required !";
      } else {
        if (selectedFiles?.length === 0)
          error.specificTimeLink = "Image Is Required !";
      }
      return setSpecificTimeError({ ...error });
    } else {
      let data = {
        title: title,
        discreption: description,
        imgType: imgType,
        image: imgType === "1" ? link : selectedFiles,
      };
      const formData = new FormData();
      formData.append("title", title);
      formData.append("discreption", description);
      formData.append("type", Number(imgType));
      if (imgType === "1") {
        formData.append("image", link);
      } else {
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("image", selectedFiles[i]);
        }
      }

      if (dialogueData) {
        const payload = {
          data: formData,
          id: dialogueData?._id,
        };
        props.editNotification(payload);
      } else {
        const payload = {
          data: formData,
        };
        props.createSpecifiedNotification(payload);
      }
      dispatch(closeDialog());
    }
  };

  const handleTypeChange = (e) => {
    setImgType(e.target.value);
    setInvalidUrls("");
    setImageShow([]);
    setSpecificTimeInput((prevSpecificTimeInput) => ({
      ...prevSpecificTimeInput,
      link: [],
    }));
    setSelectedFiles([]);
  };

  useEffect(() => {
    if (typeof specificTimeInput.link === "string") {
      const imageShow = specificTimeInput.link
        ? specificTimeInput.link?.split(",")
        : specificTimeInput.link;
      setImageShow(imageShow);
    }
  }, [specificTimeInput?.link]);

  const hanldeFileUpload = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      // Store the selected files in the component state
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
      setShowImgFile((prevShowImgFile) => [...prevShowImgFile, ...files]);
    }
  };

  return (
    <div className="specifiedNotiData-dialog">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>
              {dialogueData
                ? "Specific Notification Data Update"
                : "Create Specific Data Notification"}
            </h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12">
                  <Textarea
                    label={`Title`}
                    placeholder={"Enter Title"}
                    name={"title"}
                    onChange={(e) =>
                      handleSpecificTimeChange(
                        e,
                        "specificTimeTitle",
                        "Image Is Required !"
                      )
                    }
                    row={1}
                    value={specificTimeInput.title}
                    note={`Note : Enter coma (,) separated string.`}
                  />
                  {specificTimeError.specificTimeTitle && (
                    <p className="errorMessage">
                      {specificTimeError.specificTimeTitle}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <Textarea
                    label={`Description`}
                    placeholder={"Enter description"}
                    name={"description"}
                    onChange={(e) =>
                      handleSpecificTimeChange(
                        e,
                        "specificTimeDescription",
                        "Description Is Required !"
                      )
                    }
                    row={1}
                    value={specificTimeInput?.description}
                    note={`Note : Enter coma (,) separated string.`}
                  />
                  {specificTimeError.specificTimeDescription && (
                    <p className="errorMessage">
                      {specificTimeError.specificTimeDescription}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <h6 className="labelInput">Choose Image Type</h6>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="1"
                      name="radio-buttons-group"
                      value={imgType}
                      onChange={(e) => handleTypeChange(e)}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Link"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Image"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="link-img">
                    {imgType === "1" ? (
                      <>
                        <Textarea
                          name={"link"}
                          placeholder={"Enter Image Link"}
                          onChange={(e) =>
                            handleSpecificTimeChange(
                              e,
                              "specificTimeLink",
                              "Image Is Required !"
                            )
                          }
                          row={1}
                          value={specificTimeInput.link}
                          note={`Note : Enter coma (,) separated string.`}
                        />
                        {specificTimeError.specificTimeLink && (
                          <p className="errorMessage">
                            {specificTimeError.specificTimeLink}
                          </p>
                        )}
                        {invalidUrls && (
                          <p className="errorMessage">{invalidUrls}</p>
                        )}
                        <div className="img-show">
                          {imageShow &&
                            imageShow?.map((item) => {
                              return (
                                <>
                                  <img src={item} />
                                </>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <>
                        <input
                          multiple
                          type="file"
                          accept="image/gif, image/jpeg, image/png"
                          className="form-input mt-2"
                          onChange={hanldeFileUpload}
                        />
                        <div className="img-show mt-3">
                          {showImgFile &&
                            showImgFile?.map((item) => {
                              return (
                                <>
                                  <img src={URL.createObjectURL(item)} />
                                </>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row dialog-footer mt-3">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={() => dispatch(closeDialog())}
                  style={{ marginRight: "10px" }}
                  newClass="cancel-btn"
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmitpecificTime}
                  newClass="submit-btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  createSpecifiedNotification,
  editNotification,
})(SpecifiedNotifiDialogueData);
