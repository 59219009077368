import React from "react";
import ToggleSwitch from "./ToggleSwitch";
import Input from "./Input";

export default function AdRevenueBox(props) {
  const {
    switchValue,
    switchOnChange,
    inputValue,
    setInputValue,
    title,
    margin,
    marginShow,
    setSwitchDataType,
    dataNameTransfer,
    onClick,
  } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      className="ad-revenue-box"
      style={{ margin: `${marginShow ? margin : ""}` }}
    >
      <div className="ad-revenue-title d-flex justify-content-between align-items-center">
        <h6>{title}</h6>
        <ToggleSwitch
          value={switchValue}
          onChange={switchOnChange}
          onClick={onClick}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <Input
            label={"Publish ID"}
            type={`text`}
            name={"publishID"}
            value={inputValue?.publishID}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"App ID"}
            type={`text`}
            name={"appID"}
            value={inputValue?.appID}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"Banner"}
            type={`text`}
            name={"banner"}
            value={inputValue?.banner}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"Native"}
            type={`text`}
            name={"native"}
            value={inputValue?.native}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"Interstitial"}
            type={`text`}
            name={"interstitial"}
            value={inputValue?.interstitial}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"App Open"}
            type={`text`}
            name={"appOpen"}
            value={inputValue?.appOpen}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-12">
          <Input
            label={"Reward"}
            type={`text`}
            name={"reward"}
            value={inputValue?.reward}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
    </div>
  );
}
