import React from 'react'

export default function Title(props) {
  let {
   title,
   dashboardTitle,
   path
  }=props
    return (
    <div className='title-custom mb-2 pb-2'>
      {
        dashboardTitle ?  <h6>{title}</h6>:  <h5>{title}</h5>}
        <span>{path}</span>
    </div>
  )
}
