import axios from "axios";
import { apiInstance } from "../Api/axiosApi";

export function setToken(token) {
  if (token) {
    return (apiInstance.defaults.headers.common["Authorization"] = JSON.parse(token));
  } else {
    return delete apiInstance.defaults.headers.common["Authorization"];
  }
}

export function SetDevKey(key) {
  if (key) {
    return (apiInstance.defaults.headers.common["key"] = key);
  } else {
    return delete apiInstance.defaults.headers.common["key"];
  }
}
