import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { addBanner, updateBanner } from "../../store/slice/bannerSlice";
import Input from "../extra/Input";
import ToggleSwitch from "../extra/ToggleSwitch";
import Button from "../extra/Button";
import ReactSelect from "react-select";
import Textarea from "../extra/TextArea";
import { useLocation } from "react-router-dom";
import { baseURL } from "../utils/config";

const BannerDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [link, setLink] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [logoImageShow, setLogoImageShow] = useState();
  const location = useLocation();
  const [error, setError] = useState({
    link: "",
    logoImage: "",
  });

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleFileUpload = (e) => {
    setLogoImage(e.target.files[0]);
    const showLogo = URL?.createObjectURL(e.target.files[0]);
    setLogoImageShow(showLogo);
    if (!e.target.files[0]) {
      return setError({
        ...error,
        logoImage: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        logoImage: "",
      });
    }
  };

  useEffect(() => {
    if (dialogueData) {
      setLink(dialogueData?.link);

      setLogoImage(dialogueData?.image);
      setLogoImageShow(dialogueData?.image);
    }
  }, [dialogueData]);

  const handleSubmit = (e) => {
    if (!link || !logoImageShow) {
      let error = {};
      if (!link) error.link = "Link ss Required !";
      if (!logoImage) error.logoImage = "Image  Is Required !";

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("link", link);
      formData.append("image", logoImage);

      if (dialogueData) {
        const payload = {
          formData: formData,
          bannerId: dialogueData?._id,
        };
        dispatch(updateBanner(payload));
        dispatch(closeDialog());
      } else {
        formData.append('appId', location?.state?.editAppData?._id)

          dispatch(addBanner(formData));
        dispatch(closeDialog());
      }
    }
  };

  return (
    <div className="vipPlan-edit">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueData ? "Update Banner" : "Create New Banner"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={`Name`}
                    id={`planName`}
                    type={`text`}
                    value={link}
                    errorMessage={error.link && error.link}
                    onChange={(e) => {
                      setLink(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          link: `Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          link: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 image-input mt-2">
                  <label className="label-input">Image</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className="form-input"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={handleFileUpload}
                  />
                  <p className="errorMessage">{error.logoImage}</p>
                </div>
                <div className="col-12 col-md-6 imgShow">
                  {logoImageShow && <img src={logoImageShow} alt="" />}
                </div>
              </div>
            </div>
            <div className="row dialog-footer">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default BannerDialog;
