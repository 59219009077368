/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extra/Button";
import {
  customAdDelete,
  getAllCustomAd,
  isShowCustomAd,
} from "../../store/slice/customAdSlice";
import ToggleSwitch from "../extra/ToggleSwitch";
import dayjs from "dayjs";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { openDialog } from "../../store/slice/dialogueSlice";
import CustomAdDialog from "../dialogComponent/CustomAdDialog";
import { warning } from "../utils/Alert";

const CustomAd = () => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isShow, setIsShow] = useState(false);
  const [search, setSearch] = useState();
  const { customAd, total } = useSelector((state) => state.customAd);
  const navigate = useNavigate();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    dispatch(getAllCustomAd(payload));
  }, [start, limit]);

  useEffect(() => {
    setData(customAd);
  }, [customAd]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleIsShowClick = (row) => {
    const payload = {
      id: row?._id,
      value: row?.isShow,
    };
    dispatch(isShowCustomAd(payload));
  };

  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "UniqueId",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.uniqueId}</span>,
    },
    {
      Header: "Title",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.title[0]}</span>,
    },
    {
      Header: "Description",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.description[0]}</span>
      ),
    },
    {
      Header: "Button Text",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.buttonText[0]}</span>
      ),
    },
    {
      Header: "Link",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.link[0]}</span>,
    },
    {
      Header: "Is Show",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={setIsShow}
            onClick={() => handleIsShowClick(row)}
            value={row?.isShow}
          />
        </div>
      ),
    },
    {
      Header: "Created At",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD/MM/YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"editBtn"}
          onClick={() => handleEditApp(row, "addCustomAd")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDelete(row?._id)}
          newClass={"deleteBtn"}
        />
      ),
    },
  ];

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            dispatch(customAdDelete(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleEditApp = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="mainCustomAd">
      <Title title="Custom Ad" />
      {dialogue && dialogueType === "addCustomAd" && <CustomAdDialog />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total Custom Ads : {total}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={customAd}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className=" col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("addCustomAd")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CustomAd;
