import React from "react";
import axios from "axios";
import { setToast } from "./toastServices";
import { baseURL, key } from "../utils/config";

export const apiInstance = axios.create({
  baseURL: baseURL,
});

const getToken = JSON.parse(sessionStorage.getItem("adminToken"));

apiInstance.defaults.headers.common["key"] = key;
apiInstance.defaults.headers.common["Authorization"] = getToken;

apiInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  async function (response) {
    if (response?.data?.status === false) {
      setToast("error", response.data.message);
    }
    return response.data;
  },

  async function (error) {
    if (error.response) {
      // Handle errors with a response

      if (error.response.data.message instanceof Array) {
        // Display multiple error messages
        error.response.data.message.forEach((msg) => setToast("error", msg));
      } else if (error.response.data.message) {
        // Display a single error message
        setToast("error", error.response.data.message);
      } else {
        // Unexpected case
        setToast("error", "Unexpected error occurred.");
      }

      // Handle specific error codes
      if (
        error.response.data.code === "E_USER_NOT_FOUND" ||
        error.response.data.code === "E_UNAUTHORIZED"
      ) {
        // Consider handling authentication errors more gracefully
        // e.g., redirect to login page
        // localStorage.clear();
        // window.location.reload(false);
      }
    } else {
      // Handle non-response errors
      setToast("error", "Something went wrong!");
    }

    return Promise.reject(error);
  }
);

const getTokenData = JSON.parse(sessionStorage.getItem("adminToken"));

const headers = {
  key: key,
  Authorization: getTokenData,
};

const handleErrors = async (response) => {
  if (!response.ok) {
    const data = await response.json();
    if (data.message instanceof Array) {
      data.message.forEach((msg) => setToast("error", msg));
    } else if (data.message) {
      setToast("error", data.message);
    } else {
      setToast("error", "Unexpected error occurred.");
    }

    if (data.code === "E_USER_NOT_FOUND" || data.code === "E_UNAUTHORIZED") {
      // Consider handling authentication errors more gracefully
      // e.g., redirect to login page
      // localStorage.clear();
      // window.location.reload(false);
    }

    return Promise.reject(data);
  }

  return response.json();
};

export const apiInstanceFetch = {
  get: (url) =>
    fetch(`${baseURL}/${url}`, { method: "GET", headers }).then(handleErrors),

  post: (url, data) =>
    fetch(`${baseURL}/${url}`, {
      method: "POST",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(handleErrors),

  patch: (url, data) =>
    fetch(`${baseURL}/${url}`, {
      method: "PATCH",
      headers: { ...headers, "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(handleErrors),

  delete: (url) =>
    fetch(`${baseURL}/${url}`, {
      method: "DELETE",
      headers,
    }).then(handleErrors),
};
