import React, { useEffect, useState } from "react";
import { getSpecifiedNotification } from "../../../store/slice/notificationSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Searching from "../../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import SendIcon from "@mui/icons-material/Send";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Pagination from "../../extra/Pagination";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { baseURL } from "../../utils/config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { openDialog } from "../../../store/slice/dialogueSlice";
import SpecifiedNotifiDialog from "../../dialogComponent/SpecifiedNotifiDialog";
import { warning } from "../../utils/Alert";
import { Avatar, Chip } from "@mui/material";

const SpecifiedNotification = (props) => {
  const { getAppData, getSpecifiedNotiData } = useSelector(
    (state) => state.notification
  );
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getSpecifiedNotification();
  }, []);

  const dateFormat = (date) => {
    if (!date) return "-";  // Return a placeholder if date is not available

    const options = { timeZone: "Asia/Kolkata" };

    // If the date is a string, parse it into a Date object
    const dateObject = typeof date === 'string' ? new Date(date) : date;

    // Check if the dateObject is a valid Date
    if (isNaN(dateObject.getTime())) {
        return "";  // Return an error message for invalid dates
    }

    const formattedDate = dateObject.toLocaleString("en-US", options);
    return formattedDate;
  };
  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "App",
      body: "app",
      Cell: ({ row }) => (
        <div className="img-showTable">
          {row?.appId?.map((item) => {
            return (
              <>
                <Chip
                  avatar={
                    <Avatar alt="Natacha" src={baseURL + "/" + item?.appLogo} />
                  }
                  label={item?.appName}
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                />
                {/* <div className="app-chip">
                <img
                  src={baseURL + "/" + item?.appLogo}
                  id="imageShow"
                  className="imageShow"
                />
                <span>{item?.appName}</span>
                </div> */}
              </>
            );
          })}
        </div>
      ),
    },
    {
      Header: "Specific Time",
      body: "specificTime",
      Cell: ({ row }) => (
        <span>{"Every" + " " + row?.specificTime + " " + "hour"}</span>
      ),
    },
    {
      Header: "Last Time",
      body: "lastTime",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.lastTimeData?.length > 0 &&
            (row?.lastTimeData[0]?.lastTime?.length > 0
              ? dateFormat(
                  row?.lastTimeData[0] && row?.lastTimeData[0]?.lastTime
                )
              : "-")}
        </span>
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDelteNotifi(row)}
          newClass={"delete-btn"}
        />
      ),
    },
  ];

  const handleDelteNotifi = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              name: row?.name,
              id: row?._id,
            };
            // props.deleteGame(payload)
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setData(getSpecifiedNotiData);
  }, [getSpecifiedNotiData]);

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleEditData = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div>
      <div className="notification-table">
        {(dialogueType === "updateNotification" ||
          dialogueType === "newNotification") && <SpecifiedNotifiDialog />}
        <div className="row mt-3 pt-1">
          <div className="col-12 new-button d-flex justify-content-end">
            <Button
              btnName={"Send Notification"}
              btnIcon={<SendIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newNotification")}
              newClass={"new-btn send-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  getSpecifiedNotification,
})(SpecifiedNotification);
