import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import {
  hostCreate,
  updatedHost,
  deleteHostImage,
} from "../../store/slice/hostSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import Textarea from "../extra/TextArea";

const HostDialog = (props) => {
  const dispatch = useDispatch();
  const { getAppData, getSpecifiedNotiData } = useSelector(
    (state) => state.notification
  );
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const [hostInput, setHostInput] = useState({
    hostName: "",
    bio: "",
    age: "",
    videoLink: "",
    imageLink: [],
  });

  const [hostInputError, setHostInputError] = useState({
    hostName: "",
    bio: "",
    age: "",
    videoLink: "",
    imageLink: "",
  });

  const [imageShow, setImageShow] = useState();
  const [imageErrorShow, setImageErrorShow] = useState("");
  const [invalidUrls, setInvalidUrls] = useState([]);
  const [imgType, setImgType] = useState("1");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showImgFile, setShowImgFile] = useState([]);
  const [videoType, setVideoType] = useState("1");
  const [selectedVideoFiles, setSelectedVideoFiles] = useState();
  const [showVideoFile, setShowVideoFile] = useState();
  const [videoShow, setVideoShow] = useState();

  const handleSpecificTimeChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setHostInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setHostInputError({
        ...hostInputError,
        [errorValue]: errorMessage,
      });
    } else {
      setHostInputError({
        ...hostInputError,
        [errorValue]: "",
      });
    }
  };

  const validateImage = (url, index) => {
    const img = new Image();
    img.src = url;

    img.onerror = () => {
      setImageErrorShow();
    };

    img.onerror = () => {
      setInvalidUrls(`Invalid image URL`);
    };

    img.onload = () => {
      setInvalidUrls("");
    };
  };

  useEffect(() => {
    imageShow && imageShow?.forEach(validateImage);
  }, [imageShow]);

  useEffect(() => {
    console.log("dialogueData", dialogueData);
    setHostInput({
      age: dialogueData?.age,
      hostName: dialogueData?.name,
      bio: dialogueData?.bio,
      videoLink: dialogueData?.video,
      imageLink: dialogueData?.image
        ? dialogueData?.image?.join(",")
        : dialogueData?.image,
    });
  }, [dialogueData]);

  const handleSubmitHost = () => {
    const { hostName, age, bio, imageLink, videoLink } = hostInput;
    if (
      !hostName ||
      !age ||
      !bio ||
      (imgType === "1" && !imageLink) ||
      (imgType === "1"
        ? invalidUrls?.length > 0
        : selectedFiles?.length === 0) ||
      (videoType === "1" && !videoLink) ||
      (videoType === "1"
        ? videoShow?.length === 0
        : selectedVideoFiles?.length === 0)
    ) {
      let error = {};
      if (!hostName) error.hostName = "Name Is Required !";
      if (!age) error.age = "Age Is Required !";
      if (!bio) error.bio = "Bio Is Required !";
      if (imgType === "1") {
        if (!imageLink) error.imageLink = "Image Is Required !";
      } else {
        if (selectedFiles?.length === 0)
          error.imageLink = "Image Is Required !";
      }

      if (videoType === "1") {
        if (!videoLink) error.videoLink = "Video Is Required !";
      } else {
        if (selectedVideoFiles?.length === 0)
          error.videoLink = "Video Is Required !";
      }

      return setHostInputError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("name", hostName);
      formData.append("age", age);
      formData.append("bio", bio);
      formData.append("imageType", Number(imgType));
      formData.append("videoType", Number(videoType));
      if (imgType === "1") {
        if (typeof imageLink === "string") {
          const arrayCreateImage = imageLink?.split(",");
          formData.append("image", arrayCreateImage);
        } else {
          formData.append("image", imageLink);
        }
      } else {
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("image", selectedFiles[i]);
        }
      }
      if (videoType === "1") {
        formData.append("video", videoLink);
      } else {
        formData.append("video", selectedVideoFiles);
      }

      if (dialogueData) {
        const payload = {
          data: formData,
          id: dialogueData?._id,
        };
        props.updatedHost(payload);
      } else {
        const payload = {
          data: formData,
        };
        props.hostCreate(payload);
      }
      dispatch(closeDialog());
    }
  };

  const handleTypeChange = (e) => {
    setImgType(e.target.value);
    setInvalidUrls("");
    setImageShow([]);
    setHostInput((prevSpecificTimeInput) => ({
      ...prevSpecificTimeInput,
      imageLink: [],
    }));
    setSelectedFiles([]);
  };

  const handleVideoTypeChange = (e) => {
    setVideoType(e.target.value);
    setVideoShow("");
    setHostInput((prevSpecificTimeInput) => ({
      ...prevSpecificTimeInput,
      videoLink: "",
    }));
    setSelectedFiles("");
    setShowVideoFile("");
  };

  useEffect(() => {
    if (typeof hostInput.imageLink === "string") {
      const imageShow = hostInput.imageLink
        ? hostInput.imageLink?.split(",")
        : hostInput.imageLink;
      setImageShow(imageShow);
    }
  }, [hostInput?.imageLink]);

  useEffect(() => {
    setVideoShow(hostInput?.videoLink);
  }, [hostInput?.videoLink]);

  const hanldeFileUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
      setShowImgFile((prevShowImgFile) => [...prevShowImgFile, ...files]);
      setHostInputError((prev) => ({ ...prev, imageLink: "" }));
    }
  };

  const handleUploadVideo = (e) => {
    setHostInputError((prev) => ({ ...prev, videoLink: "" }));
    setSelectedVideoFiles(e.target.files[0]);
    const showImage = URL.createObjectURL(e.target.files[0]);
    if (showImage) {
      setShowVideoFile(showImage);
    }
  };

  const handleDeleteImg = (e, index) => {
    e.preventDefault();
    const payload = {
      hostId: dialogueData?._id,
      index: index,
    };

    if (imgType === "1") {
      const updatedImageShow = [
        ...imageShow?.slice(0, index),
        ...imageShow?.slice(index + 1),
      ];
      setImageShow(updatedImageShow);
      props.deleteHostImage(payload);
    } else {
      const updatedImageShow = [
        ...showImgFile?.slice(0, index),
        ...showImgFile?.slice(index + 1),
      ];
      setShowImgFile(updatedImageShow);
    }
  };
  return (
    <div className="host-dialog">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>{dialogueData ? "Host Update" : "Create Host"}</h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Input
                    label={`Name`}
                    id={`hostName`}
                    name={"hostName"}
                    type={`text`}
                    value={hostInput?.hostName}
                    onChange={(e) =>
                      handleSpecificTimeChange(
                        e,
                        "hostName",
                        "Name Is Required !"
                      )
                    }
                  />
                  {hostInputError.hostName && (
                    <p className="errorMessage">{hostInputError.hostName}</p>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={`Age`}
                    id={`age`}
                    name={"age"}
                    type={`number`}
                    value={hostInput?.age}
                    onChange={(e) =>
                      handleSpecificTimeChange(e, "age", "Age Is Required !")
                    }
                  />
                  {hostInputError.age && (
                    <p className="errorMessage">{hostInputError.age}</p>
                  )}
                </div>
                <div className="col-12 mt-2">
                  <Textarea
                    label={`Bio`}
                    placeholder={"Enter bio..."}
                    name={"bio"}
                    onChange={(e) =>
                      handleSpecificTimeChange(e, "bio", "Bio Is Required !")
                    }
                    row={1}
                    value={hostInput?.bio}
                  />
                  {hostInputError.bio && (
                    <p className="errorMessage">{hostInputError.bio}</p>
                  )}
                </div>
                <div className="col-12  mt-2">
                  <h6 className="labelInput">Choose Video Type</h6>
                  <div className=" ">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-buttons-group"
                        value={videoType}
                        onChange={(e) => handleVideoTypeChange(e)}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Link"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Video"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="link-img">
                      {videoType === "1" ? (
                        <>
                          <Textarea
                            name={"videoLink"}
                            placeholder={"Enter Video Link"}
                            onChange={(e) =>
                              handleSpecificTimeChange(
                                e,
                                "videoLink",
                                "video Link Is Required !"
                              )
                            }
                            row={1}
                            value={hostInput.videoLink}
                          />
                          {hostInputError.videoLink && (
                            <p className="errorMessage">
                              {hostInputError.videoLink}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            multiple
                            type="file"
                            accept="video/*"
                            className="form-input mt-2"
                            onChange={handleUploadVideo}
                          />
                          {hostInputError.videoLink && (
                            <p className="errorMessage">
                              {hostInputError.videoLink}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {videoType === "1" ? (
                    <div className="video-show">
                      {videoShow && (
                        <video
                          controls
                          src={videoShow}
                          height="80"
                          style={{ margin: "13px 0px" }}
                          width="80"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        className="video-show"
                       
                      >
                        {showVideoFile && (
                          <video
                            controls
                            src={showVideoFile}
                            style={{ margin: "13px 0px" }}
                            height="80"
                            width="80"
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12 mt-3">
                  <h6 className="labelInput">Choose Image Type</h6>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="1"
                      name="radio-buttons-group"
                      value={imgType}
                      onChange={(e) => handleTypeChange(e)}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Link"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Image"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="link-img">
                    {imgType === "1" ? (
                      <>
                        <Textarea
                          name={"imageLink"}
                          placeholder={"Enter Image Link"}
                          onChange={(e) =>
                            handleSpecificTimeChange(
                              e,
                              "imageLink",
                              "Image Is Required !"
                            )
                          }
                          row={1}
                          value={hostInput.imageLink}
                          note={`Note : Enter coma (,) separated string.`}
                        />
                        {hostInputError.imageLink && (
                          <p className="errorMessage">
                            {hostInputError.imageLink}
                          </p>
                        )}
                        {invalidUrls && (
                          <p className="errorMessage">{invalidUrls}</p>
                        )}
                        {dialogueData ? (
                          <div className="img-show">
                            {imageShow &&
                              imageShow?.map((item, index) => {
                                return (
                                  <>
                                    <div className="imageRemoveIcon">
                                      <Button
                                        btnIcon={<CancelIcon />}
                                        onClick={(e) =>
                                          handleDeleteImg(e, index)
                                        }
                                      />
                                      <img src={item} />
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        ) : (
                          <div className="img-show">
                            {imageShow &&
                              imageShow?.map((item) => {
                                return (
                                  <>
                                    <img src={item} />
                                  </>
                                );
                              })}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <input
                          multiple
                          type="file"
                          accept="image/gif, image/jpeg, image/png"
                          className="form-input mt-2"
                          onChange={hanldeFileUpload}
                        />
                        {hostInputError.imageLink && (
                          <p className="errorMessage">
                            {hostInputError.imageLink}
                          </p>
                        )}
                        {dialogueData ? (
                          <div>
                            <div className="img-show mt-3">
                              {showImgFile?.map((item, index) => {
                                return (
                                  <>
                                    <div className="imageRemoveIcon">
                                      <Button
                                        btnIcon={<CancelIcon />}
                                        onClick={(e) =>
                                          handleDeleteImg(e, index)
                                        }
                                      />
                                      <img src={URL.createObjectURL(item)} />
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="img-show mt-3">
                            {showImgFile?.map((item) => {
                              return (
                                <>
                                  <img src={URL.createObjectURL(item)} />
                                </>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row dialog-footer mt-3">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={() => dispatch(closeDialog())}
                  style={{ marginRight: "10px" }}
                  newClass="cancel-btn"
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmitHost}
                  newClass="submit-btn"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  hostCreate,
  updatedHost,
  deleteHostImage,
})(HostDialog);
