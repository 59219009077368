import React from "react";
import { useState } from "react";
import Button from "../../extra/Button";
import SendIcon from "@mui/icons-material/Send";
import Input from "../../extra/Input";
import { connect, useSelector } from "react-redux";
import { getApp,sendImmediatelyNoti } from "../../../store/slice/notificationSlice";
import Select from "react-select";
import { useEffect } from "react";
import { baseURL } from "../../utils/config";

const ImmediatelyNotifi = (props) => {
  const { getAppData, getSpecifiedNotiData } = useSelector(
    (state) => state.notification
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectMoreAppData, setSelectMoreAppData] = useState([]);
  const [invalidUrls, setInvalidUrls] = useState("");
  const [immediatelyInput, setImmediatelyInput] = useState({
    title: "",
    description: "",
    link: "",
    appSelect: "",
  });
  const [error, setError] = useState({
    title: "",
    description: "",
    link: "",
    appSelect: "",
  });

  useEffect(() => {
    props.getApp();
  }, []);

  const validateImage = (url, index) => {
    const img = new Image();
    img.src = url;

    img.onerror = () => {
      setInvalidUrls(`Invalid image URL`);
    };

    img.onload = () => {
      setInvalidUrls("");
    };
  };

  useEffect(() => {
    if(immediatelyInput?.link){
      validateImage(immediatelyInput?.link);
    }
  }, [immediatelyInput]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);

    const selectedIds = selected?.map((option) => option?._id);
    setSelectMoreAppData(selectedIds);
    if (!selected) {
      return setError({
        ...error,
        appSelect: `App Is Required !`,
      });
    } else {
      return setError({
        ...error,
        appSelect: "",
      });
    }
  };

  const handleImmediatelyInputChange = (e, errorValue, errorMessage) => {
    const { name, value } = e.target;
    setImmediatelyInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (!value) {
      setError({
        ...error,
        [errorValue]: errorMessage,
      });
    } else {
      setError({
        ...error,
        [errorValue]: "",
      });
    }
  };
  const handleSubmit = () => {
    const { title, description, link } = immediatelyInput;

    if (
      !title ||
      !description ||
      !link ||
      selectMoreAppData?.length === 0 ||
      invalidUrls?.length > 0
    ) {
      let error = {};
      if (!title) error.title = "Title Is Required !";
      if (!description) error.description = "Description Is Required !";
      if (!link) error.link = "Image Is Required !";
      if (selectMoreAppData?.length === 0)
        error.appSelect = "Select App Is Required !";
      return setError({ ...error });
    } else {
      let data = {
        title: title,
        discreption: description,
        image: link,
        appId: selectMoreAppData,
      };

      const payload = {
        data: data,
        appId: selectMoreAppData,
      };
      props.sendImmediatelyNoti(payload);
    }
  };

  return (
    <div className="immediatelyNoti">
      <form>
        <div className="row">
          <div className="col-12 select-moreApp">
            <label className="label-input">Select App</label>
            <Select
              isMulti
              options={getAppData || []}
              placeholder={"Select App"}
              value={selectedOptions}
              onChange={handleSelectChange}
              getOptionValue={(option) => option?._id}
              formatOptionLabel={(option) => (
                <div className="moreApp-option">
                  <img src={baseURL + "/" + option?.appLogo} />
                  <span>{option?.appName}</span>
                </div>
              )}
            />
            {error?.appSelect && (
              <p className="errorMessage">{error?.appSelect}</p>
            )}
          </div>
          <div className="col-12">
            <Input
              label={`Title`}
              placeholder={"Enter Title"}
              id={`title`}
              type={`text`}
              name={"title"}
              value={immediatelyInput.title}
              errorMessage={error.title && error.title}
              onChange={(e) =>
                handleImmediatelyInputChange(e, "title", "Title Is Required !")
              }
            />
          </div>
          <div className="col-12">
            <Input
              label={`Description`}
              placeholder={"Enter Description"}
              id={`description`}
              type={`text`}
              name={"description"}
              value={immediatelyInput.description}
              errorMessage={error.description && error.description}
              onChange={(e) =>
                handleImmediatelyInputChange(
                  e,
                  "description",
                  "Description Is Required !"
                )
              }
            />
          </div>

          <div className="col-12 col-md-12">
            <Input
              label={`Image`}
              id={`link`}
              type={`text`}
              placeholder={"Enter Image Link"}
              name={"link"}
              value={immediatelyInput.link}
              errorMessage={error.link && error.link}
              onChange={(e) =>
                handleImmediatelyInputChange(e, "link", "Image Is Required !")
              }
            />
            {invalidUrls && <p className="errorMessage">{invalidUrls}</p>}
          </div>
          <div className="col-12 col-md-12 show-img-imm">
            {immediatelyInput.link && <img src={immediatelyInput.link} />}
          </div>
          <div className="mt-3 d-flex justify-content-end send-btn">
            <Button
              btnIcon={<SendIcon />}
              type={"button"}
              style={{ marginRight: "10px" }}
              onClick={() => handleSubmit()}
              btnName={"Send"}
              newClass={"back-btn"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default connect(null, {
  getApp,
  sendImmediatelyNoti
})(ImmediatelyNotifi);
