import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import {  IconButton } from "@mui/material";
import Input from "../extra/Input";
import { addDeveloper, updateDeveloper } from "../../store/slice/developerSlice";

const DevDialogue = () => {
    const { dialogueData } = useSelector((state) => state.dialogue)
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState({
        name: "",
        password: "",
    });

    useEffect(() => {
        if (dialogueData) {
            setName(dialogueData?.name)
            setPassword(dialogueData?.password)
        }
    }, [dialogueData])


    const handleSubmit = () => {
        const data = { name, password }
        if (!name || !password) {
            let error = {};
            if (!name) error.name = "Name is required"
            if (!password) error.password = "Password is required"
            return setError({...error})
        } else {

            if (dialogueData) {
                const payload = { data, id: dialogueData?._id }
                dispatch(updateDeveloper(payload))
            } else {
                dispatch(addDeveloper(data))
            }
        }
        dispatch(closeDialog());
    };

    return (
        <div className="DevDialogue">
            <div class="dialog">
                <div class="mainDiaogBox">
                    <div className="dialog-head d-flex justify-content-between">
                        <h4 >Developer Dialogue</h4>
                        <IconButton onClick={()=>dispatch(closeDialog())}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                    <div className="dialog-body">
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    label={`Name`}
                                    type={`text`}
                                    placeholder={'Enter name'}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    value={name}
                                />
                                {error?.name && <p className="errorMessage fs-6 fw-bold">{error?.name}</p>}
                            </div>
                            <div className="col-12">
                                <Input
                                    label={`Password`}
                                    id={`password`}
                                    type={`password`}
                                    value={password}
                                    errorMessage={error?.password && error?.password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row dialog-footer mt-3">
                        <div className="col-6"></div>
                        <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                            <Button
                                btnName={"Cancel"}
                                onClick={()=>dispatch(closeDialog())}
                                style={{marginRight:"10px"}}
                                newClass="cancel-btn"
                            />
                            <Button
                                btnName={"Submit"}
                                type={"button"}
                                onClick={handleSubmit}
                                newClass="submit-btn"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DevDialogue;
