import React, { useEffect, useState } from "react";
import { getNotificationData } from "../../../store/slice/notificationSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Searching from "../../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { baseURL } from "../../utils/config";
import dayjs from "dayjs";
import { openDialog } from "../../../store/slice/dialogueSlice";
import SpecifiedNotifiDialogueData from "../../dialogComponent/SpecifiedNotifiDialogData";

const SpecifiedNotificationData = (props) => {
  const { getAppData, notificationData } = useSelector(
    (state) => state.notification
  );
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.getNotificationData();
  }, []);

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <div className="img-showTableData">
          {(row?.image?.length > 4 ? row?.image?.slice(0, 4) : row?.image)?.map(
            (item) => {
              return (
                <>
                  {row?.type === 1 ? (
                    <img
                      src={item}
                      id="imageShow"
                      className="imgNotification"
                    />
                  ) : (
                    <img
                      src={baseURL + "/" + item}
                      id="imageShow"
                      className="imgNotification"
                    />
                  )}
                </>
              );
            }
          )}
        </div>
      ),
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => (
        <div>
          <ul>
            {(row?.title?.length > 4 ? row?.title?.slice(0, 4) : row?.title)?.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </div>
      ),
    },
    {
      Header: "Description",
      body: "discreption",
      Cell: ({ row }) => (
        <div>
          <ul>
            {(row?.discreption?.length > 4 ? row?.discreption?.slice(0, 4) : row?.discreption)?.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </div>
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditData(row, "updateNotificationData")}
        />
      ),
    },
  ];

  useEffect(() => {
    setData(notificationData);
  }, [notificationData]);

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleEditData = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div>
      <div className="notification-table">
        {(dialogueType === "updateNotificationData" ||
          dialogueType === "newNotificationData") && (
          <SpecifiedNotifiDialogueData />
        )}
        <div className="row mt-3 pt-1">
          <div className="col-12 new-button d-flex justify-content-end">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newNotificationData")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  getNotificationData,
})(SpecifiedNotificationData);
