import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  game: [],
  totalGames: "",
  isLoading: false,
  isSkeleton:false,
};

export const getGame = createAsyncThunk("game/gameList", async (payload) => {
  return apiInstanceFetch.get(
    `admin/game/gameList?start=${payload?.start}&limit=${payload?.limit}`,
    payload
  );
});

export const createGame = createAsyncThunk(
  "admin/game/create",
  async (payload) => {
    return apiInstance.post("admin/game/create", payload);
  }
);

export const editGame = createAsyncThunk(
  "admin/game/update",
  async (payload) => {
    return apiInstance.patch(
      `admin/game/update?gameId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteGame = createAsyncThunk(
  "admin/game/delete",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/game/delete?gameId=${payload?.id}`,
    );
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGame.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getGame.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.game = action.payload.game;
      state.totalGames = action.payload.totalGames;
    });
    builder.addCase(getGame.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(createGame.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createGame.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.game.unshift(action.payload.game);
        setToast("success", "Game New Created Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(createGame.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(editGame.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.game = state?.game?.map((game) => {
        if (game?._id === action.meta.arg?.id) {
          return action.payload?.game;
        }
        return game;
      });
      setToast("success", "Game Updated Successfully");
    });
    builder.addCase(editGame.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteGame.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteGame.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.game = state.game.filter((data) => {
          return data._id !== action.meta.arg?.id;
        });
        setToast("success",`${action.meta.arg?.name&&action.meta.arg?.name}` +" "+"Game Deleted SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deleteGame.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default gameSlice.reducer;
