import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDialog } from "../../../store/slice/dialogueSlice";
import { warning } from "../../utils/Alert";
import { Box, Rating, Tab, Tabs } from "@mui/material";
import Title from "../../extra/Title";
import Searching from "../../extra/Searching";
import Button from "../../extra/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { ReactComponent as GiftIcon } from "../../../assets/images/icon/GiftIcon.svg";
import { ReactComponent as GiftCategoryIcon } from "../../../assets/images/icon/GiftCategory.svg";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import dayjs from "dayjs";
import GiftPage from "./GiftPage";
import GiftCategory from "./GiftCategory";

const GiftMain = (props) => {
  const getValue = localStorage.getItem("giftTable");
  const { game, totalGames } = useSelector((state) => state.game);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const [value, setValue] = useState(Number(getValue) ? Number(getValue) : 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const renderEditComponent = React.useCallback(() => {
    switch (value) {
      case 0:
        return <GiftPage />;
      case 1:
        return <GiftCategory />;
      default:
        break;
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("giftTable", newValue);
  };

  return (
    <div className=" giftPage">
      <div className="row">
        <div className="col-4">
          <Title title={"Gift"} />
        </div>
        <div className="col-8 gift-tab">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon tabs example"
            >
              <Tab icon={<GiftIcon />} iconPosition="start" label="All Gift" />
              <Tab
                icon={<GiftCategoryIcon />}
                iconPosition="start"
                label="Gift Category"
              />
            </Tabs>
          </Box>
        </div>
      </div>
      {renderEditComponent()}
    </div>
  );  
};
export default connect(null, {})(GiftMain);
