import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import { login } from "../../store/slice/authSlice";
import Input from "../extra/Input";
import LoginLeft from "../../assets/images/loginPage.png";
import Button from "../extra/Button";
import { devLogin } from "../../store/slice/developerSlice";

function DevLogin(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    login: "",
    password: "",
  });
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    if (!name || !password) {
      let error = {};
      if (!name) error.name = "Name Is Required !";
      if (!password) error.password = "Password is required !";
      return setError({ ...error });
    } else {
      let login = {
       name,
       password
      };
      dispatch(devLogin(login));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="login-page">
      <form>
        <div className="login-form">
          <div className="login-body">
            <div className="login-right">
              <div className="login-title">
                <img src={logo} />
                <span>Virtual Desk</span>
              </div>
              <div className="login-img">
                <img src={LoginLeft} />
              </div>
            </div>
            <div className="login-content">
              <div className="login-mobile-img">
              <div className="login-title">
                <img src={logo} />
              </div>
              </div>
              <h4>
                Welcome Back Dev,<span>Sign In</span>
              </h4>
              <h6>Please Sign In With Name And Password</h6>
              <Input
                label={`Enter Name`}
                id={`enterID`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `Name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
                onKeyPress={handleKeyPress}
              />
              <div className="mt-3 w-100">
                <Input
                  label={`Enter Password`}
                  id={`enterPassword`}
                  type={`password`}
                  value={password}
                  errorMessage={error.password && error.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        password: `Enter Password Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        password: "",
                      });
                    }
                  }}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <Button
                btnName={"Sign In"}
                type={"button"}
                onClick={handleSubmit}
                newClass={"sign-btn"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default connect(null, { login })(DevLogin);
