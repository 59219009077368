import { TablePagination } from "@mui/material";
import React from "react";

export default function Pagination(props) {
  const {
    type,
    start,
    limit,
    totalData,
    setStart,
    setLimit,
    handlePageChange,
    handleRowsPerPage,
  } = props;

  function handleChangePage(event, newpage) {
    setStart(newpage + 1);
  }

  function handleChangeRowsPerPage(event) {
    setLimit(parseInt(event.target.value, 10));
    setStart(1);
  }
  return (
    <div className="custom-pagination">
      {type === "server" && totalData > 0 && (
        <>
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={totalData}
              rowsPerPage={limit}
              page={start - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </>
      )}
    </div>
  );
}
