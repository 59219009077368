import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";


const initialState = {
    liveStreamVideo:[],
    isLoading:false,
    isSkeleton:false,
    total:null
}




export const getLiveStreamingVideos = createAsyncThunk("admin/liveStreamingVideo/getliveStreamingVideos",async (payload)=>{

    return apiInstanceFetch.get(`admin/liveStreamingVideo/getliveStreamingVideos?start=${payload?.start}&limit=${payload?.limit}`)
})

export const createLiveStreamVideo = createAsyncThunk("admin/liveStreamingVideo/createLiveStreamingVideo", async (payload) =>{
    return apiInstance.post("admin/liveStreamingVideo/createLiveStreamingVideo",payload)
})

export const deleteLiveStreamVideo = createAsyncThunk("admin/liveStreamingVideo/deleteLiveStreamingVideo", async (id) =>{
    
    return apiInstanceFetch.delete(`admin/liveStreamingVideo/deleteLiveStreamingVideo?liveStreamingVideoId=${id}`)
})

const liveStreamingVideoSlice = createSlice({
    name:"liveStreamingVideoSlice",
    initialState,
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(getLiveStreamingVideos.pending,(state,action) =>{
            state.isSkeleton = true;
        })

        builder.addCase(getLiveStreamingVideos.fulfilled,(state,action) =>{
            state.isSkeleton = false;
            state.liveStreamVideo = action?.payload?.liveStreamingVideo
            state.total = action?.payload?.totalLiveStreamingVideo
        })

        
        builder.addCase(getLiveStreamingVideos.rejected,(state,action) =>{
            state.isSkeleton = false;
        })

        builder.addCase(createLiveStreamVideo.pending,(state,action) =>{
            state.isLoading = true;
        })

        builder.addCase(createLiveStreamVideo.fulfilled,(state,action) =>{
            state.isLoading = false;
            state.liveStreamVideo = [...action?.payload?.liveStreamingVideo, ...state.liveStreamVideo];
            setToast("success","Added LiveStream Video Successfully");
        })

        
        builder.addCase(createLiveStreamVideo.rejected,(state,action) =>{
            state.isLoading = false;
        })

        
    builder.addCase(deleteLiveStreamVideo.pending, (state, action) => {
        state.isLoading = true;
      })
  
      builder.addCase(deleteLiveStreamVideo.fulfilled, (state, action) => {
        
        state.liveStreamVideo = state.liveStreamVideo.filter((liveStreamVideo) => liveStreamVideo._id !== action.meta.arg);
        state.isLoading = false;
        setToast("success","Deleted LiveStream Video Successfully");
  
      })
      builder.addCase(deleteLiveStreamVideo.rejected, (state, action) => {
        state.isLoading = false;
      })
    }
})
export default liveStreamingVideoSlice.reducer;