import React, { useEffect, useState } from "react";

import { deleteBanner, getBanners, handleActive } from "../../store/slice/bannerSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ToggleSwitch from "../extra/ToggleSwitch";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useLocation } from "react-router-dom";
import { warning } from "../utils/Alert";
import Button from "../extra/Button";
import { openDialog } from "../../store/slice/dialogueSlice";

import noImage from "../../../src/assets/images/noImage.jpg";
import BannerDialog from "../dialogComponent/BannerDialog";
const Banner = (props) => {
  const [data, setData] = useState([]);

  const location = useLocation();
  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.banner);
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );


  useEffect(() => {
      dispatch(getBanners(location.state?.editAppData?._id));
  }, [location.state,dispatch]);

  useEffect(() => {
    setData(banner);
  }, [banner]);

  console.log("data", data);

  const handleEditPlan = (row, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: row,
    };
    dispatch(openDialog(payload));
  };

  const handleDeletePlan = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
              dispatch(deleteBanner(row));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleInActiveSwitch = (row) => {

      dispatch(handleActive(row?._id));
  };

  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => <span className="  text-nowrap">{index + 1}</span>,
    },

    {
      Header: "Image",
      Cell: ({ row }) => (
        <div>
          {row?.image ? (
            <img
              src={row?.image ? row?.image : noImage}
              alt="bannerImage"
              style={{
                width: "250px",
                height: "70px",
                borderRadius: "6px",
                objectFit: "cover",
              }}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      Header: "Link",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.link ? row?.link : "-"}</span>
      ),
    },
    {
      Header: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.createdAt.split("T")[0]}</span>
      ),
    },

    {
      Header: "Is Active",
      body: "isActive",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handleInActiveSwitch(row)}
            value={row?.isActive}
          />
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"editBtn"}
          onClick={() => handleEditPlan(row, "banner")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDeletePlan(row?._id)}
          newClass={"delete-btn-plan"}
        />
      ),
    },
  ];

  return (
    <div>
      {dialogueType === "banner"  ? (
        <BannerDialog />
      ) : (
        <>
          <div className="plan-table">
            <div className="new-button d-flex justify-content-end">
              <Button
                btnName={"New"}
                btnIcon={<AddRoundedIcon />}
                type={"button"}
                onClick={() => handleNewOpenDialog("banner")}
                newClass={"new-btn"}
              />
            </div>
            <Table data={data} mapData={mapData} type={"server"} />
          </div>
        </>
      )}
    </div>
  );
};
export default Banner;
