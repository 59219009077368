import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  movedApp: [],
  totalMovedApp: "",
  isLoading: false,
  isSkeleton: false,
};

export const getMovedApp = createAsyncThunk(
  "admin/app/getAllMoveApp",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/app/getAllMoveApp?start=${payload.start}&limit=${payload.limit}`
    );
  }
);

export const restoreApp = createAsyncThunk(
  "admin/app/isRestore",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/app/isRestore?appId=${payload}`);
  }
);

export const deleteMoveApp = createAsyncThunk(
  "admin/app/delete",
  async (payload) => {
    return apiInstanceFetch.delete(`admin/app/delete?appId=${payload}`);
  }
);

const movedAppSlice = createSlice({
  name: "movedApp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMovedApp.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getMovedApp.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.movedApp = action.payload.app;
      state.totalMovedApp = action.payload.totalMoveApp;
    });
    builder.addCase(getMovedApp.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(restoreApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(restoreApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.movedApp = state.movedApp.filter((data) => {
          return data._id !== action.meta.arg;
        });
        setToast("success", "App Restore SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(restoreApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteMoveApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMoveApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.movedApp = state.movedApp.filter((data) => {
        return data._id !== action.meta.arg;
      });
      setToast("success", "App Delete SuccessFull");
    });
    builder.addCase(deleteMoveApp.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default movedAppSlice.reducer;
