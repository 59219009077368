import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ReactComponent as AddImageIcon } from "../../../assets/images/icon/AddImageIcon.svg";
import Input from "../../extra/Input";
import { Autocomplete, Chip, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { getMoreAppAll } from "../../../store/slice/moreAppSlice";
import Button from "../../extra/Button";
import {
  updateApp,
  isHandleSwitchEdit,
  removeMoreApp,
  getAppDetails,
  isPublishedSwitch,
} from "../../../store/slice/appSlice";
import { moreAppSubmit } from "../../../store/slice/appSlice";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/config";
import Textarea from "../../extra/TextArea";

const AddDetails = (props) => {
  const { moreAppAll } = useSelector((state) => state.moreApp);
  const { appDetails } = useSelector((state) => state.app);
  const [appName, setAppName] = useState("");
  const [packageName, setPackageName] = useState("");
  const [appKey, setAppKey] = useState("");
  const [accountName, setAccountName] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [version, setVersion] = useState(0);
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [note, setNote] = useState("");
  const [appRedirectLink, setAppRedirectLink] = useState("");
  const [reviewLink, setReviewLink] = useState("");
  const [logo, setLogo] = useState("");
  const [showImage, setShowImage] = useState("");
  const [mining, setMining] = useState("");

  const [isMining, setIsMining] = useState(false);
  const [isAppRedirect, setIsAppRedirect] = useState(false);
  const [isMoreApp, setIsMoreApp] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [editDataGet, setEditDataGet] = useState();
  const [referralBonus, setReferralBonus] = useState("");
  const [loginBonus, setLoginBonus] = useState("");
  const [appPlayStoreLink, setAppPlayStoreLink] = useState("");
  const [minimumWithdraw, setMinimumWithdraw] = useState("");
  const [moreAppDescreption, setMoreAppDescreption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [moreAppData, setMoreAppData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectChange = (selected, type) => {
    setSelectedOptions(selected);
    if (type === "addApp") {
      const selectedIds = selected?.map((option) => option?.appKey);
      const updatedData = selectedIds?.join(",");
      let payload = {
        moreAppKey: updatedData,
        appId: location?.state?.editAppData?._id,
      };

      if (updatedData) {
        props.moreAppSubmit(payload);
      }
    }
  };

  useEffect(() => {
    setLogo(baseURL + "/" + appDetails?.appLogo);
    setShowImage(baseURL + "/" + appDetails?.appLogo);
    setAppName(appDetails?.appName);
    setPrivacyPolicy(appDetails?.privacyPolicyURL);
    setPackageName(appDetails?.packageName);
    setAppKey(appDetails?.appKey);
    setAccountName(appDetails?.accountName);
    setBaseUrl(appDetails?.domainName);
    const checkLinkData = appDetails?.link &&appDetails?.link?.includes(
      "https://play.google.com/store/apps/details?id"
    );
    if (checkLinkData === true) {
      setAppPlayStoreLink(appDetails?.link);
    } else {
      setAppPlayStoreLink(
        `https://play.google.com/store/apps/details?id=${appDetails?.packageName}${appDetails?.link}`
      );
    }
    console.log("appDetails",appDetails)
    console.log("checkLinkData", checkLinkData);
    setMining(appDetails?.mining);
    setVersion(appDetails?.version);
    setAppRedirectLink(appDetails?.appRedirectLink);
    setIsMining(appDetails?.isMining);
    setIsAppRedirect(appDetails?.isAppRedirect);
    setIsReview(appDetails?.isReview);
    setForceUpdate(appDetails?.forceUpdate);
    setReviewLink(appDetails?.review);
    setNote(appDetails?.note);
    setMinimumWithdraw(appDetails?.minimumWithdraw);
    setReferralBonus(appDetails?.referralBonus);
    setLoginBonus(appDetails?.loginBonus);
    setIsPublished(appDetails?.isPublished);
    setIsMoreApp(appDetails?.isMoreApp);
    setMoreAppDescreption(appDetails?.description);
    setSelectedOptions(appDetails?.moreApp);
  }, [appDetails]);

  useEffect(() => {
    props.getAppDetails(location.state?.editAppData?._id);
  }, [location.state]);

  useEffect(() => {
    props.getMoreAppAll(location.state?.editAppData?._id);
  }, [location.state]);

  useEffect(() => {
    setMoreAppData(moreAppAll);
  }, [moreAppAll]);

  const handleFileUpload = (event) => {
    setLogo(event.target.files[0]);
    setShowImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("appLogo", logo);
    formData.append("appName", appName);
    formData.append("packageName", packageName);
    formData.append("privacyPolicyURL", privacyPolicy);
    formData.append("appKey", appKey);
    formData.append("accountName", accountName);
    formData.append("note", note);
    formData.append("domainName", baseUrl);
    formData.append("mining", mining);
    formData.append("version", version);
    formData.append("appRedirectLink", appRedirectLink);
    formData.append("review", reviewLink);
    formData.append("minimumWithdraw", minimumWithdraw);
    formData.append("loginBonus", loginBonus);
    formData.append("referralBonus", referralBonus);
    formData.append("link", appPlayStoreLink);
    formData.append("description", moreAppDescreption);

    let payload = {
      data: formData,
      id: location?.state?.editAppData?._id,
    };
    props.updateApp(payload);
  };

  const handleSwitch = (id, type, value) => {
    const payload = {
      appId: id,
      type: type,
      value: value,
    };
    dispatch(isHandleSwitchEdit(payload));
  };

  const handlePublishSwitch = () => {
    const payload = {
      apiType: "AllApp",
      appId: location.state.editAppData?._id,
      type: "Status",
      value: isPublished,
    };
    dispatch(isPublishedSwitch(payload));
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="moreApp-optionList">
      <img src={baseURL + "/" + data?.appLogo} />
      <span>{data?.appName}</span>
    </div>
  );

  const CustomMultiValue = ({ children, ...props }) => (
    <div className="custom-multi-value">
      {children}
      <span
        className="custom-multi-value-remove"
        // onClick={() => props.removeProps.onClick()}
        onClick={() => handleRemoveApp(props.data)}
      >
        ✕
      </span>
    </div>
  );

  const handleRemoveApp = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option._id !== removedOption._id
    );
    const payload = {
      appId: location.state?.editAppData?._id,
      moreAppId: removedOption?._id,
    };
    props.removeMoreApp(payload);
    handleSelectChange(updatedOptions, "removeApp");
  };

  return (
    <div className="add-details">
      <form>
        <div className="row">
          <div className={` col-6`}>
            <div
              className={`image-upload ${showImage ? "show-img-content" : ""}`}
            >
              <label for="image" onChange={handleFileUpload}>
                {showImage ? (
                  <div className={`${showImage ? "show-img" : ""}`}>
                    <div className="edit-icon">
                      <EditIcon />
                    </div>
                    {showImage && <img src={showImage} />}
                  </div>
                ) : (
                  <div className="img-box">
                    <AddImageIcon />
                  </div>
                )}
                <input
                  type="file"
                  name="image"
                  accept="image/gif, image/jpeg, image/png"
                  id="image"
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <Button
              btnName={"Submit"}
              type={"submit"}
              newClass={"submit-btn"}
              onClick={handleSubmit}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`App Name`}
              id={`enterAppName`}
              type={`text`}
              value={appName}
              onChange={(e) => {
                setAppName(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Package Name`}
              id={`packageName`}
              type={`text`}
              value={packageName}
              onChange={(e) => {
                setPackageName(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Privacy Policy`}
              id={`privacyPolicy`}
              type={`text`}
              value={privacyPolicy}
              onChange={(e) => {
                setPrivacyPolicy(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`App Key`}
              id={`appKey`}
              readOnly={true}
              type={`text`}
              value={appKey}
              onChange={(e) => {
                setAppKey(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Minimum Withdraw`}
              id={`minimumWithdraw`}
              type={`number`}
              value={minimumWithdraw}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
                const decimalCount = sanitizedValue.split(".").length - 1;
                if (decimalCount > 1) return;

                setMinimumWithdraw(sanitizedValue);
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              label={`Review`}
              id={`review`}
              type={`text`}
              value={reviewLink}
              switchShow={true}
              onChangeSwitch={setIsReview}
              switchValue={isReview}
              switchOnClick={() =>
                handleSwitch(
                  location.state.editAppData?._id,
                  "isReview",
                  isReview
                )
              }
              onChange={(e) => {
                setReviewLink(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Referral Bonus`}
              id={`referralBonus`}
              type={`number`}
              value={referralBonus}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
                const decimalCount = sanitizedValue.split(".").length - 1;
                if (decimalCount > 1) return;
                setReferralBonus(sanitizedValue);
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              label={`App Redirect Link`}
              id={`appRedirectLink`}
              type={`text`}
              value={appRedirectLink}
              switchShow={true}
              switchOnClick={() =>
                handleSwitch(
                  location.state.editAppData?._id,
                  "isAppRedirect",
                  appRedirectLink
                )
              }
              onChangeSwitch={setIsAppRedirect}
              switchValue={isAppRedirect}
              onChange={(e) => {
                setAppRedirectLink(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              label={`Login Bonus`}
              id={`loginBonus`}
              type={`number`}
              value={loginBonus}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
                const decimalCount = sanitizedValue.split(".").length - 1;
                if (decimalCount > 1) return;
                setLoginBonus(sanitizedValue);
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <Input
              label={`Mining`}
              id={`mining`}
              type={`text`}
              value={mining}
              switchShow={true}
              onChangeSwitch={setIsMining}
              switchValue={isMining}
              switchOnClick={() =>
                handleSwitch(
                  location.state.editAppData?._id,
                  "isMining",
                  isMining
                )
              }
              onChange={(e) => {
                setMining(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Account Name`}
              id={`accountName`}
              type={`text`}
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 moreApp-selector">
            <div className="toggle-switch-more-app mt-2">
              <label className="label-input">More App</label>
              <ToggleSwitch
                onChange={setIsMoreApp}
                value={isMoreApp}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "isMoreApp",
                    isMoreApp
                  )
                }
              />
            </div>
            <Select
              isMulti
              options={moreAppData || []}
              value={selectedOptions}
              isClearable={false}
              onChange={(e) => handleSelectChange(e, "addApp")}
              // getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option._id}
              formatOptionLabel={(option) => (
                <div className="moreApp-option">
                  <img src={baseURL + "/" + option?.appLogo} />
                  <span>{option?.appName}</span>
                </div>
              )}
              components={{
                Option: CustomOption,
                MultiValue: CustomMultiValue,
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`Base URL`}
              id={`baseUrl`}
              type={`text`}
              value={baseUrl}
              onChange={(e) => {
                setBaseUrl(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <Input
              label={`More App Link`}
              id={`moreAppLink`}
              type={`text`}
              value={appPlayStoreLink}
              onChange={(e) => {
                setAppPlayStoreLink(e.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <div className="privacy-policy policy-note mt-3 p-0">
              <Textarea
                label={`Note`}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                row={5}
                value={note}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 ">
            <div className="privacy-policy policy-note mt-3 p-0">
              <Textarea
                label={`More App Descreption`}
                onChange={(e) => {
                  setMoreAppDescreption(e.target.value);
                }}
                row={5}
                value={moreAppDescreption}
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <Input
              label={`Version`}
              id={`version`}
              type={`number`}
              value={version}
              onChange={(e) => {
                setVersion(e.target.value);
              }}
            />
            <div>
              <label className="label-input">Force Update</label>
              <ToggleSwitch
                onChange={setForceUpdate}
                value={forceUpdate}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "forceUpdate",
                    forceUpdate
                  )
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <div>
                <label className="label-input">Status</label>
                <ToggleSwitch
                  onChange={setIsPublished}
                  value={isPublished}
                  onClick={() => handlePublishSwitch()}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default connect(null, {
  getMoreAppAll,
  moreAppSubmit,
  updateApp,
  isHandleSwitchEdit,
  getAppDetails,
  removeMoreApp,
  isPublishedSwitch,
})(AddDetails);
