/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extra/Button";
import ToggleSwitch from "../extra/ToggleSwitch";
import dayjs from "dayjs";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { openDialog } from "../../store/slice/dialogueSlice";
import ShortVideoDialogue from "../dialogComponent/ShortVideoDialog";
import {
  deleteLiveStreamVideo,
  getLiveStreamingVideos,
} from "../../store/slice/liveStreamSlice";
import LiveStreamDialog  from "../dialogComponent/LiveStreamDialog";
import { isSkeleton } from "../utils/allSelector";

const Row = () => (
  <div className="row">
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
  </div>
);
const LiveStreamVideo = () => {
  const [data, setData] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const { liveStreamVideo, total } = useSelector(
    (state) => state.liveStreamVideo
  );
  const navigate = useNavigate();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const isSkeletonLoader = useSelector(isSkeleton);
  const [skeleton,setSkeleton]=useState()
  const numberOfRows = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    dispatch(getLiveStreamingVideos(payload));
  }, [start, limit]);

  useEffect(() => {
    const renderedRows = [...Array(numberOfRows)]?.map((e, i) => (
      <div>
        <Row />
      </div>
    ));
    setSkeleton(renderedRows)
  }, [numberOfRows])

  useEffect(() => {
    if (liveStreamVideo) {
      // const transformedData = liveStreamVideo?.flatMap((video) =>
      //   video?.link?.map((link) => ({
      //     _id: video?._id,
      //     link,
      //     createdAt: video?.createdAt,
      //     updatedAt: video?.updatedAt,
      //   }))
      // );
      setData(liveStreamVideo);
      setTableValues(liveStreamVideo);
    }
  }, [liveStreamVideo]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleDelete = (id) => {
    dispatch(deleteLiveStreamVideo(id));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="shortVideo">
      <Title title="Live Streaming Video" />
      {dialogue && dialogueType === "liveVideo" && <LiveStreamDialog  />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>
          Total Live Streaming Videos: {total}
        </h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={tableValues}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("liveVideo")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <div className="simple-table">
          <table>
            <thead>
              <tr>
                <th>
                  <span>Index</span>
                </th>
                <th>
                  <span>Video</span>
                </th>
                <th>
                  <span>Link</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
                <th>
                  <span>Created At</span>
                </th>
              </tr>
            </thead>
            {
              isSkeletonLoader === true ?
              (
                <tbody>
                <tr>
                  <td>
                    {skeleton}
                  </td>
                  <td>
                    {skeleton}
                  </td>
                  <td>
                    {skeleton}
                  </td>
                  <td>
                    {skeleton}
                  </td>
                  <td>
                    {skeleton}
                  </td>
                  </tr>
            </tbody>
              ):(
            <tbody>
              {data?.length > 0 &&
                data?.map((item, index) => {
                  const adjustedIndex = index + (start - 1) * limit + 1;
                  return (
                    <tr>
                      <td>
                        <span>{adjustedIndex}</span>
                      </td>
                      <td>
                        <video
                          controls
                          src={item?.link}
                          height="80"
                          width="80"
                        />
                      </td>
                      <td>
                        <span>{item?.link}</span>
                      </td>
                      <td>
                        <Button
                          btnIcon={<DeleteRoundedIcon />}
                          onClick={() => handleDelete(item?._id)}
                          newClass={"deleteBtn"}
                        />
                      </td>
                      <td>
                        <span className="text-nowrap">
                          {dayjs(item?.createdAt)?.format("DD MMM YYYY")}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
              )
            }
          </table>
        </div>
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default LiveStreamVideo;
