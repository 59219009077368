import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAdmin, getDashboardCount ,dashboardAnalyticData} from "../../store/slice/authSlice";
import { baseURL } from "../utils/config";
import Navbar from "../navbar/Navbar";
import PlayStoreIcon from "../../assets/images/icon/PlayStore.png";
import { ReactComponent as UpArrowIcon } from "../../assets/images/icon/UpArrowIcon.svg";
import Title from "../extra/Title";
import Button from "../extra/Button";
import { useNavigate } from "react-router-dom";
import DateRange from "../extra/DateRangePicker";
import Table from "../extra/Table";
import dayjs from "dayjs";

function Dashboard(props) {
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const [data, setData] = useState([]);
  const { dashboardCount, totalLiveApp ,dashData} = useSelector((state) => state.admin);
  const {   } = useSelector((state) => state.admin);

  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      startDate: startDate,
      endDate: endDate,
    };
    props.getDashboardCount();
    
    props.dashboardAnalyticData(payload);
  }, [startDate,endDate,props]);

  const handleAllDate = () => {
    setStartDate("All");
    setEndDate("All");
  };

  useEffect(() =>{
    
    setData(dashData)
  },[dashData])

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {index + 1}
        </span>
      ),
    },
    {
      Header: "Logo",
      body: "logo",
      Cell: ({ row }) => (
        <div className="image-showTable">
          {row?.appLogo && <img alt="logo" src={baseURL + "/" + row?.appLogo} style={{height:"48px",width:"48px",borderRadius:"7px",objectFit:"cover"}}  />}
        </div>
      ),
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appName}</span>,
    },
    {
      Header: "Account Name",
      body: "accountName",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.accountName ? row?.accountName : "-"}
        </span>
      ),
    },
    {
      Header: "Total",
      body: "total",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.total ? row?.total : "-"}</span>,
    },
    {
      Header: "New",
      body: "newUser",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.new}</span>,
    },
    {
      Header: "Organic",
      body: "organic",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.organic}</span>,
    },
    {
      Header: "Paid",
      body: "paid",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.paid}</span>,
    },
   
    
  ];
  return (
    <div className="dashboardPage appUser">
      <h3>Welcome Admin !</h3>
      <Title title={"Dashboard"} dashboardTitle={true} />
     
      <div className="row g-3 d-flex">
        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6>All Application</h6>
              <h4>{dashboardCount ? dashboardCount : "0"}</h4>
              <Button
                btnName={"View Details"}
                btnIcon={<UpArrowIcon />}
                type={"button"}
                newClass={"submit-btn"}
                onClick={() => navigate("/admin/app")}
              />
            </div>
            <div className="dashboard-card-right">
              <img src={PlayStoreIcon} />
            </div>
            <div></div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12">
          <div className="dashboard-card">
            <div className="dashboard-card-left">
              <h6>Total Live Apps</h6>
              <h4>{totalLiveApp ? totalLiveApp : "0"}</h4>
              <Button
                btnName={"View Details"}
                btnIcon={<UpArrowIcon />}
                type={"button"}
                newClass={"submit-btn"}
                onClick={() => navigate("/admin/app")}
              />
            </div>
            <div className="dashboard-card-right">
              <img src={PlayStoreIcon} />
            </div>
            
          </div>
        </div>
      </div>
      <div className="mt-3">
      {/* <Title title={"Dashboard"} dashboardTitle={true} /> */}
      <div className="col-12 col-sm-6 d-flex  ">
        <Button
          btnName={"All"}
          newClass={"all-dateBtn"}
          onClick={() => handleAllDate()}
        />
        <DateRange
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      </div>
      <div className="mt-4">
      <Table
          data={data}
          type={"server"}
          mapData={mapData}
        />
      </div>
    </div>
  );
}

export default connect(null, { getAdmin, getDashboardCount,dashboardAnalyticData })(Dashboard);
