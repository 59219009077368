import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";


const initialState = {
    testId:{},
    isLoading:false,
    isSkeleton:false,
}

export const getTestAdId = createAsyncThunk("admin/testAdId/getTestAdId",async (payload)=>{
    return apiInstanceFetch.get("admin/testAdId/getTestAdId")
})

export const updateTestAdId = createAsyncThunk("admin/testAdId/updateTestADId", async (payload) =>{
    
    return apiInstanceFetch.patch(`admin/testAdId/updateTestADId?testAdId=${payload?.id}`,payload?.data)
})


const testAdIdSlice = createSlice({
    name:"testAdIdSlice",
    initialState,
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(getTestAdId.pending,(state,action) =>{
            state.isSkeleton = true;
        })

        builder.addCase(getTestAdId.fulfilled,(state,action) =>{
            state.isSkeleton = false;
            state.testId = action?.payload?.testId
        })

        builder.addCase(getTestAdId.rejected,(state,action) =>{
            state.isSkeleton = false;
        })

        builder.addCase(updateTestAdId.pending, (state, action) => {
            state.isLoading = true;
          });
      
          builder.addCase(updateTestAdId.fulfilled, (state, action) => {
            if (action.payload.status) {
              state.testId = {
                ...state.testId,
                ...action.payload.testId
              };
            }
            state.isLoading = false;
            setToast("success", "Updated Successfully");
          });
          
          builder.addCase(updateTestAdId.rejected, (state, action) => {
            state.isLoading = false;
          });
      

    }
})
export default testAdIdSlice.reducer;