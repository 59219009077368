/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Input from "../extra/Input";
import Button from "../extra/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "../extra/TextArea";
import {
  addCustomAd,
  updateCustomAd,
  customAdDeleteImage,
} from "../../store/slice/customAdSlice";
import { baseURL } from "../utils/config";

const CustomAdDialogue = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState([]);
  const [iconPath, setIconPath] = useState([]);
  const [interstitial, setInterstitial] = useState([]);
  const [interstitialPath, setInterstitialPath] = useState([]);
  const [native, setNative] = useState([]);
  const [nativePath, setNativePath] = useState([]);
  const [banner, setBanner] = useState([]);
  const [bannerPath, setBannerPath] = useState([]);
  const [error, setError] = useState({
    title: "",
    description: "",
    buttonText: "",
    link: "",
    banner: "",
    interstitial: "",
    icon: "",
    native: "",
  });

  useEffect(() => {
    if (dialogueData) {
      if (dialogueData.link && dialogueData.link.length > 0) {
        setLink(dialogueData.link.join(","));
      }
      if (dialogueData.title && dialogueData.title.length > 0) {
        setTitle(dialogueData.title.join(","));
      }
      if (dialogueData.buttonText && dialogueData.buttonText.length > 0) {
        setButtonText(dialogueData.buttonText.join(","));
      }
      if (dialogueData.description && dialogueData.description.length > 0) {
        setDescription(dialogueData.description.join(","));
      }
      if (dialogueData.icon && dialogueData.icon.length > 0) {
        const iconUrl = dialogueData.icon.map((path) => baseURL + "/" + path);
        setIconPath(iconUrl);
      }
      if (dialogueData.banner && dialogueData.banner.length > 0) {
        const bannerUrl = dialogueData.banner.map(
          (path) => baseURL + "/" + path
        );
        setBannerPath(bannerUrl);
      }
      if (dialogueData.native && dialogueData.native.length > 0) {
        const nativeUrl = dialogueData.native.map(
          (path) => baseURL + "/" + path
        );
        setNativePath(nativeUrl);
      }
      if (dialogueData.interstitial && dialogueData.interstitial.length > 0) {
        const interstitialUrl = dialogueData.interstitial.map(
          (path) => baseURL + "/" + path
        );
        setInterstitialPath(interstitialUrl);
      }
    }
  }, [dialogueData]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleImage = (e, type, pathState, setState, setPathState) => {
    const selectedImages = Array.from(e.target.files);
    const imageUrls = selectedImages.map((image) => URL.createObjectURL(image));

    setState((prevSelectedImages) => [
      ...prevSelectedImages,
      ...selectedImages,
    ]);
    setPathState((prevImageUrls) => [...prevImageUrls, ...imageUrls]);


    if (!e.target.files.length) {
      return setError({
        ...error,
        [type]: `${type} Is Required`,
      });
    } else {
      return setError({
        ...error,
        [type]: "",
      });
    }
  };

  const removeImage = (
    index,
    state,
    setState,
    pathState,
    setPathState,
    type
  ) => {
    // const updatedImages = state.filter((_, i) => i !== index);
    // setState(updatedImages);
    // const updatedPaths = pathState.filter((_, i) => i !== index);
    // setPathState(updatedPaths);
    const payload = {
      id: dialogueData?._id,
      position: index,
      type: type,
    };
    dispatch(customAdDeleteImage(payload));

    const updatedSelectedImages = [...state];
    const updatedImageUrls = [...pathState];
    updatedSelectedImages.splice(index, 1);
    updatedImageUrls.splice(index, 1);

    setState(updatedSelectedImages);
    setPathState(updatedImageUrls);
  };

  // Usage
  const handleIcon = (e) =>
    handleImage(e, "icon", iconPath, setIcon, setIconPath);
  const removeIcon = (index) =>
    removeImage(index, icon, setIcon, iconPath, setIconPath, "icon");

  const handleBanner = (e) =>
    handleImage(e, "banner", bannerPath, setBanner, setBannerPath);
  const removeBanner = (index) =>
    removeImage(index, banner, setBanner, bannerPath, setBannerPath, "banner");

  const handleNative = (e) =>
    handleImage(e, "native", nativePath, setNative, setNativePath);
  const removeNative = (index) =>
    removeImage(index, native, setNative, nativePath, setNativePath, "native");

  const handleInt = (e) =>
    handleImage(
      e,
      "interstitial",
      interstitialPath,
      setInterstitial,
      setInterstitialPath
    );
  const removeInt = (index) =>
    removeImage(
      index,
      interstitial,
      setInterstitial,
      interstitialPath,
      setInterstitialPath,
      "interstitial"
    );

  const handleSubmit = () => {
    const formData = new FormData();
    for (let i = 0; i < icon?.length; i++) {
      formData.append("icon", icon[i]);
    }

    for (let i = 0; i < banner.length; i++) {
      formData.append("banner", banner[i]);
    }

    for (let i = 0; i < native.length; i++) {
      formData.append("native", native[i]);
    }

    for (let i = 0; i < interstitial.length; i++) {
      formData.append("interstitial", interstitial[i]);
    }

    formData.append("link", link?.trim());
    formData.append("buttonText", buttonText?.trim());
    formData.append("description", description?.trim());
    formData.append("title", title?.trim());
    if (dialogueData) {
      let payload = { data: formData, id: dialogueData?._id };
      dispatch(updateCustomAd(payload));
      dispatch(closeDialog());
    } else {
      if (!link || !buttonText || !description || !title || icon.length <= 0) {
        let error = {};
        if (!link) error.link = "Link is Required";
        if (!buttonText) error.buttonText = "buttonText is Required";
        if (!description) error.description = "description is Required";
        if (!title) error.title = "title is Required";
        if (icon.length <= 0) error.icon = "Icon is Required";
        if (interstitial.length <= 0)
          error.interstitial = "interstitial is Required";
        if (banner.length <= 0) error.banner = "banner is Required";
        if (native.length <= 0) error.native = "Native is Required";

        return setError({ ...error });
      } else {
        dispatch(addCustomAd(formData));
        dispatch(closeDialog());
      }
    }
    // dispatch(closeDialog());
  };
  return (
    <div className="createCustomAd">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>{dialogueData ? "Update Custom Ad" :"Create Custom Ad"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row mt-3">
              <div className="col-12 col-sm-6">
                <TextArea
                  label={`Title`}
                  placeholder={"Enter Title"}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        title: `title Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        title: "",
                      });
                    }
                  }}
                  row={1}
                  value={title}
                  note={`Note : Enter coma (,) separated string.`}
                />
                {error.title && <p className="errorMessage">{error.title}</p>}
              </div>

              <div className="col-12 col-sm-6">
                <TextArea
                  label={`Description`}
                  placeholder={"Enter Description"}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        description: `description Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        description: "",
                      });
                    }
                  }}
                  row={1}
                  value={description}
                  note={`Note : Enter coma (,) separated string.`}
                />
                {error.description && (
                  <p className="errorMessage">{error.description}</p>
                )}
              </div>
              <div className="col-12 col-sm-6">
                <TextArea
                  label={`Button Text`}
                  placeholder={"Enter Button Text"}
                  onChange={(e) => {
                    setButtonText(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        buttonText: `buttonText Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        buttonText: "",
                      });
                    }
                  }}
                  row={1}
                  value={buttonText}
                  note={`Note : Enter coma (,) separated string.`}
                />
                {error.buttonText && (
                  <p className="errorMessage">{error.buttonText}</p>
                )}
              </div>

              <div className="col-12 col-sm-6">
                <TextArea
                  label={`Link`}
                  placeholder={"Enter Link"}
                  onChange={(e) => {
                    setLink(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        link: `Link Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        link: "",
                      });
                    }
                  }}
                  row={1}
                  value={link}
                  note={`Note : Enter coma (,) separated string.`}
                />
                {error.link && <p className="errorMessage">{error.link}</p>}
              </div>

              <div className="col-12 d-flex">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="inputData  image-input">
                      <div>
                        <label>Icon</label>
                      </div>
                      <input
                        type="file"
                        multiple
                        accept="image/gif, image/jpeg, image/png"
                        onChange={(e) => handleIcon(e)}
                        className="form-input"
                      />
                    </div>
                    {error.icon && <p className="errorMessage">{error.icon}</p>}
                  </div>
                  {iconPath?.length > 0 && (
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div className="image-start mt-3">
                        {iconPath.map((icon, index) => (
                          <div
                            key={index}
                            className="image-preview-multipal d-flex align-items-center"
                          >
                            <div className="image-box">
                              <img
                                src={icon}
                                alt={`ServiceImage${index}`}
                                className="form-input"
                                draggable="false"
                                width={"60px"}
                                height={"60px"}
                              />
                              <IconButton
                                onClick={() => removeIcon(index)}
                                sx={{ fontSize: "7px", cursor: "pointer" }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 d-flex">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="inputData ">
                      <div>
                        <label>Native</label>
                      </div>
                      <input
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        multiple
                        // value={icon}
                        onChange={(e) => handleNative(e)}
                        className="form-input"
                      />
                    </div>
                    {error.native && (
                      <p className="errorMessage">{error.native}</p>
                    )}
                  </div>
                  {nativePath?.length > 0 && (
                    <div className="col-12 col-sm-6  mb-3 mb-sm-0">
                      <div className="image-start mt-3">
                        {nativePath.map((native, index) => (
                          <div
                            key={index}
                            className="image-preview-multipal d-flex align-items-center"
                          >
                            <div className="image-box">
                              <img
                                src={native}
                                alt={`ServiceImage${index}`}
                                className="form-input"
                                draggable="false"
                                width={"60px"}
                                height={"60px"}
                              />
                              <IconButton
                                onClick={() => removeNative(index)}
                                sx={{ fontSize: "7px", cursor: "pointer" }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 d-flex">
                <div className="row">
                  <div className="inputData col-12 col-sm-6">
                    <div>
                      <label>Banner</label>
                    </div>
                    <input
                      type="file"
                      multiple
                      accept="image/gif, image/jpeg, image/png"
                      // value={icon}
                      onChange={(e) => handleBanner(e)}
                      id="image"
                      name="image"
                      className="form-input"
                    />
                    {error.banner && (
                      <p className="errorMessage">{error.banner}</p>
                    )}
                  </div>
                  {bannerPath?.length > 0 && (
                    <div className="image-start mt-3 col-12 col-sm-6  mb-3 mb-sm-0">
                      {bannerPath.map((banner, index) => (
                        <div
                          key={index}
                          className="image-preview-multipal d-flex align-items-center"
                        >
                          <div className="image-box">
                            <img
                              src={banner}
                              alt={`ServiceImage${index}`}
                              className="form-input"
                              draggable="false"
                              width={"60px"}
                              height={"60px"}
                            />
                            <IconButton
                              onClick={() => removeBanner(index)}
                              sx={{ fontSize: "7px", cursor: "pointer" }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 d-flex">
                <div className="row">
                  <div className="inputData col-12 col-sm-6">
                    <div>
                      <label>Interstitial</label>
                    </div>
                    <input
                      type="file"
                      multiple
                      accept="image/gif, image/jpeg, image/png"
                      onChange={(e) => handleInt(e)}
                      className="form-input"
                    />
                    {error.interstitial && (
                      <p className="errorMessage">{error.interstitial}</p>
                    )}
                  </div>
                  {interstitialPath?.length > 0 && (
                    <div className="image-start mt-3 col-12 col-sm-6  mb-3 mb-sm-0">
                      {interstitialPath.map((interstitial, index) => (
                        <div
                          key={index}
                          className="image-preview-multipal d-flex align-items-center"
                        >
                          <div className="image-box">
                            <img
                              src={interstitial}
                              alt={`ServiceImage${index}`}
                              className="form-input"
                              draggable="false"
                              width={"60px"}
                              height={"60px"}
                            />
                            <IconButton
                              onClick={() => removeInt(index)}
                              sx={{ fontSize: "7px", cursor: "pointer" }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row dialog-footer">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={handleClose}
                style={{ marginRight: "10px" }}
                newClass={"cancel-btn"}
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass={"submit-btn"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomAdDialogue;
