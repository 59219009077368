/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { warning } from "../utils/Alert";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { openDialog } from "../../store/slice/dialogueSlice";
import {
  deleteDeveloper,
  getDevelopers,
} from "../../store/slice/developerSlice";
import DevDialog from "../dialogComponent/DevDialog";

const Developer = (props) => {
  const { developer, total } = useSelector((state) => state?.developer);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };

    props.getDevelopers(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(developer);
  }, [developer]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.name}</span>,
    },
    {
      Header: "IP",
      body: "ipAddress",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.ipAddress !== null ? row?.ipAddress : "-"}
        </span>
      ),
    },
    {
      Header: "Last Login",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.lastLogin !== null ? row?.lastLogin : "-"}
        </span>
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD/MM/YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          onClick={() =>
            dispatch(openDialog({ dialogueType: "dev", dialogueData: row }))
          }
          btnIcon={<EditRoundedIcon />}
          newClass="editBtn"
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass="deleteBtn"
          onClick={() => handleDeleteApp(row)}
        />
      ),
    },
    {
      Header: "Created At	",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  const handleDeleteApp = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              id: row?._id,
              name: row?.name,
            };
            props.deleteDeveloper(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="mainCustomAd">
      <Title title="Developers" />
      {dialogue && dialogueType === "dev" && <DevDialog />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total Developers: {total}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={developer}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("dev")}
              newClass={"add-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={total}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, {
  getDevelopers,
  deleteDeveloper,
})(Developer);
