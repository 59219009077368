import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  getSettingApp,
  appSettingSwitch,
  paymentSettingSwitch,
  updateAppSetting,
} from "../../../store/slice/appSlice";
import OtherSettingInput from "../../extra/OtherSettingInput";
import { useLocation } from "react-router-dom";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import ToggleSwitch from "../../extra/ToggleSwitch";

const AppEditSetting = (props) => {
  const location = useLocation();
  const { appSettings } = useSelector((state) => state.app);

  const [p1, setP1] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p1Name",
    typeValue: "p1Value",
  });
  const [p2, setP2] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p2Name",
    typeValue: "p2Value",
  });
  const [p3, setP3] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p3Name",
    typeValue: "p3Value",
  });
  const [p4, setP4] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p4Name",
    typeValue: "p4Value",
  });
  const [p5, setP5] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p5Name",
    typeValue: "p5Value",
  });
  const [p6, setP6] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p6Name",
    typeValue: "p6Value",
  });
  const [p7, setP7] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p7Name",
    typeValue: "p7Value",
  });
  const [p8, setP8] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p8Name",
    typeValue: "p8Value",
  });
  const [p9, setP9] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p9Name",
    typeValue: "p9Value",
  });
  const [p10, setP10] = useState({
    text: "",
    title: "",
    typeAdd: "P",
    typeText: "p10Name",
    typeValue: "p10Value",
  });

  const [p1Switch, setP1Switch] = useState(false);
  const [p2Switch, setP2Switch] = useState(false);
  const [p3Switch, setP3Switch] = useState(false);
  const [p4Switch, setP4Switch] = useState(false);
  const [p5Switch, setP5Switch] = useState(false);
  const [p6Switch, setP6Switch] = useState(false);
  const [p7Switch, setP7Switch] = useState(false);
  const [p8Switch, setP8Switch] = useState(false);
  const [p9Switch, setP9Switch] = useState(false);
  const [p10Switch, setP10Switch] = useState(false);
  const [settingInput, setSettingInput] = useState({
    oneSignalId: "",
    onesignalApiKey: "",
    stripePublishableKey: "",
    stripeSecretKey: "",
    razorpayId: "",
    razorpayKey: "",
    upiKey: "",
    upiRedirectUrl: "",
    googlePlayEmail: "",
    googlePlayKey: "",
    coinBaseKey:""
  });
  const [stripeSwitch, setStripeSwitch] = useState();
  const [razorPaySwitch, setRazorPaySwitch] = useState();
  const [upiSwitch, setUpiSwitch] = useState();
  const [googleSwitch, setGoogleSwitch] = useState();
  const [coinBaseSwitch, setCoinBaseSwitch] = useState(false);



  const handleSwitchChange = (type, value) => {
    const payload = {
      appId: location.state.editAppData?._id,
      type: type,
      settingId: appSettings?._id,
      value: value,
    };
    props.appSettingSwitch(payload);
  };

  useEffect(() => {
    props.getSettingApp(location?.state?.editAppData?._id);
  }, [location?.state]);

  useEffect(() => {
    setP1Switch(appSettings?.p1?.p1Switch);
    setP2Switch(appSettings?.p2?.p2Switch);
    setP3Switch(appSettings?.p3?.p3Switch);
    setP4Switch(appSettings?.p4?.p4Switch);
    setP5Switch(appSettings?.p5?.p5Switch);
    setP6Switch(appSettings?.p6?.p6Switch);
    setP7Switch(appSettings?.p7?.p7Switch);
    setP8Switch(appSettings?.p8?.p8Switch);
    setP9Switch(appSettings?.p9?.p9Switch);
    setP10Switch(appSettings?.p10?.p10Switch);

    setP1({
      ...p1,
      text: appSettings?.p1?.p1Name,
      title: appSettings?.p1?.p1Value,
    });
    setP2({
      ...p2,
      text: appSettings?.p2?.p2Name,
      title: appSettings?.p2?.p2Value,
    });
    setP3({
      ...p3,
      text: appSettings?.p3?.p3Name,
      title: appSettings?.p3?.p3Value,
    });
    setP4({
      ...p4,
      text: appSettings?.p4?.p4Name,
      title: appSettings?.p4?.p4Value,
    });
    setP5({
      ...p5,
      text: appSettings?.p5?.p5Name,
      title: appSettings?.p5?.p5Value,
    });
    setP6({
      ...p6,
      text: appSettings?.p6?.p6Name,
      title: appSettings?.p6?.p6Value,
    });
    setP7({
      ...p7,
      text: appSettings?.p7?.p7Name,
      title: appSettings?.p7?.p7Value,
    });
    setP8({
      ...p8,
      text: appSettings?.p8?.p8Name,
      title: appSettings?.p8?.p8Value,
    });
    setP9({
      ...p9,
      text: appSettings?.p9?.p9Name,
      title: appSettings?.p9?.p9Value,
    });
    setP10({
      ...p10,
      text: appSettings?.p10?.p10Name,
      title: appSettings?.p10?.p10Value,
    });

    setSettingInput({
      oneSignalId: appSettings?.oneSignalId,
      onesignalApiKey: appSettings?.onesignalApiKey,
      stripePublishableKey: appSettings?.stripePublishableKey,
      stripeSecretKey: appSettings?.stripeSecretKey,
      razorpayId: appSettings?.razorpayId,
      razorpayKey: appSettings?.razorpayKey,
      upiKey: appSettings?.upiKey,
      upiRedirectUrl: appSettings?.upiRedirectUrl,
      googlePlayEmail: appSettings?.googlePlayEmail,
      googlePlayKey: appSettings?.googlePlayKey,
      coinBaseKey:appSettings?.coinBaseKey
    });

    setStripeSwitch(appSettings?.stripeSwitch)
    setGoogleSwitch(appSettings?.googlePlaySwitch)
    setUpiSwitch(appSettings?.upiSwitch)
    setRazorPaySwitch(appSettings?.razorpaySwitch)
    setCoinBaseSwitch(appSettings?.coinBaseSwitch)
  }, [appSettings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettingInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      appId: appSettings?.appId,
      data: settingInput,
      settingId: appSettings?._id,
    };
    props.updateAppSetting(payload);
  };

  const handleSwitch = (id, type, value) => {
    const payload = {
      appId: location.state.editAppData?._id,
      type: type,
      settingId: appSettings?._id,
      value: value,
    };
    props.paymentSettingSwitch(payload);
  };

  return (
    <div className="app-setting">
      <form>
        <Button
          btnName={"Submit"}
          type={"submit"}
          newClass={"submit-btn"}
          onClick={handleSubmit}
        />
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="setting-box">
              <div className="setting-field">
                <h6>One Signal Setting</h6>

                <Input
                  label={"One Signal ID"}
                  type={`text`}
                  name={"oneSignalId"}
                  value={settingInput?.oneSignalId}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  label={"One Signal Api Key"}
                  type={`text`}
                  name={"onesignalApiKey"}
                  value={settingInput?.onesignalApiKey}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="setting-field">
                <div className="setting-title">
                  <h6>Stripe Setting</h6>
                  <div>
                    <ToggleSwitch
                      onChange={setStripeSwitch}
                      value={stripeSwitch}
                      onClick={() =>
                        handleSwitch(
                          location.state.editAppData?._id,
                          "stripeSwitch",
                          stripeSwitch
                        )
                      }
                    />
                  </div>
                </div>
                <Input
                  label={"Stripe Publishable Key"}
                  type={`text`}
                  name={"stripePublishableKey"}
                  value={settingInput?.stripePublishableKey}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  label={"Stripe Secret Key"}
                  type={`text`}
                  name={"stripeSecretKey"}
                  value={settingInput?.stripeSecretKey}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="setting-field">
                <div className="setting-title">
                  <h6>Razor Pay Setting</h6>
                  <div>
                    <ToggleSwitch
                      onChange={setRazorPaySwitch}
                      value={razorPaySwitch}
                      onClick={() =>
                        handleSwitch(
                          location.state.editAppData?._id,
                          "razorpaySwitch",
                          razorPaySwitch
                        )
                      }
                    />
                  </div>
                </div>
                <Input
                  label={"Razor Pay ID"}
                  type={`text`}
                  name={"razorpayId"}
                  value={settingInput?.razorpayId}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  label={"Razor Pay Key"}
                  type={`text`}
                  name={"razorpayKey"}
                  value={settingInput?.razorpayKey}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="setting-field">
                <div className="setting-title">
                  <h6>Upi Setting</h6>
                  <div>
                    <ToggleSwitch
                      onChange={setUpiSwitch}
                      value={upiSwitch}
                      onClick={() =>
                        handleSwitch(
                          location.state.editAppData?._id,
                          "upiSwitch",
                          upiSwitch
                        )
                      }
                    />
                  </div>
                </div>
                <Input
                  label={"UPI Key"}
                  type={`text`}
                  name={"upiKey"}
                  value={settingInput?.upiKey}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  label={"UPI Redirect URL"}
                  type={`text`}
                  name={"upiRedirectUrl"}
                  value={settingInput?.upiRedirectUrl}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="setting-field">
                <div className="setting-title">
                  <h6>Google Play Setting</h6>
                  <div>
                    <ToggleSwitch
                      onChange={setGoogleSwitch}
                      value={googleSwitch}
                      onClick={() =>
                        handleSwitch(
                          location.state.editAppData?._id,
                          "googlePlaySwitch",
                          googleSwitch
                        )
                      }
                    />
                  </div>
                </div>
                <Input
                  label={"Google Play Email"}
                  type={`text`}
                  name={"googlePlayEmail"}
                  value={settingInput?.googlePlayEmail}
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  label={"Google Play Key"}
                  type={`text`}
                  name={"googlePlayKey"}
                  value={settingInput?.googlePlayKey}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="setting-field">
                <div className="setting-title">
                  <h6>CoinBase Setting</h6>
                  <div>
                    <ToggleSwitch
                      onChange={setCoinBaseSwitch}
                      value={coinBaseSwitch}
                      onClick={() =>
                        handleSwitch(
                          location.state.editAppData?._id,
                          "coinBaseSwitch",
                          coinBaseSwitch
                        )
                      }
                    />
                  </div>
                </div>
                <Input
                  label={"CoinBase Key"}
                  type={`text`}
                  name={"coinBaseKey"}
                  value={settingInput?.coinBaseKey}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="pBox">
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P1"}
                  valueData={p1}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p1Switch", p1Switch)}
                  switchValue={p1Switch}
                  onChangeSwitch={setP1Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P2"}
                  valueData={p2}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p2Switch", p2Switch)}
                  switchValue={p2Switch}
                  onChangeSwitch={setP2Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P3"}
                  valueData={p3}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p3Switch", p3Switch)}
                  switchValue={p3Switch}
                  onChangeSwitch={setP3Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P4"}
                  valueData={p4}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p4Switch", p4Switch)}
                  switchValue={p4Switch}
                  onChangeSwitch={setP4Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P5"}
                  valueData={p5}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p5Switch", p5Switch)}
                  switchValue={p5Switch}
                  onChangeSwitch={setP5Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P6"}
                  valueData={p6}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p6Switch", p6Switch)}
                  switchValue={p6Switch}
                  onChangeSwitch={setP6Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P7"}
                  valueData={p7}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p7Switch", p7Switch)}
                  switchValue={p7Switch}
                  onChangeSwitch={setP7Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P8"}
                  valueData={p8}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p8Switch", p8Switch)}
                  switchValue={p8Switch}
                  onChangeSwitch={setP8Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P9"}
                  valueData={p9}
                  settingId={appSettings?._id}
                  onClickSwitch={() => handleSwitchChange("p9Switch", p9Switch)}
                  switchValue={p9Switch}
                  onChangeSwitch={setP9Switch}
                />
              </div>
              <div className="input-setting mt-3">
                <OtherSettingInput
                  label={"P10"}
                  valueData={p10}
                  settingId={appSettings?._id}
                  onClickSwitch={() =>
                    handleSwitchChange("p10Switch", p10Switch)
                  }
                  switchValue={p10Switch}
                  onChangeSwitch={setP10Switch}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default connect(null, {
  getSettingApp,
  appSettingSwitch,
  paymentSettingSwitch,
  updateAppSetting,
})(AppEditSetting);
