import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  hostData: [],
  hostTotal: 0,
  fakeMessageData: [],
  fakeMessageId: "",
  fakeCommentData: [],
  fakeCommentId: "",
  total: null,
  isLoading: false,
  isSkeleton: false,
};

export const getHost = createAsyncThunk(
  "admin/host/getHostWithImage",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/host/getHostWithImage?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);
export const getHostTwo = createAsyncThunk(
  "admin/host/getHostWithVideo",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/host/getHostWithVideo?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const getFakeMessage = createAsyncThunk(
  "admin/message/getMessage",
  async (payload) => {
    return apiInstanceFetch.get(`admin/message/getMessage?type=${payload}`);
  }
);

export const updatedFakeMessage = createAsyncThunk(
  "admin/message/updateMessage",
  async (payload) => {
    return apiInstance.patch(`admin/message/updateMessage`, payload?.data);
  }
);

export const getFakeComment = createAsyncThunk(
  "fakeComment/getfakeComment",
  async (payload) => {
    return apiInstanceFetch.get(`admin/fakeComment/getfakeComment`);
  }
);

export const updatedFakeComment = createAsyncThunk(
  "fakeComment/updatefakeComment",
  async (payload) => {
    return apiInstance.patch(
      `admin/fakeComment/updatefakeComment?fakeCommentId=${payload?.id}`,
      payload?.data
    );
  }
);

export const hostCreate = createAsyncThunk(
  "admin/host/createHost",
  async (payload) => {
    return apiInstance.post("admin/host/createHost", payload.data);
  }
);

export const updatedHost = createAsyncThunk(
  "admin/host/updateHost?hostId",
  async (payload) => {
    return apiInstance.patch(
      `admin/host/updateHost?hostId=${payload?.id}`,
      payload?.data
    );
  }
);

export const deleteHost = createAsyncThunk(
  "admin/host/deleteHost",
  async (id) => {
    return apiInstanceFetch.delete(`admin/host/deleteHost?hostId=${id}`);
  }
);

export const deleteHostImage = createAsyncThunk(
  "admin/host/deleteImageOfHost",
  async (payload) => {
    return apiInstanceFetch.delete(
      `admin/host/deleteImageOfHost?hostId=${payload?.hostId}&position=${payload?.index}`
    );
  }
);

const moreAppSlice = createSlice({
  name: "hostData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHost.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getHost.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.hostData = action.payload.host;
      state.hostTotal = action.payload.totalHosts;
    });
    builder.addCase(getHost.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getHostTwo.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getHostTwo.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.hostData = action.payload.host;
      state.hostTotal = action.payload.totalHosts;
    });
    builder.addCase(getHostTwo.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getFakeMessage.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getFakeMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fakeMessageData = action.payload.data?.message;
      state.fakeMessageId = action.payload.data?._id;
    });
    builder.addCase(getFakeMessage.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatedFakeMessage.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updatedFakeMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fakeMessageData = action.payload?.message?.message;
      setToast("success", "Message Update Successfully");
    });
    builder.addCase(updatedFakeMessage.rejected, (state, action) => {
      setToast("error", action.payload.message);
      state.isLoading = false;
    });

    builder.addCase(getFakeComment.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getFakeComment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fakeCommentData = action.payload.fakeComment?.fakeComment;
      state.fakeCommentId = action.payload.fakeComment?._id;
    });
    builder.addCase(getFakeComment.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatedFakeComment.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updatedFakeComment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fakeCommentData = action.payload?.fakeComment?.fakeComment;
      setToast("success", "Comment Updated Successfully");
    });
    builder.addCase(updatedFakeComment.rejected, (state, action) => {
      setToast("error", action.payload.message);
      state.isLoading = false;
    });

    builder.addCase(hostCreate.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(hostCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hostData.unshift(action?.payload?.data);
      setToast("success", "Host Added Successfully");
    });

    builder.addCase(hostCreate.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatedHost.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updatedHost.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        state.hostData = state.hostData?.map((app) => {
          if (app?._id === action.meta.arg?.id) {
            return action.payload?.data;
          }
          return app;
        });
        setToast("success", "Host Updated Successfully");
      }
    });

    builder.addCase(updatedHost.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteHost.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteHost.fulfilled, (state, action) => {
      state.hostData = state.hostData.filter(
        (hostData) => hostData._id !== action.meta.arg
      );
      state.isLoading = false;
      setToast("success", "Host Deleted  Successfully");
    });
    builder.addCase(deleteHost.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteHostImage.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteHostImage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        state.hostData = state.hostData?.map((app) => {
          if (app?._id === action.meta.arg?.hostId) {
            return action.payload?.host;
          }
          return app;
        });
        setToast("success", "Image Deleted Successfully");
      }
    });
    builder.addCase(deleteHostImage.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default moreAppSlice.reducer;
