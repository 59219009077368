import React, { useEffect, useState } from "react";
import { getGame, deleteGame } from "../../store/slice/gameSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../extra/Title";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Searching from "../extra/Searching";
import Button from "../extra/Button";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Videogames from "../../assets/images/icon/GameDefaultImage.png";
import Table from "../extra/Table";
import { baseURL } from "../utils/config";
import dayjs from "dayjs";
import { warning } from "../utils/Alert";
import Rating from "@mui/material/Rating";
import Pagination from "../extra/Pagination";
import { openDialog } from "../../store/slice/dialogueSlice";
import GameDialog from "../dialogComponent/GameDialog";

const Game = (props) => {
  const { game, totalGames } = useSelector((state) => state.game);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  $(document).ready(function () {
    $(".imageShow").bind("error", function () {
      // Set the default image
      $(this).attr("src", Videogames);
    });
  });

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    props.getGame(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(game);
  }, [game]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleEditApp = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };
    dispatch(openDialog(payload));
  };

  const handleDelteApp = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              name: row?.name,
              id: row?._id,
            };
            props.deleteGame(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <div>
          {row?.image && (
            <img src={row?.banner} id="imageShow" className="imageShow" />
          )}
        </div>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.name}</span>,
    },
    {
      Header: "Link",
      body: "link",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.link}</span>,
    },
    {
      Header: "Rating",
      body: "rating",
      Cell: ({ row }) => (
        <div>
          <Rating
            name="read-only"
            value={row?.rating && row?.rating}
            precision={0.5}
            readOnly
          />
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditApp(row, "updateGame")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDelteApp(row)}
          newClass={"delete-btn"}
        />
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMM YYYY") : ""}
        </span>
      ),
    },
  ];

  return (
    <div className="gamePage">
      {(dialogueType === "updateGame" || dialogueType === "newGame") && (
        <GameDialog />
      )}
      <Title title={"Game"} />
      <div className="game-table">
        <h6 style={{ marginTop: "10px" }}>Total Games : {totalGames}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={game}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newGame")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={totalGames}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, { getGame, deleteGame })(Game);
