/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  getMovedApp,
  restoreApp,
  deleteMoveApp,
} from "../../store/slice/movedAppSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { warning } from "../utils/Alert";
import { deleteMoreApp, getMoreApp } from "../../store/slice/moreAppSlice";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { openDialog } from "../../store/slice/dialogueSlice";
import MoreAppDialog  from "../dialogComponent/MoreAppDialog";
import ToggleSwitch from "../extra/ToggleSwitch";
import { useNavigate } from "react-router-dom";

const MoreApp = (props) => {
  const { moreApp, total } = useSelector((state) => state.moreApp);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Logo",
      body: "logo",
      Cell: ({ row }) => (
        <div className="image-showTable">
          {<img src={baseURL + "/" + row?.appLogo} />}
        </div>
      ),
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appName}</span>,
    },
    {
      Header: "Key",
      body: "moreAppKey",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appKey}</span>,
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) =>
        row?.isMore === false ? (
          <Button
            onClick={() =>
              navigate("/admin/updateApp", { state: { editAppData: row } })
            }
            btnIcon={<EditRoundedIcon />}
            newClass="editBtn"
          />
        ) : (
          <Button
            onClick={() =>
              dispatch(
                openDialog({ dialogueType: "moreApp", dialogueData: row })
              )
            }
            btnIcon={<EditRoundedIcon />}
            newClass="editBtn"
          />
        ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass="deleteBtn"
          onClick={() => handleDeleteApp(row?._id)}
        />
      ),
    },
    {
      Header: "Created At	",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  const handleDeleteApp = (id) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            dispatch(deleteMoreApp(id));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };
    dispatch(getMoreApp(payload));
  }, [start, limit]);

  useEffect(() => {
    setData(moreApp);
  }, [moreApp]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  return (
    <div className="mainCustomAd">
      <Title title="More App" />
      {dialogue && dialogueType === "moreApp" && <MoreAppDialog  />}
      <div className="customAdTable">
        <h6 style={{ marginTop: "10px" }}>Total More App: {total}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={moreApp}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("moreApp")}
              newClass={"add-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={data?.length}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default MoreApp;
