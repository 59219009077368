import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { appUserGet } from "../../../store/slice/appSlice";
import DateRange from "../../extra/DateRangePicker";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Table from "../../extra/Table";
import { baseURL } from "../../utils/config";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import NoImage from "../../../assets/images/noImage.jpg"

const UserEdit = (props) => {
  const {
    appTotalUsers,
    appTotalUser,
    appNewUser,
    appDirectUser,
    appMarketThrough,
    appUser,
  } = useSelector((state) => state.app);

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  const location = useLocation();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
      appId: location?.state?.editAppData?._id,
      startDate: startDate,
      endDate: endDate,
    };
    props.appUserGet(payload);
  }, [startDate, endDate, start, limit, location?.state]);

  useEffect(() => {
    setData(appUser);
  }, [appUser]);

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Profile Image",
      body: "profileImage",
      Cell: ({ row }) => (
        <div className="logo-image">
            <img
            src={row?.profileImage ? baseURL + "/" + row?.profileImage : NoImage}
              alt="logo"
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "7px",
                objectFit: "cover",
                border: "1px solid #e8e8e8",
                boxShadow:"0 0 1px #000000"
              }}
            />
        </div>
      ),
    },
    {
      Header: "Name",
      body: "name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.userName ? row?.userName : "-"}
        </span>
      ),
    },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.email ? row?.email : "-"}</span>
      ),
    },
    {
      Header: "Country",
      body: "country",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.country ? row?.country : "-"}
        </span>
      ),
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt && dayjs(row?.createdAt)?.format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      Header: "Last login",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.lastLogin}
        </span>
      ),
    },
  ];

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleAllDate = () => {
    setStartDate("All");
    setEndDate("All");
  };
  return (
    <div className="appUser">
      <div className="row">
        <div className="col-12 col-sm-2 mb-2 mb-md-0">
          <div className="totalUser-box">
            <h6>Total User : {appTotalUsers ? appTotalUsers : "0"}</h6>
          </div>
        </div>
        <div className="col-12 col-sm-10 d-flex justify-content-end">
          <div className="totalUser-box">
            <h6>Total Analytic User : {appTotalUser ? appTotalUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>New User : {appNewUser ? appNewUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>Organic User : {appDirectUser ? appDirectUser : "0"}</h6>
          </div>
          <div className="totalUser-box">
            <h6>Paid User: {appMarketThrough ? appMarketThrough : "0"}</h6>
          </div>
        </div>
      </div>
      <div className="row mt-2 search-input-box">
        <div className="col-12 col-sm-6">
          <Searching
            placeHolder={"What’re you searching for?"}
            type="client"
            buttonShow={true}
            data={appUser}
            setData={setData}
            onFilterData={handleFilterData}
            searchValue={search}
          />
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center justify-content-sm-end">
          <Button
            btnName={"All"}
            newClass={"all-dateBtn"}
            onClick={() => handleAllDate()}
          />
          <DateRange
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      <div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={appTotalUser}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, {
  appUserGet,
})(UserEdit);
