import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import Input from "../extra/Input";
import {
  createSpecifiedNotification,
  sendSpecifiedNotification,
  getApp,
  editNotification,
} from "../../store/slice/notificationSlice";
import SendIcon from "@mui/icons-material/Send";
import { getMoreApp } from "../../store/slice/moreAppSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import Select from "react-select";
import { baseURL } from "../utils/config";

const SpecifiedNotifiDialogue = (props) => {
  const dispatch = useDispatch();
  const { getAppData, getSpecifiedNotiData } = useSelector(
    (state) => state.notification
  );

  const { moreApp } = useSelector((state) => state.moreApp);

  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const [specificNotiTime, setSpecificNotiTime] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectMoreAppData, setSelectMoreAppData] = useState([]);
  const [error, setError] = useState({
    selectedOptions: "",
    specificNotiTime: "",
  });

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);

    const selectedIds = selected?.map((option) => option?._id);
    setSelectMoreAppData(selectedIds);
    if (!selected) {
      return setError({
        ...error,
        selectedOptions: `App Is Required !`,
      });
    } else {
      return setError({
        ...error,
        selectedOptions: "",
      });
    }
  };

  useEffect(() => {
    props.getApp();
  }, []);

  const handleSubmitpecificTime = () => {
    const selectOption = selectMoreAppData?.length === 0;
    if (selectOption || !specificNotiTime) {
      let error = {};
      if (selectOption) error.selectedOptions = "App Is Required !";
      if (!specificNotiTime) error.specificNotiTime = "Time Is Required !";
      return setError({ ...error });
    } else {
      const getId = selectMoreAppData && selectMoreAppData?.join(",");
      let data = {
        appId: getId,
        time: specificNotiTime,
      };
      props.sendSpecifiedNotification(data);
      dispatch(closeDialog());
    }
  };

  return (
    <div className="specified-dialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>
              {dialogueData
                ? "Specific Notification Update"
                : "Send Specified Notification"}
            </h4>
            <IconButton onClick={() => dispatch(closeDialog())}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12 select-moreApp">
                  <label className="input-label">Select App</label>
                  <Select
                    isMulti
                    options={getAppData || []}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                    getOptionValue={(option) => option?._id}
                    formatOptionLabel={(option) => (
                      <div className="moreApp-option">
                        <img src={baseURL + "/" + option?.appLogo} />
                        <span>{option?.appName}</span>
                      </div>
                    )}
                  />
                  {error.selectedOptions && (
                    <p className="errorMessage">{error.selectedOptions}</p>
                  )}
                </div>
                <div className="col-12 select-moreApp">
                  <Input
                    label={`Time`}
                    id={`number`}
                    type={`number`}
                    name={"specificNotiTime"}
                    value={specificNotiTime}
                    errorMessage={error.specificNotiTime}
                    onChange={(e) => {
                      setSpecificNotiTime(
                        (e.target.value = Math.max(
                          0,
                          parseFloat(e.target.value)
                        ).toString())
                      );
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          specificNotiTime: `Time Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          specificNotiTime: "",
                        });
                      }
                    }}
                  />
                  <p className="errorMessage">
                    The message will start sending every hour (i.e., 1,2,3....)
                  </p>
                </div>
              </div>
            </div>
            <div className="row dialog-footer mt-3">
              <div className="col-12 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={() => dispatch(closeDialog())}
                  style={{ marginRight: "10px" }}
                  newClass="cancel-btn"
                />
                <Button
                  btnName={"Send"}
                  newClass={"submit-btn send-btn-dialogue"}
                  type={"button"}
                  btnIcon={<SendIcon />}
                  onClick={handleSubmitpecificTime}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  createSpecifiedNotification,
  getApp,
  sendSpecifiedNotification,
  editNotification,
})(SpecifiedNotifiDialogue);
