import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from "../extra/Button";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { getPost, deletePost } from "../../store/slice/postSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import { baseURL } from "../utils/config";
import dayjs from "dayjs";
import { openDialog } from "../../store/slice/dialogueSlice";
import { warning } from "../utils/Alert";
import Pagination from "../extra/Pagination";
import PostDialog from "../dialogComponent/PostDialog";

const PostTable = (props) => {
  const { postData, totalPost } = useSelector((state) => state.post);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(postData);
  }, [postData]);

  useEffect(() => {
    const payload = {
      start: start,
      limit: limit,
    };
    props.getPost(payload);
  }, [start, limit]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleEditApp = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };
    dispatch(openDialog(payload));
  };

  const handleDelteApp = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            const payload = {
              name: row?.name,
              id: row?._id,
            };
            props.deletePost(payload);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Male Image",
      body: "mImage",
      Cell: ({ row }) => (
        <div>
          {row?.mImage[0] && (
            <img src={row?.mImage[0]} id="imageShow" className="imageShow" />
          )}
        </div>
      ),
    },
    {
      Header: "Female Image",
      body: "femaleImage",
      Cell: ({ row }) => (
        <div>
          {row?.fImage[0] && (
            <img src={row?.fImage[0]} id="imageShow" className="imageShow" />
          )}
        </div>
      ),
    },
    {
      Header: "Male Name",
      body: "mName",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.mName[0]}</span>,
    },
    {
      Header: "Female Name",
      body: "fName",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.fName[0]}</span>,
    },
    {
      Header: "Male Username",
      body: "mUsername",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.mUsername[0]}</span>
      ),
    },
    {
      Header: "Female Username",
      body: "fUsername",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.fUsername[0]}</span>
      ),
    },
    {
      Header: "Male Bio",
      body: "mBio",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.mBio[0]}</span>,
    },
    {
      Header: "Female  Bio",
      body: "fBio",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.fBio[0]}</span>,
    },
    {
      Header: "Male Comment",
      body: "mComment",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.mComment[0]}</span>
      ),
    },
    {
      Header: "Female Comment",
      body: "fComment",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.fComment[0]}</span>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditApp(row, "updatePost")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDelteApp(row)}
          newClass={"delete-btn"}
        />
      ),
    },
    {
      Header: "Create At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMM YYYY") : ""}
        </span>
      ),
    },
  ];

  return (
    <div className="postPage">
      <Title title={"Post"} />
      {(dialogueType === "newPost" || dialogueType === "updatePost") && (
        <PostDialog />
      )}
      <div className="post-table">
        <h6 style={{ marginTop: "10px" }}>Total Post : {totalPost}</h6>
        <div className="row mt-3 pt-1">
          <div className="col-8">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={postData}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4 new-button d-flex justify-content-end">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newPost")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={totalPost}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default connect(null, { getPost, deletePost })(PostTable);
