import { useState } from "react";

const Textarea = (props) => {
    const { id, label, row,note, placeholder, name, errorMessage,onChange,value } = props;
    const [error, setError] = useState("");
    // const checkForm = (e) => {
    //   if (e.target.value == "") {
    //     setError("opacity-1");
    //   } else {
    //     setError("opacity-0");
    //   }
    // };
    return (
      <div className="inputData text-start">
        <label htmlFor={id}>{label}</label>
        <textarea
          id={id}
          rows={row}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
        ></textarea>
       {
        errorMessage&&(
        <p
          className={`errorMessage-text  ${error}`}
          id={`error-${name}`}
        >
          {errorMessage}
        </p>
        )
       }
       {note && <p className="text-danger">{note}</p>}
      </div>
    );
  };
  export default Textarea;