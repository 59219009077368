import React, { useEffect, useState } from "react";
import prettier from "prettier";
import parserBabel from "prettier/parser-babel";
import ReactJson from "react-json-view";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import Split from "@uiw/react-split";
import { json as jsonLang } from "@codemirror/lang-json";
import Button from "../../extra/Button";

const borderRadius = EditorView.theme({
  ".cm-scroller, &": {
    borderRadius: "0px",
  },
  "&": {
    border: "1px solid transparent",
  },
});

export default function JsonFormatter() {
  const [inputVal, setInputVal] = useState();
  const [outputVal, setOutputVal] = useState("");
  const [error, setError] = useState("");

  const handleFormat = () => {
    try {
      setError("");
      setInputVal(
        prettier.format(inputVal, {
          parser: "json",
          tabWidth: 2,
          printWidth: 30,
          plugins: [parserBabel],
        })
      );
      if (inputVal) {
        const obj = JSON.parse(inputVal);
        setOutputVal(obj);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  const handleMinify = () => {
    try {
      setError("");
      setInputVal(JSON.stringify(JSON.parse(inputVal)));
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  return (
    <div className="json-formeter">
      <div className="header">
        <div className="button-box">
          <Button
            newClass={"move-btn"}
            btnName={" Format & View"}
            onClick={handleFormat}
          />
          <Button
            newClass={"remove-space"}
            btnName={"Remove white spaces"}
            onClick={handleMinify}
          />
        </div>
        {error && <div className="error">{error}</div>}
      </div>
      <div className="code-editor">
        <Split style={{ width: "100%", height: "100%", minHeight: "450px" }}>
          <div className="input-area">
            <CodeMirror
              extensions={[jsonLang(), borderRadius]}
              height="100%"
              style={{ height: "100%", border: "none" }}
              placeholder="Enter your JSON here..."
              value={inputVal}
              onChange={(val) => setInputVal(val)}
            />
          </div>
          <div className="output-area">
            {error && (
              <pre style={{ padding: 0, margin: 0, color: "red" }}>{error}</pre>
            )}
            {outputVal && typeof outputVal === "object" && (
              <ReactJson
                src={outputVal}
                theme="rjv-default"
                displayDataTypes={false}
                collapsed={true}
                enableClipboard={false}
              />
            )}
          </div>
        </Split>
      </div>
    </div>
  );
}
