import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import AllApp from "../Table/AllApp";
import Navbar from "../navbar/Navbar";
import Game from "../Table/Game";
import MovedApp from "../Table/MovedApp";
import MoreApp from "../Table/MoreApp";
import EditApp from "./EditApp";
import CustomAd from "../Table/CustomAd";
import Developer from "../Table/Developer";
import Profile from "../Page/Profile";
import ShortVideo from "../Table/ShortVideo";
import LiveStreamVideo from "../Table/LiveStreamVideo";
import VideoCallVideo from "../Table/VideoCallVideo";
import PrivateVideo from "../Table/PrivateVideo";
import TestId from "../Table/TestId";
import PostTable from "../Table/PostTable";
import NotificationPage from "./NotificationTable/NotificationPage";
import NotificationPageNotificationPage from "./NotificationPage";
import Error404 from "../extra/Error404";
import HostTable from "../Table/HostTable";
import GiftMain from "./GiftPage/GiftMain";
import AppForDev from "../Table/AppForDev";
import DevNav from "../navbar/DevNav";

export default function Dev() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (
      location.pathname == "/dev" ||
      location.pathname == "/dev/" ||
      location.pathname == "dev"
    ) {
      
      // window.location.reload(true);
      navigate("/dev/app");
    }
  }, []);

  return (
    <div className="pagesContent">
      <DevNav />
      <div className="mainPage">
        <div className="mainPage-bg"></div>
        <Routes>
          <Route path="/app" element={<AppForDev />} />
          <Route path="/updateApp" element={<EditApp />} />
          <Route path="/notification" element={<NotificationPage />} />
        </Routes>
      </div>
    </div>
  );
}
