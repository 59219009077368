import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  customAd: [],
  total: null,
  isSkeleton: false,
  isLoading: false,
};

export const getAllCustomAd = createAsyncThunk(
  "admin/customAd/getAllCustomAd",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/customAd/getAllCustomAd?start=${payload.start}&limit=${payload.limit}`
    );
  }
);

export const addCustomAd = createAsyncThunk(
  "admin/customAd/createCustomAd",
  async (payload) => {
    return apiInstance.post("admin/customAd/createCustomAd", payload);
  }
);

export const updateCustomAd = createAsyncThunk(
  "admin/customAd/updateCustomAd",
  async (payload) => {
    return apiInstance.patch(
      `admin/customAd/updateCustomAd?customAdId=${payload?.id}`,
      payload?.data
    );
  }
);

export const customAdDelete = createAsyncThunk(
  "admin/customAd/delete",
  async (id) => {
    return apiInstanceFetch.delete(`admin/customAd/delete?customAdId=${id}`);
  }
);

export const customAdDeleteImage = createAsyncThunk(
  "admin/customAd/deleteImages",
  async (payload) => {
    return apiInstance.patch(
      `admin/customAd/deleteImages?customAdId=${payload?.id}&type=${payload?.type}&position=${payload?.position}`,
      payload?.data
    );
  }
);

export const isShowCustomAd = createAsyncThunk(
  "admin/customAd/isShow",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/customAd/isShow?customAdId=${payload?.id}`
    );
  }
);
const customAdSlice = createSlice({
  name: "customAdSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCustomAd.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllCustomAd.fulfilled, (state, action) => {
      state.customAd = action?.payload?.customAd;
      state.isSkeleton = false;
      state.total = action?.payload?.totalCustomAd;
    });

    builder.addCase(getAllCustomAd.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addCustomAd.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addCustomAd.fulfilled, (state, action) => {
      // state.customAd = action?.payload?.customAd
      state.isLoading = false;
      state.customAd.unshift(action?.payload?.customAd);
      setToast("success", "Ad Added Successfully");
    });

    builder.addCase(addCustomAd.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCustomAd.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCustomAd.fulfilled, (state, action) => {
      const updatedAd = action?.payload?.customAd;
      const adIndex = state?.customAd?.findIndex(
        (ad) => ad?._id === updatedAd?._id
      );
      if (adIndex !== -1) {
        state.customAd[adIndex] = {
          ...state.customAd[adIndex],
          ...action?.payload?.customAd,
        };
      }
      state.isLoading = false;
      setToast("success", "Ad Updated Successfully");
    });

    builder.addCase(updateCustomAd.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(customAdDelete.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(customAdDelete.fulfilled, (state, action) => {
      state.customAd = state.customAd.filter(
        (customAd) => customAd._id !== action.meta.arg
      );
      state.isLoading = false;
      setToast("success", "Ad Deleted Successfully");
    });
    builder.addCase(customAdDelete.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(customAdDeleteImage.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(customAdDeleteImage.fulfilled, (state, action) => {
      // state.customAd = state.customAd
      state.isLoading = false;
      setToast("success", "Image Deleted Successfully");
    });

    builder.addCase(customAdDeleteImage.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isShowCustomAd.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(isShowCustomAd.fulfilled, (state, action) => {
      state.customAd = state.customAd?.map((customAd) => {
        if (customAd?._id === action.meta.arg?.id) {
          return action.payload?.customAd;
        }
        return customAd;
      });
      setToast(
        "success",
        `${action.meta.arg?.value === true ? "Disable" : "Enabled"}` +
          " " +
          "Is Show"
      );
      state.isLoading = false;
    });
    builder.addCase(isShowCustomAd.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default customAdSlice.reducer;
