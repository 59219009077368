import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import TextArea from "../extra/TextArea";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { createPrivateVideo } from "../../store/slice/privateVideoSlice";

const PrivateVideoDialogue = () => {
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [showVideo, setShowVideo] = useState([]);

  const handleSubmit = () => {
    if (!link) {
      setError("Link is Required");
    } else {
      const payload = { link };
      dispatch(createPrivateVideo(payload));
      dispatch(closeDialog());
    }
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  
  useEffect(() => {
    const videoShow = link && link?.split(",");
    setShowVideo(videoShow);
  }, [link]);

  return (
    <div className="ShortVideoDialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>Create Private Video</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row mt-3">
              <div className="col-12">
                <TextArea
                  label={`Link`}
                  placeholder={"Enter Link"}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                  row={1}
                  value={link}
                  errorMessage={error && error}
                  note={`Note : Enter coma (,) separated string.`}
                />
                <p className="text-danger">
                  Note : Youtube links are not Acceptable{" "}
                </p>
              </div>
              <div className="col-12">
                <div className="video-showBox">
                {showVideo?.length > 0 &&
                  showVideo?.map((item) => {
                    return <video controls src={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row dialog-footer">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={handleClose}
                style={{ marginRight: "10px" }}
                newClass={"cancel-btn"}
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass={"submit-btn"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateVideoDialogue;
