import React, { useEffect, useState } from "react";
import {
  getMovedApp,
  restoreApp,
  deleteMoveApp,
} from "../../store/slice/movedAppSlice";
import { connect, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import { baseURL } from "../utils/config";
import Title from "../extra/Title";
import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Searching from "../extra/Searching";
import dayjs from "dayjs";
import Button from "../extra/Button";
import { warning } from "../utils/Alert";
import { useNavigate } from "react-router-dom";

const MovedApp = (props) => {
  const { totalMovedApp, movedApp } = useSelector((state) => state.movedApp);
  const navigate = useNavigate();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Logo",
      body: "logo",
      Cell: ({ row }) => (
        <div className="image-showTable">
          {row?.appLogo && <img src={baseURL + "/" + row?.appLogo} />}
        </div>
      ),
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appName}</span>,
    },
    {
      Header: "App Key",
      body: "appKey",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appKey}</span>,
    },
    {
      Header: "Account Name",
      body: "accountName",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.accountName ? row?.accountName : "-"}
        </span>
      ),
    },
    {
      Header: "Restore App",
      body: "restoreApp",
      Cell: ({ row }) => (
        <Button
          btnName={"Restore"}
          btnIcon={<RestoreIcon />}
          onClick={() => handleRestoreApp(row)}
          newClass={"move-btn"}
        />
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditApp(row)}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          newClass={"delete-btn"}
          onClick={() => handleDeleteApp(row)}
        />
      ),
    },
    {
      Header: "Created At	",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.createdAt).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      Header: "Moved At",
      body: "movedAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {dayjs(row?.updatedAt).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  const handleRestoreApp = (row) => {
    props.restoreApp(row?._id);
  };

  const handleEditApp = (data, type) => {
    navigate("/admin/updateApp", { state: { editAppData: data } });
  };

  const handleDeleteApp = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            props.deleteMoveApp(row?._id);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let payload = {
      start: start,
      limit: limit,
    };
    props.getMovedApp(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(movedApp);
  }, [movedApp]);

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <div className="moved-app">
      <Title title={"All Moved Apps"} />
      <div className="movedApp-table">
        <div className="row">
          <div className="col-12 col-sm-5 d-flex align-items-center">
            <h6>Total Moved App : {totalMovedApp}</h6>
          </div>
          <div className="col-12 col-sm-7 mt-3 mt-sm-0">
            <div className="search-input">
              <Searching
                placeHolder={"What’re you searching for?"}
                type="client"
                data={movedApp}
                setData={setData}
                buttonShow={false}
                onFilterData={handleFilterData}
                searchValue={search}
              />
            </div>
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={totalMovedApp}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default connect(null, {
  getMovedApp,
  restoreApp,
  deleteMoveApp,
})(MovedApp);
