import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  getAppData: [],
  notificationData: {},
  getSpecifiedNotiData: [],
  isSkeleton:false
};

export const getApp = createAsyncThunk(
  "admin/app/getAppsForNotification",
  async (payload) => {
    return apiInstanceFetch.get(`admin/app/getAppsForNotification`);
  }
);

export const getSpecifiedNotification = createAsyncThunk(
  "admin/notification/getSpecifiedNotification",
  async (payload) => {
    return apiInstanceFetch.get(`admin/notification/getSpecifiedNotification`);
  }
);

export const getNotificationData = createAsyncThunk(
  "admin/specifiedNotificationData/getNotificationData",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/specifiedNotificationData/getNotificationData`
    );
  }
);

export const createSpecifiedNotification = createAsyncThunk(
  "admin/specifiedNotificationData/storeNotificationData",
  async (payload) => {
    return apiInstance.post(
      `admin/specifiedNotificationData/storeNotificationData`,
      payload?.data
    );
  }
);

export const sendNotification = createAsyncThunk(
  "admin/app/notificationToApp",
  async (payload) => {
    return apiInstance.post(
      `admin/app/notificationToApp?appId=${payload?.appId}`,
      payload?.data
    );
  }
);

export const sendImmediatelyNoti = createAsyncThunk(
  "admin/notification/notificationToParticularApp?",
  async (payload) => {
    return apiInstance.post(
      `admin/notification/notificationToParticularApp?appId=${payload.appId}`,
      payload?.data
    );
  }
);


export const sendSpecifiedNotification = createAsyncThunk(
  "admin/notification/specifiedNotificationToParticularApp",
  async (payload) => {
    return apiInstance.post(
      `admin/notification/specifiedNotificationToParticularApp?appId=${payload.appId}&specificTime=${payload.time}`
    );
  }
);

export const editNotification = createAsyncThunk(
  "admin/specifiedNotificationData/editNotificationData",
  async (payload) => {
    return apiInstance.patch(
      `admin/specifiedNotificationData/editNotificationData?notificationId=${payload?.id}`,
      payload?.data
    );
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAppData = action.payload.app;
    });
    builder.addCase(getApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createSpecifiedNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createSpecifiedNotification.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.notificationData?.unshift(action?.payload?.notification);
        setToast(
          "success",
          "Specified Notification Data Success"
        );
      }
    });
    builder.addCase(createSpecifiedNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(sendNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(sendNotification.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "Notification Send Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(sendNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getNotificationData.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getNotificationData.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.notificationData = action.payload.notification;
    });
    builder.addCase(getNotificationData.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(getSpecifiedNotification.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSpecifiedNotification.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.getSpecifiedNotiData = action.payload.notification;
    });
    builder.addCase(getSpecifiedNotification.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(sendSpecifiedNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(sendSpecifiedNotification.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.getSpecifiedNotiData?.unshift(action.payload.notification);
        setToast("success", " Specified Notification Create Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(sendSpecifiedNotification.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(sendImmediatelyNoti.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(sendImmediatelyNoti.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", " Notification send to the particular app's users Successfully");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(sendImmediatelyNoti.rejected, (state, action) => {
      state.isLoading = false;
    });


    builder.addCase(editNotification.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editNotification.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.notificationData =  state.notificationData?.map((item) => {
          if (item?._id === action.meta.arg.id) {
            return action.payload?.notification;
          }
          return item;
        });
        setToast(
          "success",
            "Specified Notification Update Successfully"
        );
      }
    });
    builder.addCase(editNotification.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default notificationSlice.reducer;
