import React from "react";
import { useSelector } from "react-redux";
import { isLoading, isSkeleton } from "./allSelector";
import { useLocation } from "react-router-dom";

const Loader = () => {
  const roleLoader = useSelector(isLoading);
  const isSkeletonLoader = useSelector(isSkeleton);
 const location=useLocation()
  return (
    <>
    {
    isSkeletonLoader ?
      "":roleLoader && (
        <div class="main-loader">
        <div class="loader"></div>
        </div>
      )
    }
    </>
  );
};

export default Loader;
