import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isLoading, isSkeleton } from "../utils/allSelector";

const Row = () => (
  <div className="row">
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
    <div className="skeleton-table"></div>
  </div>
);

export default function Table(props) {
  let { data, start, limit, type, mapData } = props;
  const isSkeletonLoader = useSelector(isSkeleton);
  const [skeleton, setSkeleton] = useState();
  const numberOfRows = 10;

  useEffect(() => {
    const renderedRows = [...Array(numberOfRows)]?.map((e, i) => (
      <div>
        <Row />
      </div>
    ));
    const data = <Row />;
    setSkeleton(data);
  }, []);

  return (
    <div
      className="custom-table"
      style={{ maxHeight: `${data?.length <= 10 ? "100%" : "776px"}` }}
    >
      <table width="100%" border className="primeTable text-center">
        <thead className="">
          <tr>
            {mapData?.map((res, index) => {
              return (
                <th className={`text-nowrap`}>
                  <div className="table-head">{res?.Header}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        {type == "server" && (
          <>
            {isSkeletonLoader === true ? (
              <tbody>
                <tr>
                  {mapData?.map((res) => {
                    return (
                      <>
                        <td>
                          <Row />
                        </td>
                      </>
                    );
                  })}
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data?.length > 0 ? (
                  <>
                    {data
                      // limit > 0
                      // ? [data].slice(start * limit, start * limit + limit)
                      // : data
                      .map((i, k) => {
                        return (
                          <>
                            <tr>
                              {mapData.map((res,index) => {
                                return (
                                  <>
                                    {
                                      <td>
                                        {res.Cell ? (
                                          <res.Cell row={i} index={k} />
                                        ) : (
                                          <span className={res.class}>
                                            {i[res.body]}
                                          </span>
                                        )}
                                      </td>
                                    }
                                  </>
                                );
                              })}
                            </tr>
                          </>
                        );
                      })}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="25"
                      className="text-center not-dataFound"
                      style={{ height: "358px", borderBottom: "none" }}
                    >
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </>
        )}
      </table>
    </div>
  );
}
