import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import Button from "../extra/Button";
import TextArea from "../extra/TextArea";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { createMoreApp, updateMoreApp } from "../../store/slice/moreAppSlice";
import Input from "../extra/Input";
import { baseURL } from "../utils/config";

const MoreAppDialogue = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState([]);
  const [logoPath, setLogoPath] = useState("");

  const [error, setError] = useState({
    link: "",
    title: "",
    description: "",
    logoPath: "",
  });

  useEffect(() => {
    if (dialogueData) {
      setLink(dialogueData?.link);
      setTitle(dialogueData?.appName);
      setDescription(dialogueData?.description);
      setLogoPath(baseURL+"/"+ dialogueData?.appLogo);
      setLogo(baseURL+"/"+ dialogueData?.appLogo);
    }
  }, [dialogueData]);

  const handleSubmit = () => {
    var formData = new FormData();
    formData.append("link", link);
    formData.append("appName", title);
    formData.append("description", description);
    formData.append("appLogo", logo);
    if (dialogueData) {
      const payload = { id: dialogueData?._id, data: formData };
      dispatch(updateMoreApp(payload));
      dispatch(closeDialog());
    } else {
      if (!link || !title || !description || !logoPath) {
        let error = {};
        if (!link) error.link = "Link is Require !";
        if (!title) error.title = "App Name is Require !";
        if (!description) error.description = "Description is Require !";
        if (!logoPath) error.logoPath = "App Logo is Require !";
        return setError({ ...error });
      } else {
        dispatch(createMoreApp(formData));
        dispatch(closeDialog());
      }
    }
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleFileUpload = (e) => {
    setLogo(e.target.files[0]);
    const showLogo = URL.createObjectURL(e.target.files[0]);
    setLogoPath(showLogo);

    if (!e.target.files[0]) {
      return setError({
        ...error,
        logoPath: `Logo is Require !`,
      });
    } else {
      return setError({
        ...error,
        logoPath: "",
      });
    }
  };

  return (
    <div className="moreAppDialogue">
      <div class="dialog">
        <div class="mainDiaogBox">
          <div className="dialog-head d-flex justify-content-between">
            <h4>{dialogueData ? "Update More App" : "New More App"} </h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <div className="dialog-body">
            <div className="row mt-3">
              <div className="col-12">
                <Input
                  label={`Link`}
                  type={`text`}
                  errorMessage={error.link && error.link}
                  onChange={(e) => {
                    setLink(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        link: `Link is Require !`,
                      });
                    } else {
                      return setError({
                        ...error,
                        link: "",
                      });
                    }
                  }}
                  value={link}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`App Name`}
                  id={`title`}
                  type={`text`}
                  value={title}
                  errorMessage={error?.title && error?.title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        title: `App Name is Require !`,
                      });
                    } else {
                      return setError({
                        ...error,
                        title: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <Input
                  label={`Description`}
                  id={`description`}
                  type={`description`}
                  value={description}
                  errorMessage={error?.description && error?.description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        description: `Description is Require !`,
                      });
                    } else {
                      return setError({
                        ...error,
                        description: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-6 image-input">
                <label className="label-input" style={{ marginBottom: "8px" }}>
                  App Logo
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/gif, image/jpeg, image/png"
                  className="form-input"
                  onChange={handleFileUpload}
                />
                {error?.logoPath && (
                  <p className="errorMessage">{error?.logoPath}</p>
                )}
                <div className="mt-2">{logoPath && <img src={logoPath} />}</div>
              </div>
            </div>
          </div>
          <div className="row dialog-footer">
            <div className="col-6"></div>
            <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
              <Button
                btnName={"Cancel"}
                onClick={handleClose}
                style={{ marginRight: "10px" }}
                newClass="cancel-btn"
              />
              <Button
                btnName={"Submit"}
                type={"button"}
                onClick={handleSubmit}
                newClass="submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreAppDialogue;
