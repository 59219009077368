import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import Searching from "../extra/Searching";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAllApp,
  editAccountName,
  editDomainName,
  isPublishedSwitch,
  deleteApp,
  movedApp,
  cloneApp,
  isAssignSwitch,
  getAllAppForDev,
} from "../../store/slice/appSlice";
import Table from "../extra/Table";
import { baseURL } from "../utils/config";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import $ from "jquery";
import Button from "../extra/Button";
import noImageFound from "../../assets/images/noImageFound.svg";
import { Tooltip } from "@mui/material";
import Pagination from "../extra/Pagination";
import EdiText from "react-editext";
import { openDialog } from "../../store/slice/dialogueSlice";
import NewAppCreate from "../dialogComponent/NewAppCreate";
import { useNavigate } from "react-router-dom";
import { successFullAlertShow, warning } from "../utils/Alert";

const AppForDev = (props) => {
  const { allApp, totalApp } = useSelector((state) => state.app);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const { admin } = useSelector((state) => state.admin);

  const [dev, setDev] = useState({});
  useEffect(() => {
    setDev(admin);
  }, [admin]);
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState();
  const [copyCodeOpen, setCopyCodeOpen] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  
  const mapData = [
    {
      Header: "Index",
      body: "index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Logo",
      body: "logo",
      Cell: ({ row }) => (
        <div className="logo-image">
          {row?.appLogo && (
            <img src={baseURL + "/" + row?.appLogo} alt="logo" />
          )}
        </div>
      ),
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span className="  text-nowrap">{row?.appName}</span>,
    },
    {
      Header: "App Key",
      body: "appKey",
      Cell: ({ row, index }) => (
        <Tooltip
          title={copyCodeOpen ? "Copied!" : "Copy"}
          arrow
          placement="top"
        >
          <span
            style={{ cursor: "pointer" }}
            id={`myInput${index}`}
            onClick={() => copyCode(index)}
          >
            {row?.appKey}
          </span>
        </Tooltip>
      ),
    },
    {
      Header: "Base URL Name",
      body: "domainName",
      Cell: ({ row, index }) => (
        <div className="edit-text-input">
          <EdiText
            type="text"
            value={row?.domainName === "null" ? "" : row?.domainName}
            onSave={(val) => handleEditBaseUrl(val, row?._id)}
          />
        </div>
      ),
    },

    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"edit-btn"}
          onClick={() => handleEditApp(row, "updateApp")}
        />
      ),
    },

    {
      Header: "Clone",
      body: "clone",
      Cell: ({ row }) => (
        <Button
          btnIcon={<AddRoundedIcon />}
          newClass={"clone-btn"}
          onClick={() => handleCloneApp(row)}
        />
      ),
    },
  ];

  const handleCloneApp = (row) => {
    props.cloneApp(row?._id);
  };
  
  useEffect(() => {
    const developer = JSON.parse(sessionStorage.getItem("token"));
    if( developer){

      const payload = {
        start: start,
        limit: limit,
        id: developer._id,
      };
      dispatch(getAllAppForDev(payload));
    }
  }, [start, limit,dispatch]);

  useEffect(() => {
    setData(allApp);
  }, [allApp]);

  const handleEditBaseUrl = (val, id) => {
    const payload = {
      appId: id,
      payloadData: { domainName: val },
    };
    dispatch(editDomainName(payload));
  };

  $(document).ready(function () {
    $("#imgShow").bind("error", function () {
      // Set the default image
      $(this).attr("src", noImageFound);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleEditApp = (data, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: data,
    };

    dispatch(openDialog(payload));
    localStorage.removeItem("appEdit");
    navigate("/dev/updateApp", { state: { editAppData: data } });
  };

  const copyCode = (index) => {
    setCopyCodeOpen(true);
    var copyText = document.getElementById(`myInput${index}`);
    var inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = copyText.textContent;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();
    setTimeout(() => {
      setCopyCodeOpen(false);
    }, 1000);
  };

  return (
    <div className="allApp-page">
      {dialogueType === "newApp" && <NewAppCreate />}
      <Title title={"All Apps"} />
      <div className="allApp-table">
        <h6 style={{ marginTop: "10px" }}>Total App : {totalApp}</h6>
        <div className="row mt-3 pt-1 search-btnBox">
          <div className="col-8 ">
            <Searching
              placeHolder={"What’re you searching for?"}
              type="client"
              buttonShow={true}
              data={allApp}
              setData={setData}
              onFilterData={handleFilterData}
              searchValue={search}
            />
          </div>
          <div className="col-4  new-button d-flex justify-content-end align-items-center">
            <Button
              btnName={"New"}
              btnIcon={<AddRoundedIcon />}
              type={"button"}
              onClick={() => handleNewOpenDialog("newApp")}
              newClass={"new-btn"}
            />
          </div>
        </div>
        <Table
          data={data}
          type={"server"}
          mapData={mapData}
          start={start}
          limit={limit}
        />
        <Pagination
          type={"server"}
          start={start}
          limit={limit}
          totalData={totalApp}
          setStart={setStart}
          setLimit={setLimit}
          handleRowsPerPage={handleRowsPerPage}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  getAllApp,
  editAccountName,
  isPublishedSwitch,
  deleteApp,
  movedApp,
  cloneApp,
  isAssignSwitch,
})(AppForDev);
