import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ReactSelect from "react-select";

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Input from "../../extra/Input";
import Textarea from "../../extra/TextArea";
import AdRevenueBox from "../../extra/AdRevenueBox";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import ToggleSwitch from "../../extra/ToggleSwitch";
import {
  getMonetization,
  getCustomeAd,
  customAdSubmit,
  removeCustomAd,
  appMonetizationSwitch,
  monetizationUpdate,
} from "../../../store/slice/appSlice";
import { useLocation } from "react-router-dom";
import Button from "../../extra/Button";
import { baseURL } from "../../utils/config";

const adLevelSelectData = [
  { id: 1, title: "low" },
  { id: 2, title: "medium" },
  { id: 3, title: "high" },
  { id: 4, title: "highest" },
];
const Monetization = (props) => {
  const location = useLocation();
  const { monetizationData, customAd } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [adLevelSelect, setAdLevelSelect] = useState();
  const [lowAddDescription, setLowAddDescription] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [mediumAddDescription, setMediumAddDescription] = useState("");
  const [highAddDescription, setHighAddDescription] = useState("");
  const [highestAddDescription, setHighestLowAddDescription] = useState("");

  console.log("monetizationData", monetizationData)

  const [isGoogle1, setIsGoogle1] = useState(false);
  const [googleAd1, setGoogleAd1] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [isGoogle2, setIsGoogle2] = useState(false);
  const [googleAd2, setGoogleAd2] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [isFacebook1, setIsFacebook1] = useState(false);
  const [facebook1, setFaceBook1] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [isFacebook2, setIsFacebook2] = useState(false);
  const [facebook2, setFaceBook2] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [isOther1, setIsOther1] = useState(false);
  const [other1, setOther1] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [isOther2, setIsOther2] = useState(false);
  const [other2, setOther2] = useState({
    publishID: "",
    appID: "",
    banner: "",
    native: "",
    interstitial: "",
    appOpen: "",
    reward: "",
  });

  const [interstitialAd, setInterstitialAd] = useState("");
  const [preLoadAd, setPreLoadAd] = useState(false);
  const [adColorSame, setAdColorSame] = useState(false);
  const [splashAd, setSplashAd] = useState(false);
  const [getCustomeAddData, setGetCustomeAddData] = useState([]);
  const [isCustomAd, setIsCustomAd] = useState(false);
  const [isCustomLinkDirectOpen, setIsCustomLinkDirectOpen] = useState(false);
  const [switchDataType, setSwitchDataType] = useState(true);

  const handleChangeAdLevelSelect = (e) => {
    setAdLevelSelect(e.target.value);
  };

  const handleSelectChange = (selected, type) => {
    setSelectedOptions(selected);
    if (type === "addAds") {
      const selectedIds = selected?.map((option) => option?.uniqueId);
      const updatedData = selectedIds?.join(",");
      let payload = {
        customAdUniqueId: updatedData,
        appId: location?.state?.editAppData?._id,
      };
      props.customAdSubmit(payload);
    }
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="moreApp-optionList">
      <img src={data?.icon[0] && baseURL + "/" + data?.icon[0]} />
      <span>{data?.title[0] && data?.title[0]}</span>
    </div>
  );

  const CustomMultiValue = ({ children, ...props }) => (
    <div className="custom-multi-value">
      {children}
      <span
        className="custom-multi-value-remove"
        // onClick={() => props.removeProps.onClick()}
        onClick={() => handleRemoveApp(props.data)}
      >
        ✕
      </span>
    </div>
  );

  const handleRemoveApp = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option._id !== removedOption._id
    );
    const payload = {
      appId: location.state?.editAppData?._id,
      customAdId: removedOption?._id,
    };
    props.removeCustomAd(payload);
    handleSelectChange(updatedOptions, "removeAds");
  };

  useEffect(() => {
    setGetCustomeAddData(customAd);
  }, [customAd]);

  useEffect(() => {
    setLowAddDescription(monetizationData?.lowDescription);
    setMediumAddDescription(monetizationData?.mediumDescription);
    setHighAddDescription(monetizationData?.highDescription);
    setHighestLowAddDescription(monetizationData?.highestDescription);
    setAdLevelSelect(monetizationData?.adLevel);
    setGoogleAd1({
      publishID: monetizationData?.google1?.publisherIdGoogle1,
      appID: monetizationData?.google1?.appIdGoogle1,
      banner: monetizationData?.google1?.bannerGoogle1,
      native: monetizationData?.google1?.nativeGoogle1,
      interstitial: monetizationData?.google1?.interstitialGoogle1,
      appOpen: monetizationData?.google1?.appOpenGoogle1,
      reward: monetizationData?.google1?.rewardGoogle1,
    });
    setIsGoogle1(monetizationData?.google1?.isGoogle1);

    setGoogleAd2({
      publishID: monetizationData?.google2?.publisherIdGoogle2,
      appID: monetizationData?.google2?.appIdGoogle2,
      banner: monetizationData?.google2?.bannerGoogle2,
      native: monetizationData?.google2?.nativeGoogle2,
      interstitial: monetizationData?.google2?.interstitialGoogle2,
      appOpen: monetizationData?.google2?.appOpenGoogle2,
      reward: monetizationData?.google2?.rewardGoogle2,
    });
    setIsGoogle2(monetizationData?.google2?.isGoogle2);

    setFaceBook1({
      publishID: monetizationData?.facebook1?.publisherIdFacebook1,
      appID: monetizationData?.facebook1?.appIdFacebook1,
      banner: monetizationData?.facebook1?.bannerFacebook1,
      native: monetizationData?.facebook1?.nativeFacebook1,
      interstitial: monetizationData?.facebook1?.interstitialFacebook1,
      appOpen: monetizationData?.facebook1?.appOpenFacebook1,
      reward: monetizationData?.facebook1?.rewardFacebook1,
    });
    setIsFacebook1(monetizationData?.facebook1?.isFacebook1);

    setFaceBook2({
      publishID: monetizationData?.facebook2?.publisherIdFacebook2,
      appID: monetizationData?.facebook2?.appIdFacebook2,
      banner: monetizationData?.facebook2?.bannerFacebook2,
      native: monetizationData?.facebook2?.nativeFacebook2,
      interstitial: monetizationData?.facebook2?.interstitialFacebook2,
      appOpen: monetizationData?.facebook2?.appOpenFacebook2,
      reward: monetizationData?.facebook2?.rewardFacebook2,
    });
    setIsFacebook2(monetizationData?.facebook2?.isFacebook2);

    setOther1({
      publishID: monetizationData?.other1?.publisherIdOther1,
      appID: monetizationData?.other1?.appIdOther1,
      banner: monetizationData?.other1?.bannerOther1,
      native: monetizationData?.other1?.nativeOther1,
      interstitial: monetizationData?.other1?.interstitialOther1,
      appOpen: monetizationData?.other1?.appOpenOther1,
      reward: monetizationData?.other1?.rewardOther1,
    });
    setIsOther1(monetizationData?.other1?.isOther1);

    setOther2({
      publishID: monetizationData?.other2?.publisherIdOther2,
      appID: monetizationData?.other2?.appIdOther2,
      banner: monetizationData?.other2?.bannerOther2,
      native: monetizationData?.other2?.nativeOther2,
      interstitial: monetizationData?.other2?.interstitialOther2,
      appOpen: monetizationData?.other2?.appOpenOther2,
      reward: monetizationData?.other2?.rewardOther2,
    });
    setIsOther2(monetizationData?.other2?.isOther2);

    setInterstitialAd(monetizationData?.interstitialAdClick);
    setPreLoadAd(monetizationData?.preLoadAd);
    setAdColorSame(monetizationData?.adColorSame);
    setSplashAd(monetizationData?.splashAd);
    setIsCustomAd(monetizationData?.isCustomAd);
    setSelectedOptions(monetizationData?.customAd);
    setIsCustomLinkDirectOpen(monetizationData?.isCustomLinkDirectOpen);
  }, [monetizationData]);

  useEffect(() => {
    const payload = location?.state?.editAppData?._id;
    props.getMonetization(payload);
  }, [location.state]);

  useEffect(() => {
    props.getCustomeAd();
  }, []);

  const handleSwitch = (id, type, value) => {
    const payload = {
      appId: id,
      type: type,
      value: value,
    };
    props.appMonetizationSwitch(payload);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      lowDescription: lowAddDescription,
      mediumDescription: mediumAddDescription,
      highDescription: highAddDescription,
      highestDescription: highestAddDescription,
      interstitialAdClick: interstitialAd,
      adLevel: adLevelSelect,

      publisherIdGoogle1: googleAd1?.publishID,
      appIdGoogle1: googleAd1?.appID,
      bannerGoogle1: googleAd1?.banner,
      nativeGoogle1: googleAd1?.native,
      interstitialGoogle1: googleAd1?.interstitial,
      appOpenGoogle1: googleAd1?.appOpen,
      rewardGoogle1: googleAd1?.reward,

      publisherIdGoogle2: googleAd2?.publishID,
      appIdGoogle2: googleAd2?.appID,
      bannerGoogle2: googleAd2?.banner,
      nativeGoogle2: googleAd2?.native,
      interstitialGoogle2: googleAd2?.interstitial,
      appOpenGoogle2: googleAd2?.appOpen,
      rewardGoogle2: googleAd2?.reward,

      publisherIdFacebook1: facebook1?.publishID,
      appIdFacebook1: facebook1?.appID,
      bannerFacebook1: facebook1?.banner,
      nativeFacebook1: facebook1?.native,
      interstitialFacebook1: facebook1?.interstitial,
      appOpenFacebook1: facebook1?.appOpen,
      rewardFacebook1: facebook1?.reward,

      publisherIdFacebook2: facebook2?.publishID,
      appIdFacebook2: facebook2?.appID,
      bannerFacebook2: facebook2?.banner,
      nativeFacebook2: facebook2?.native,
      interstitialFacebook2: facebook2?.interstitial,
      appOpenFacebook2: facebook2?.appOpen,
      rewardFacebook2: facebook2?.reward,

      publisherIdOther1: other1?.publishID,
      appIdOther1: other1?.appID,
      bannerOther1: other1?.banner,
      nativeOther1: other1?.native,
      interstitialOther1: other1?.interstitial,
      appOpenOther1: other1?.appOpen,
      rewardOther1: other1?.reward,

      publisherIdOther2: other2?.publishID,
      appIdOther2: other2?.appID,
      bannerOther2: other2?.banner,
      nativeOther2: other2?.native,
      interstitialOther2: other2?.interstitial,
      appOpenOther2: other2?.appOpen,
      rewardOther2: other2?.reward,

      appId: location.state.editAppData?._id,
    };
    let payload = {
      data: data,
      appId: location.state.editAppData?._id,
    };
    props.monetizationUpdate(payload);
  };

  // const handleSwitchData = (e) => {
  //   setSwitchDataType(!switchDataType)
  //   e.preventDefault();
  //   console.log("switchDataType",switchDataType);
  //   if (switchDataType === true) {
  //     const getGoogleAd1=JSON.parse(localStorage.getItem("googleAd1"));
  //     setGoogleAd2({
  //       publishID: getGoogleAd1?.publishID,
  //       appID: getGoogleAd1?.appID,
  //       banner: getGoogleAd1?.banner,
  //       native: getGoogleAd1?.native,
  //       interstitial: getGoogleAd1?.interstitial,
  //       appOpen: getGoogleAd1?.appOpen,
  //       reward: getGoogleAd1?.reward,
  //     });
  //   } else {
  //     const getGoogleAd2=JSON.parse(localStorage.getItem("googleAd2"));
  //     setGoogleAd1({
  //       publishID: getGoogleAd2?.publishID,
  //       appID: getGoogleAd2?.appID,
  //       banner: getGoogleAd2?.banner,
  //       native: getGoogleAd2?.native,
  //       interstitial: getGoogleAd2?.interstitial,
  //       appOpen: getGoogleAd2?.appOpen,
  //       reward: getGoogleAd2?.reward,
  //     });
  //   }
  // };
  const handleSwitchData = (e) => {
    setSwitchDataType((prevSwitchDataType) => !prevSwitchDataType);
    e.preventDefault();

    if (!switchDataType) {
      const getGoogleAd2 = JSON.parse(sessionStorage.getItem("googleAd2"));
      setGoogleAd1({
        publishID: getGoogleAd2?.publishID,
        appID: getGoogleAd2?.appID,
        banner: getGoogleAd2?.banner,
        native: getGoogleAd2?.native,
        interstitial: getGoogleAd2?.interstitial,
        appOpen: getGoogleAd2?.appOpen,
        reward: getGoogleAd2?.reward,
      });
      const getGoogleAd1 = JSON.parse(sessionStorage.getItem("googleAd1"));
      setGoogleAd2({
        publishID: getGoogleAd1?.publishID,
        appID: getGoogleAd1?.appID,
        banner: getGoogleAd1?.banner,
        native: getGoogleAd1?.native,
        interstitial: getGoogleAd1?.interstitial,
        appOpen: getGoogleAd1?.appOpen,
        reward: getGoogleAd1?.reward,
      });
    } else {
      const getGoogleAd2 = JSON.parse(sessionStorage.getItem("googleAd2"));
      setGoogleAd1({
        publishID: getGoogleAd2?.publishID,
        appID: getGoogleAd2?.appID,
        banner: getGoogleAd2?.banner,
        native: getGoogleAd2?.native,
        interstitial: getGoogleAd2?.interstitial,
        appOpen: getGoogleAd2?.appOpen,
        reward: getGoogleAd2?.reward,
      });
      const getGoogleAd1 = JSON.parse(sessionStorage.getItem("googleAd1"));
      setGoogleAd2({
        publishID: getGoogleAd1?.publishID,
        appID: getGoogleAd1?.appID,
        banner: getGoogleAd1?.banner,
        native: getGoogleAd1?.native,
        interstitial: getGoogleAd1?.interstitial,
        appOpen: getGoogleAd1?.appOpen,
        reward: getGoogleAd1?.reward,
      });
    }
  };

  useEffect(() => {
    sessionStorage.setItem("googleAd1", JSON.stringify(googleAd1));
    sessionStorage.setItem("googleAd2", JSON.stringify(googleAd2));
  }, [googleAd1, googleAd2]);

  return (
    <div className="monetization-edit">
      <form>
        <div className="ad-level ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="ad-level-title d-flex align-items-center">
              <h6>Ad Level</h6>
              <select
                value={adLevelSelect}
                onChange={(e) => handleChangeAdLevelSelect(e)}
              >
                {adLevelSelectData?.map((item) => {
                  return <option value={item?.id}>{item?.title}</option>;
                })}
              </select>
            </div>
            <Button
              btnName={"Submit"}
              type={"submit"}
              newClass={"submit-btn"}
              onClick={handleSubmit}
            />
          </div>
          <div className="ad-level-body">
            <div className="row gx-3">
              <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                <div className=" ad-level-box">
                  <h5>1. Low</h5>
                  <Textarea
                    label={`Description`}
                    placeholder={"Enter description..."}
                    onChange={(e) => {
                      setLowAddDescription(e.target.value);
                    }}
                    row={4}
                    value={lowAddDescription}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0  ">
                <div className=" ad-level-box">
                  <h5>2. Medium</h5>
                  <Textarea
                    label={`Description`}
                    placeholder={"Enter description..."}
                    onChange={(e) => {
                      setMediumAddDescription(e.target.value);
                    }}
                    row={4}
                    value={mediumAddDescription}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <div className=" ad-level-box">
                  <h5>3. High</h5>
                  <Textarea
                    label={`Description`}
                    placeholder={"Enter description..."}
                    onChange={(e) => {
                      setHighAddDescription(e.target.value);
                    }}
                    row={4}
                    value={highAddDescription}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <div className=" ad-level-box">
                  <h5>4. Highest</h5>
                  <Textarea
                    label={`Description`}
                    placeholder={"Enter description..."}
                    onChange={(e) => {
                      setHighestLowAddDescription(e.target.value);
                    }}
                    row={4}
                    value={highestAddDescription}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-revenue">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="row" style={{ position: "relative" }}>
                <div className="switchData-btn">
                  <Button
                    btnIcon={<SwapHorizontalCircleIcon />}
                    onClick={(e) => handleSwitchData(e)}
                    newClass={"switchData"}
                  />
                </div>
                <div className="col-12 mb-4 pb-4 col-md-6 mb-md-0 pb-md-0 ">
                  <AdRevenueBox
                    title={"Google Ad Revenue 1"}
                    inputValue={googleAd1}
                    setInputValue={setGoogleAd1}
                    switchValue={isGoogle1}
                    setSwitchDataType={setSwitchDataType}
                    dataNameTransfer={"isGoogle1"}
                    margin={"0px 10px 0px 0px"}
                    marginShow={true}
                    switchOnChange={setIsGoogle1}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "isGoogle1",
                        isGoogle1
                      )
                    }
                  />
                </div>
                <div className="col-12  col-md-6 ">
                  <AdRevenueBox
                    title={"Google Ad Revenue 2"}
                    inputValue={googleAd2}
                    setInputValue={setGoogleAd2}
                    switchValue={isGoogle2}
                    dataNameTransfer={"isGoogle2"}
                    setSwitchDataType={setSwitchDataType}
                    switchOnChange={setIsGoogle2}
                    margin={"0px 0px 0px 10px"}
                    marginShow={true}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "isGoogle2",
                        isGoogle2
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <AdRevenueBox
                title={"Facebook 1"}
                inputValue={facebook1}
                setInputValue={setFaceBook1}
                switchValue={isFacebook1}
                switchOnChange={setIsFacebook1}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "isFacebook1",
                    isFacebook1
                  )
                }
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <AdRevenueBox
                title={"Facebook 2"}
                inputValue={facebook2}
                setInputValue={setFaceBook2}
                switchValue={isFacebook2}
                switchOnChange={setIsFacebook2}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "isFacebook2",
                    isFacebook2
                  )
                }
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <AdRevenueBox
                title={"Other 1"}
                inputValue={other1}
                setInputValue={setOther1}
                switchValue={isOther1}
                switchOnChange={setIsOther1}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "isOther1",
                    isOther1
                  )
                }
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <AdRevenueBox
                title={"Other 2"}
                inputValue={other2}
                setInputValue={setOther2}
                switchValue={isOther2}
                switchOnChange={setIsOther2}
                onClick={() =>
                  handleSwitch(
                    location.state.editAppData?._id,
                    "isOther2",
                    isOther2
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="interstitial-ad">
          <div className="row">
            <div className="col-12 col-md-6 mt-3">
              <div className="interstitial-ad-box">
                <Input
                  label={`Interstitial Ad Click`}
                  id={`interstitialAd`}
                  type={`text`}
                  value={interstitialAd}
                  onChange={(e) => {
                    setInterstitialAd(e.target.value);
                  }}
                />
                <div className="pre-load-ads pt-3">
                  <label className="label-text">Pre Load Ads</label>
                  <ToggleSwitch
                    value={preLoadAd}
                    onChange={setPreLoadAd}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "preLoadAd",
                        preLoadAd
                      )
                    }
                  />
                </div>
                <div className="pre-load-ads">
                  <label className="label-text">Ads Color Same</label>
                  <ToggleSwitch
                    value={adColorSame}
                    onChange={setAdColorSame}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "adColorSame",
                        adColorSame
                      )
                    }
                  />
                </div>
                <div className="pre-load-ads">
                  <label className="label-text">Splash Ads</label>
                  <ToggleSwitch
                    value={splashAd}
                    onChange={setSplashAd}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "splashAd",
                        splashAd
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="custom-ad-box">
                <label className="label-text mb-0">Custom Ads</label>
                <div className="customeAd-option">
                  <ReactSelect
                    isMulti
                    options={customAd || []}
                    value={selectedOptions}
                    isClearable={false}
                    onChange={(e) => handleSelectChange(e, "addAds")}
                    // getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option._id}
                    formatOptionLabel={(option) => (
                      <div className="moreApp-option">
                        <img
                          src={
                            option?.icon ? baseURL + "/" + option?.icon[0] : ""
                          }
                        />
                        <span>{option?.title ? option?.title[0] : ""}</span>
                      </div>
                    )}
                    components={{
                      Option: CustomOption,
                      MultiValue: CustomMultiValue,
                    }}
                  />
                </div>
                <div className="mt-3 mb-2">
                  <label className="label-text mb-2">Custom Ads</label>
                  <ToggleSwitch
                    value={isCustomAd}
                    onChange={setIsCustomAd}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "isCustomAd",
                        isCustomAd
                      )
                    }
                  />
                </div>
                <div className="mt-2 mb-2">
                  <label className="label-text mb-2">
                    Custom Link Direct Open
                  </label>
                  <ToggleSwitch
                    value={isCustomLinkDirectOpen}
                    onChange={setIsCustomLinkDirectOpen}
                    onClick={() =>
                      handleSwitch(
                        location.state.editAppData?._id,
                        "isCustomLinkDirectOpen",
                        isCustomLinkDirectOpen
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default connect(null, {
  getMonetization,
  getCustomeAd,
  customAdSubmit,
  removeCustomAd,
  appMonetizationSwitch,
  monetizationUpdate,
})(Monetization);
