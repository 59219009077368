import React, { useEffect, useState } from "react";
import { ReactComponent as AddImageIcon } from "../../assets/images/icon/AddImageIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import { getAdmin, updateProfile,updatePassword } from "../../store/slice/authSlice";
import { connect, useSelector } from "react-redux";
import { baseURL } from "../utils/config";
import Input from "../extra/Input";
import Button from "../extra/Button";

const Profile = (props) => {
  const { admin } = useSelector((state) => state.admin);
  const [imageShow, setImageShow] = useState();
  const [image, setImage] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setImageShow(baseURL + "/" + admin?.image);
    setImage(baseURL + "/" + admin?.image);
    setPassword(admin?.password);
    setName(admin?.name);
    setEmail(admin?.email);
  }, [admin]);

  useEffect(() => {
    const getId = JSON.parse(sessionStorage?.getItem("token"));
    props.getAdmin(getId?._id);
  }, []);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];

    setImage(selectedFile);
    setImageShow(URL.createObjectURL(selectedFile));

    const formData = new FormData();
    formData.append("image", selectedFile);

    const payload = {
      id: admin?._id,
      data: formData,
    };

    props.updateProfile(payload);
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    if (!email || !name) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!name) error.name = "Name Is Required !";
      return setError({ ...error });
    } else {
      const data = {
        name: name,
        email: email,
      };
      const payload = {
        id: admin?._id,
        data: data,
      };

      props.updateProfile(payload);
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const validPassword = newPassword == confirmPassword;
    if (!newPassword || !password || !confirmPassword || !validPassword) {
      let error = {};
      if (!password) error.password = "Password Is Required !";
      if (!newPassword) error.newPassword = "New Password Is Required !";
      if (!confirmPassword) {
        error.confirmPassword = "Confirm Password Is Required !";
      } else if (!validPassword) {
        error.confirmPassword = "Passwords do not match!";
      }
      return setError({ ...error });
    } else {
      const data = {
        oldPass: password,
        newPass:newPassword,
        confirmPass:confirmPassword
      };
      const payload = {
        id: admin?._id,
        data: data,
        type:"password",
      };

      props.updatePassword(payload);

    }
  };

  return (
    <div className="profilePage mt-3">
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="admin-box">
            <h3>Admin Avatar</h3>
            <div className="profile-image">
              <div
                className={`image-upload ${
                  imageShow ? "show-img-content" : ""
                }`}
              >
                <label for="image" onChange={handleFileUpload}>
                  {imageShow ? (
                    <div className={`${imageShow ? "show-img" : ""}`}>
                      <div className="edit-icon">
                        <EditIcon />
                      </div>
                      {imageShow && <img src={imageShow} />}
                    </div>
                  ) : (
                    <div className="img-box">
                      <AddImageIcon />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png" 
                    name="image"
                    id="image"
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <h5>{admin?.name ? admin?.name : "Admin"}</h5>
              <h6>{"Admin of Virtual Desk"}</h6>
            </div>
           
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
        <div className="admin-box">
        <h3>Admin Info</h3>
        <form>
              <div className="admin-info">
                <div>
                  <Input
                    label={`Name`}
                    id={`name`}
                    type={`text`}
                    errorMessage={error.name && error.name}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: `Name Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          name: "",
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <Input
                    label={`Email`}
                    id={`email`}
                    type={`text`}
                    errorMessage={error.email && error.email}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `Email Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <Button
                type={"submit"}
                onClick={handleUpdateProfile}
                btnName={"Update Profile"}
                newClass={"btn-submit"}
              />
            </form>
        </div>
          </div>
        <div className="col-12 col-md-6 mt-0 mt-md-3">
          <div className="admin-box">
            <h3>Change Password</h3>
            <form>
              <div className="mt-3">
                <div>
                  <Input
                    label={`Password`}
                    id={`password`}
                    type={`password`}
                    value={password}
                    errorMessage={error.password && error.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          password: `Password Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          password: "",
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <Input
                    label={`New Password`}
                    id={`newPassword`}
                    type={`password`}
                    value={newPassword}
                    errorMessage={error.newPassword && error.newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          newPassword: `New Password Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          newPassword: "",
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <Input
                    label={`Confirm password`}
                    id={`confirmPassword`}
                    type={`password`}
                    value={confirmPassword}
                    errorMessage={
                      error.confirmPassword && error.confirmPassword
                    }
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          confirmPassword: `Confirm Password Is Required !`,
                        });
                      } else {
                        return setError({
                          ...error,
                          confirmPassword: "",
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <Button
                type={"submit"}
                btnName={"Change Password"}
                onClick={handleChangePassword}
                newClass={"btn-submit"}
              />
            </form>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default connect(null, { getAdmin, updateProfile,updatePassword })(Profile);
