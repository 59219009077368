import React, { useEffect, useState } from "react";
import {
  getPlan,
  planUpdate,
  deletePlan,
  paymentPlanSwitch,
  isActiveSwitch,
} from "../../store/slice/appSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ToggleSwitch from "../extra/ToggleSwitch";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useLocation } from "react-router-dom";
import { warning } from "../utils/Alert";
import Button from "../extra/Button";
import { openDialog } from "../../store/slice/dialogueSlice";
import PlanEdit from "../Page/EditApp/PlanEdit";
import { baseURL } from "../utils/config";

const PlanTable = (props) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isFree, setIsFree] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { planData, totalPlans } = useSelector((state) => state.app);
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const handlePageChange = (pageNumber) => {
    setStart(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setStart(1);
    setLimit(value);
  };

  useEffect(() => {
    const payload = {
      appId: location?.state?.editAppData?._id,
      start,
      limit,
    };
    props.getPlan(payload);
  }, [start, limit]);

  useEffect(() => {
    setData(planData);
  }, [planData]);

  const handleIsfreeSwitch = (row) => {};

  const handleEditPlan = (row, type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: row,
    };
    dispatch(openDialog(payload));
  };

  const handleDeltePlan = (row) => {
    const data = warning();
    data
      .then((res) => {
        if (res) {
          if (res) {
            props.deletePlan(row);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNewOpenDialog = (type) => {
    const payload = {
      dialogue: true,
      dialogueType: type,
      dialogueData: null,
    };
    dispatch(openDialog(payload));
  };

  const handleInActiveSwitch = (row) => {
    const payload = {
      id: row?._id,
      value: row?.isActive,
    };
    props.isActiveSwitch(payload);
  };

  const handlePaymentSwitch=(row,switchValue,type)=>{
    const payload = {
      planId: row?._id,
      appId:location?.state?.editAppData?._id,
      type:type,
      value:switchValue,
    };
    props.paymentPlanSwitch(payload);
  }
  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(start - 1) * limit + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "Name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.name ? row?.name : "-"}</span>
      ),
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div>
          {row?.image ? (
            <img
              src={baseURL + "/" + row?.image}
              style={{
                width: "38px",
                height: "38px",
                borderRadius: "6px",
                objectFit: "cover",
              }}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      Header: "Product Key",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.productKey ? row?.productKey : "-"}
        </span>
      ),
    },
    {
      Header: "Contract",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.contract ? row?.contract : "-"}
        </span>
      ),
    },
    {
      Header: "Contract Time",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.contractTime ? row?.contractTime : "-"}
        </span>
      ),
    },
    {
      Header: "Monthly Earning",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.monthlyEarning ? row?.monthlyEarning : "-"}
        </span>
      ),
    },
    {
      Header: "Rupee",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.rupee ? row?.rupee : "-"}</span>
      ),
    },
    {
      Header: "USD",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.dollar ? row?.dollar : "-"}</span>
      ),
    },
    {
      Header: "Server Name",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.serverName ? row?.serverName : "-"}
        </span>
      ),
    },
    {
      Header: "Description",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.description ? row?.description : "-"}
        </span>
      ),
    },
    {
      Header: "Is Free",
      Cell: ({ row }) => (
        <span className="  text-nowrap">
          {row?.isFree === true ? "Free" : "Paid"}
        </span>
      ),
    },
    {
      Header: "Is Active",
      body: "isActive",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handleInActiveSwitch(row)}
            value={row?.isActive}
          />
        </div>
      ),
    },
    {
      Header: "Google Pay",
      body: "googlePlaySwitch",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handlePaymentSwitch(row,row?.googlePlaySwitch,"googlePlaySwitch")}
            value={row?.googlePlaySwitch}
          />
        </div>
      ),
    },
    {
      Header: "Stripe",
      body: "stripeSwitch",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handlePaymentSwitch(row,row?.stripeSwitch,"stripeSwitch")}
            value={row?.stripeSwitch}
          />
        </div>
      ),
    },
    {
      Header: "Coin Base",
      body: "coinBaseSwitch",
      Cell: ({ row }) => (
        <div>
          <ToggleSwitch
            leftLabel={""}
            rightLabel={""}
            onChange={() => handlePaymentSwitch(row,row?.coinBaseSwitch,"coinBaseSwitch")}
            value={row?.coinBaseSwitch}
          />
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "edit",
      Cell: ({ row }) => (
        <Button
          btnIcon={<EditRoundedIcon />}
          newClass={"editBtn"}
          onClick={() => handleEditPlan(row, "editPlan")}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          btnIcon={<DeleteRoundedIcon />}
          onClick={() => handleDeltePlan(row?._id)}
          newClass={"delete-btn-plan"}
        />
      ),
    },
  ];

  return (
    <div>
      {dialogueType === "editPlan" || dialogueType === "newPlan" ? (
        <PlanEdit />
      ) : (
        <>
          <div className="plan-table">
            <div className="new-button d-flex justify-content-end">
              <Button
                btnName={"New"}
                btnIcon={<AddRoundedIcon />}
                type={"button"}
                onClick={() => handleNewOpenDialog("newPlan")}
                newClass={"new-btn"}
              />
            </div>
            <Table
              data={data}
              type={"server"}
              mapData={mapData}
              start={start}
              limit={limit}
            />
            <Pagination
              type={"server"}
              start={start}
              limit={limit}
              totalData={totalPlans}
              setStart={setStart}
              setLimit={setLimit}
              handleRowsPerPage={handleRowsPerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default connect(null, {
  getPlan,
  planUpdate,
  deletePlan,
  isActiveSwitch,
  paymentPlanSwitch
})(PlanTable);
