import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/slice/dialogueSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

import Input from "../extra/Input";
import ToggleSwitch from "../extra/ToggleSwitch";
import Button from "../extra/Button";
import {
  createPlatform,
  updatePlatform,
} from "../../store/slice/newMonetizationSlice";
import ReactSelect from "react-select";
const AddMonetization = (props) => {
  const { dialogueData, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [bannerAdUnitId, setBannerAdUnitId] = useState("");
  const [interstitialAdUnitId, setInterstitialAdUnitId] = useState("");
  const [nativeAdUnitId, setNativeAdUnitId] = useState("");
  const [appOpenAdUnitId, setAppOpenAdUnitId] = useState("");
  const [enable, setEnable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState("");

  console.log('type', type)



  const [error, setError] = useState({
    name: "",
    type: "",
    bannerAdUnitId: "",
    interstitialAdUnitId: "",
    nativeAdUnitId: "",
    appOpenAdUnitId: "",
  });

  useEffect(() => {
    if (dialogueType === "editMonetization") {
      setName(dialogueData?.data?.name);
      setType(dialogueData?.data?.type);
      setBannerAdUnitId(dialogueData?.data?.bannerAdUnitId);
      setInterstitialAdUnitId(dialogueData?.data?.interstitialAdUnitId);
      setNativeAdUnitId(dialogueData?.data?.nativeAdUnitId);
      setAppOpenAdUnitId(dialogueData?.data?.appOpenAdUnitId);
      setEnable(dialogueData?.data?.enable || false);
    }
  }, [dialogueType, dialogueData]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleSubmit = (e) => {
    if (
      !name ||
      !type ||
      !bannerAdUnitId ||
      !interstitialAdUnitId ||
      !nativeAdUnitId ||
      !appOpenAdUnitId
    ) {
      let error = {};
      if (!name) error.name = "Name Is Required!";
      if (!bannerAdUnitId) error.bannerAdUnitId = "Banner Ad Unit ID Is Required!";
      if (!interstitialAdUnitId) error.interstitialAdUnitId = "Interstitial Ad Unit ID Is Required!";
      if (!nativeAdUnitId) error.nativeAdUnitId = "Native Ad Unit ID Is Required!";
      if (!appOpenAdUnitId) error.appOpenAdUnitId = "App Open Ad Unit ID Is Required!";

      return setError({ ...error });
    } else {
      const data = {
        name,
        type,
        bannerAdUnitId,
        interstitialAdUnitId,
        nativeAdUnitId,
        appOpenAdUnitId,
        enable,
      };

      let payload;

      if (dialogueType === "editMonetization") {
        payload = {
          data,
          appId: dialogueData.appId,
          platformId: dialogueData.platformId,
        };

        dispatch(updatePlatform(payload));
      } else {
        payload = {
          data,
          appId: dialogueData,
        };
        dispatch(createPlatform(payload));
      }

      dispatch(closeDialog());
    }
  };

  const options = [
    { value: "GOOGLE", label: "GOOGLE" },
    { value: "FACEBOOK", label: "FACEBOOK" },
  ]

  const handleAutocompleteChange = (selected) => {
    setSelectedOptions(selected);
    const selectedIds = selected?.map((option) => option?._id);
    const updatedData = selectedIds?.join(",");
    setSelectedDeveloper(updatedData);
  };

  return (
    <div className="new-appCreate">
      <div className="dialog">
        <div className="mainDiaogBox">
          <div className="dialog-head">
            <h4>{dialogueType === "editMonetization" ? "Edit Monetization" : "Add Monetization"}</h4>
            <IconButton onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </div>
          <form>
            <div className="dialog-body">
              <div className="row">
                <div className="col-12">
                  <Input
                    label={` Name`}
                    id={`enterAppName`}
                    type={`name`}
                    value={name}
                    errorMessage={error.name && error.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: `Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          name: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 devloper-select">
                  <label className="label-input d-block">Devloper</label>
                  <div

>
                    <select
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                        if (!e.target.value) {
                          setError({
                            ...error,
                            type: `Type Is Required`,
                          });
                        } else {
                          setError({
                            ...error,
                            type: "",
                          });
                        }
                      }}
                      style={{
                        borderRadius: "6px",
                        border: "1px solid #c5c5c5",
                        background: "#fff",
                        padding: "7px",
                        width: "100%",
                        outline: "none",
                      }}
                    >
                    {options?.map((value) => (
                      <option value={value?.value}>{value?.label}</option>
                    ))}
                  </select>
                    {error.type && <div className="error-message">{error.type}</div>}

                  </div>
                
                </div>

                <div className="col-12">
                  <Input
                    label={`Banner Ad Unit ID`}
                    id={`bannerAdUnitId`}
                    type={`bannerAdUnitId`}
                    value={bannerAdUnitId}
                    errorMessage={error.bannerAdUnitId && error.bannerAdUnitId}
                    onChange={(e) => {
                      setBannerAdUnitId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          bannerAdUnitId: `Banner Ad Unit ID Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          bannerAdUnitId: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12">
                  <Input
                    label={`Interstitial Ad Unit ID`}
                    id={`interstitialAdUnitId`}
                    type={`interstitialAdUnitId`}
                    value={interstitialAdUnitId}
                    errorMessage={error.interstitialAdUnitId && error.interstitialAdUnitId}
                    onChange={(e) => {
                      setInterstitialAdUnitId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          interstitialAdUnitId: `Interstitial Ad Unit ID Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          interstitialAdUnitId: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12">
                  <Input
                    label={`Native Ad Unit ID`}
                    id={`nativeAdUnitId`}
                    type={`nativeAdUnitId`}
                    value={nativeAdUnitId}
                    errorMessage={error.nativeAdUnitId && error.nativeAdUnitId}
                    onChange={(e) => {
                      setNativeAdUnitId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          nativeAdUnitId: `Native Ad Unit ID Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          nativeAdUnitId: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12">
                  <Input
                    label={`App Open Ad Unit ID`}
                    id={`appOpenAdUnitId`}
                    type={`appOpenAdUnitId`}
                    value={appOpenAdUnitId}
                    errorMessage={error.appOpenAdUnitId && error.appOpenAdUnitId}
                    onChange={(e) => {
                      setAppOpenAdUnitId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          appOpenAdUnitId: `App Open Ad Unit ID Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          appOpenAdUnitId: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 mt-3">
                  <label className="label-input">Enable/Disable</label>
                  <ToggleSwitch value={enable} onChange={setEnable} />
                </div>
              </div>
            </div>
            <div className="row dialog-footer">
              <div className="col-6"></div>
              <div className="col-6 mt-2 d-flex align-items-center justify-content-end">
                <Button
                  btnName={"Cancel"}
                  onClick={handleClose}
                  newClass={"cancel-btn"}
                />
                <Button
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmit}
                  newClass={"submit-btn"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddMonetization;
