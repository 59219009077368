import dayjs from "dayjs";
import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

export default function DateRange(props) {
  const { setStartDate, setEndDate, endDate, startDate } = props;

  const [isDateRangePickerVisible, setDateRangePickerVisible] = useState(false);

  const [state, setState] = useState({
    start: dayjs().subtract(29, "days"),
    end: dayjs(),
  });

  const startAllDate = "1970-01-01";
  const endAllDate = dayjs().format("YYYY-MM-DD");

  const handleInputClick = () => {
    setDateRangePickerVisible(!isDateRangePickerVisible);
  };

  const handleCallback = (start, end) => {
    setState({ start, end });
  };

  const handleCancel = (event, picker) => {
    picker?.element.val("");
    setStartDate("All");
    setEndDate("All");
  };

  const handleApply = (event, picker) => {
    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div>
      <DateRangePicker
        initialSettings={{
          startDate: undefined,
          endDate: undefined,
          ranges: {
            Today: [dayjs().toDate(), dayjs().toDate()],
            Yesterday: [
              dayjs().subtract(1, "days").toDate(),
              dayjs().subtract(1, "days").toDate(),
            ],

            "Last 7 Days": [
              dayjs().subtract(6, "days").toDate(),
              dayjs().toDate(),
            ],
            "Last 30 Days": [
              dayjs().subtract(29, "days").toDate(),
              dayjs().toDate(),
            ],
            "This Month": [
              dayjs().startOf("month").toDate(),
              dayjs().endOf("month").toDate(),
            ],
            "Last Month": [
              dayjs().subtract(1, "month").startOf("month").toDate(),
              dayjs().subtract(1, "month").endOf("month").toDate(),
            ],
            // "Reset Dates": [new Date("1970-01-01"), dayjs().toDate()],
          },
          maxDate: new Date(),
        }}
        onCallback={handleCallback}
        onCancel={handleCancel}
        onApply={handleApply}
      >
        <input
          type="text"
          readOnly
          placeholder="Select Date Range"
          onClick={handleInputClick}
          className={`daterange float-right  mr-4  text-center `}
          value={
            startDate === "All" && endDate === "All"
              ? "Select Date Range"
              : dayjs(startDate).format("YYYY/MM/DD") &&
                dayjs(endDate).format("YYYY/MM/DD")
              ? `${dayjs(startDate).format("YYYY/MM/DD")} - ${dayjs(
                  endDate
                ).format("YYYY/MM/DD")}`
              : "Select Date Range"
          }
          style={{
            // width: "85%",
            fontWeight: 500,
            cursor: "pointer",
            background: "white",
            color: "#3f51b5",
            display: "flex",
            width: "100%",
            justifyContent: "end",
            fontSize: "13px",
            padding: "10px",
            maxWidth: "226px",
            borderRadius: "6px",
            border: "1px solid rgba(63, 81, 181, 0.5)",
            height: "48px !important",
          }}
        />
      </DateRangePicker>
    </div>
  );
}
